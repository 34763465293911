import { ERROR_SERVER_NOT_AVAILABLE, ERROR_INTERNAL_SERVER_FAILURE, ERROR_CLIENT_ERROR } from "../constants/error";
import auth from "../../../service/auth";
import { Printer, LabelTypes } from "../state/types";
import { API_BASE_URL } from "../../../api/api";

const defaultHeaders: RequestInit = {
    mode: "cors", 
    credentials: "include",
    headers: { "Content-Type": "application/json" },
};

export interface Draft
{
    id: string;
    name: string;
}

export const TRACKING_BATCH = 0;
export const TRACKING_SERIAL = 1;
export const TRACKING_ORDINARY = 2;

type TrackingType = 0 | 1 | 2;

export interface Item
{
    ean: string;
    sku: string;
    unit: string;
    name: string;
    tracking: TrackingType;
    barcode: string;
    lotNumber: string;
    quantity: string;
}

// for development
// const API_BASE_URI = "http://localhost:52184/stock-management/inventory";

const defaultHandler: ResponseHandler<any> = (response: Response) => response.json();

type ResponseHandler<T> = (response: Response) => T;

export interface RelocationRequest
{
    from: string;
    to: string;
    item: Item;
    qty: number;
    warehouse: string;
}

export interface LabelRequest
{
    printer: string;
    quantity: number;
    barcode: string;
    labelSku: string;
    additionalFields: { [key: string]: string };
}

export const apiCall = async <T>(uri: string, options: RequestInit = { credentials: "include", mode: "cors" }, handler: ResponseHandler<T> = defaultHandler): Promise<T> => 
{
    try
    {
        const fetch = auth.getAuthenticatedFetch();
        const response = await fetch(`${API_BASE_URL}/${uri}`, options);
        
        if(response.ok)
        {
            return handler(response);
        }

        if(response.status === 500)
        {
            let message = null;
            try 
            {
                const responseBody = await response.json();
                message = responseBody.errorMessage;
            } catch(e) {}
            throw { error: ERROR_INTERNAL_SERVER_FAILURE, message };
        }

        if(response.status === 404)
        {
            throw { error: ERROR_CLIENT_ERROR };
        }

        if(response.status === 409)
        {
            throw await response.json();
        }

    } catch (e)
    {
        if (e instanceof TypeError)
        {
            throw { error: ERROR_SERVER_NOT_AVAILABLE }
        }
        throw e;
    }
    return null as any;
};

export const getDrafts = async (warehouse: string): Promise<Draft[]> =>
    apiCall<Draft[]>(`stock-management/inventory/${warehouse}/drafts`);

export const getItem = async (barcode: string): Promise<Item> => 
    apiCall<Item>(`lookup/barcode/${barcode}`).then(item => ({ ...item, barcode }));

export const getQty = async (warehouse: string, location: string, sku: string) =>
    apiCall<string>(`lookup/${warehouse}/location/${location}/item/${sku}`);

export const moveItem = async ({ from, to, item: { barcode: item }, warehouse, qty: quantity }: RelocationRequest) =>
    apiCall(`stock-management/relocate`, {
        ...defaultHeaders,
        method: "POST",
        body: JSON.stringify({ from, to, item, warehouse, quantity }),
    }, response => response.ok);

export const updateItem = async (draft: string, warehouse: string, barcode: string, location: string, quantity: number): Promise<boolean> => 
    apiCall(`stock-management/inventory/${warehouse}/draft/${draft}/item/${barcode}`, { 
        ...defaultHeaders,
        method: "PUT",
        body: JSON.stringify({ quantity, location })
    }, response => response.ok);

export const fetchPrinters = async (): Promise<Printer[]> =>
    apiCall<Printer[]>(`stock-management/label/printers`);

export const fetchLabelTypes = async (barcode: string, languageId = "en-US"): Promise<LabelTypes> =>
    apiCall<LabelTypes>(`lookup/barcode/${barcode}/labels/${languageId}`);

export const requestLabel = async (labelRequest: LabelRequest): Promise<void> => 
    apiCall<void>(`stock-management/label/print`, {
        ...defaultHeaders,
        method: "POST",
        body: JSON.stringify(labelRequest)
    }, response => response.ok);

export const fetchPendingItems = async (warehouse: string, draft: string, location: string): Promise<Item[]> =>
    apiCall<Item[]>(`stock-management/inventory/${warehouse}/draft/${draft}/pending/${location}`, undefined, res => 
        (res.ok) ? (
            res.status === 204 ? [] : res.json()
        ) : defaultHandler(res));

export const confirmLocationComplete = async (warehouse: string, draft: string, location: string): Promise<void> =>
    apiCall<void>(`stock-management/inventory/${warehouse}/draft/${draft}/pending/${location}/complete`, {
        ...defaultHeaders,
        method: "POST"
    }, res => res.ok ? Promise.resolve() : defaultHandler(res));
// export const requestLabelConfig = async (itemId: string): Promise< => 
