import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import i18n from "es2015-i18n-tag";

import { getInventoryScannedItem, getInventoryScannedLocation, getInventoryIsLoadingItem, getInventoryCurrentItem, getInventoryIsUpdatingItem } from "../../state";

import scanIcon from "../../icons/scan.svg";
import SoftBlink from "../../../../components/SoftBlink";
import { stockScannedItem, stockScannedLocation, stockScannedClear, stockFetchItem, stockUpdateItem, stockFetchPendingItems, stockClearPreviousLocationAndItems, stockRevertLocation, stockConfirmLocationComplete } from "../../state/action";
import { Scanner } from "../../../../util/scanner";
import { TRACKING_SERIAL, TRACKING_BATCH, TRACKING_ORDINARY, apiCall } from "../../api/StockApi";
import RadioButtonGroup from "../../../../components/RadioButtonGroup";
import { StockState, getInventoryPreviousLocation, getInventoryPendingItems } from "../../state/reducer";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Divider from "@material-ui/core/Divider";
import { RadioGroup, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

interface RouteParams {
    warehouse: string;
    draft: string;
}

export interface Props extends RouteComponentProps<RouteParams> {

}

interface State {
    quantity: string;
    containerIsClosed?: string;
    confirmChecked: boolean;
    showAlreadyCountedDialog?: {
        result: ValidateResult;
        confirm: (action: "cancel" | "add" | "overwrite") => void;
    };
    action: string;
}

interface ValidateResult {
    previouslyCounted: boolean;
    qty: number;
    date: string;
    addAllowed: boolean;
    draft: string;
}


const mapStateToProps = (state: StockState) => ({
    scannedItem: getInventoryScannedItem(state),
    scannedLocation: getInventoryScannedLocation(state),
    previousLocation: getInventoryPreviousLocation(state),
    pendingItems: getInventoryPendingItems(state),
    loadingItem: getInventoryIsLoadingItem(state),
    updatingItem: getInventoryIsUpdatingItem(state),
    currentItem: getInventoryCurrentItem(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    scanItem: (item: string) => dispatch(stockScannedItem(item)),
    scanLocation: (location: string) => dispatch(stockScannedLocation(location)),
    scanClear: () => dispatch(stockScannedClear()),
    fetchItem: (draft: string, barcode: string, location: string, warehouse: string) => dispatch(stockFetchItem(draft, barcode, location, warehouse) as any),
    updateItem: (draftId: string, warehouse: string, barcode: string, location: string, quantity: number, sku: string) =>
        dispatch(stockUpdateItem(draftId, warehouse, barcode, location, quantity, sku) as any),
    fetchPendingItems: (warehouse: string, draft: string, location: string) =>
        dispatch(stockFetchPendingItems(warehouse, draft, location)),
    clearPreviousLocationAndItems: () => dispatch(stockClearPreviousLocationAndItems()),
    revertLocation: () => dispatch(stockRevertLocation()),
    confirmLocationComplete: (warehouse: string, draft: string, location: string) =>
        dispatch(stockConfirmLocationComplete(warehouse, draft, location))
});

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const getTrackingTypeLabel = (n: number) =>
    (n === TRACKING_BATCH) ? i18n`Batch` :
        (n === TRACKING_SERIAL) ? i18n`Serial` :
            (n === TRACKING_ORDINARY) ? i18n`Ordinary`
                : i18n`Unknown`

interface ScanInputProps {
    label: string;
    value?: string;
}
const ScanInput = ({ label, value = undefined }: ScanInputProps) => (
    <Paper style={{ padding: 8, marginTop: 16 }}>
        <Grid container spacing={8} alignItems="center">
            <Grid item xs={10}>
                <Typography variant="caption">{label}</Typography>
                {
                    value ? (
                        <Typography variant="body1">{value}</Typography>
                    ) : (
                            <SoftBlink duration={1.5}>
                                <Typography variant="body1">_</Typography>
                            </SoftBlink>
                        )
                }
            </Grid>
            <Grid item xs={1}>
                {
                    value ? <Check color="primary" /> : null
                }
            </Grid>
        </Grid>
    </Paper>
);

class Inventory extends React.Component<Props & StateProps, State>
{
    private scanner: Scanner | null = null;

    state: State = { quantity: "", confirmChecked: false, action: "cancel" };

    componentWillMount() {
        this.scanner = new Scanner(this.scannedBardcode);
        this.onPropsChanged(this.props);
    }

    componentWillUnmount() {
        if (this.scanner !== null) {
            this.scanner.destroy();
        }
    }

    componentWillReceiveProps(nextProps: Props & StateProps) {
        this.onPropsChanged(nextProps);
    }

    private onPropsChanged(props: Props & StateProps) {
        const { scannedItem, scannedLocation, currentItem, loadingItem, pendingItems, previousLocation, fetchPendingItems } = props;
        const { draft, warehouse } = props.match.params;

        if (previousLocation !== undefined) {
            if (pendingItems === undefined) {
                fetchPendingItems(warehouse, draft, previousLocation);
            }
            else if (pendingItems.length === 0) {
                this.props.clearPreviousLocationAndItems();
            }
        }

        if (scannedItem && scannedLocation) {
            if (!currentItem && !loadingItem) {
                this.props.fetchItem(draft, scannedItem, scannedLocation, warehouse);
            }
            else if (currentItem && currentItem !== this.props.currentItem && currentItem.tracking === TRACKING_SERIAL && !this.isContainer(scannedItem)) {
                this.props.updateItem(draft, warehouse, scannedItem, scannedLocation, 1, currentItem.sku);
            }
        }
        else {
            this.setState({ quantity: "" });
        }
    }

    private isContainer = (barcode: string | undefined) => (barcode !== undefined) && (barcode.length === 10) && (barcode.substr(0, 1).toUpperCase() === "C");

    private scannedBardcode = (symbols: string) => {
        if (this.showLoader()) {
            return;
        }

        if (this.isContainer(symbols)) {
            this.props.scanItem(symbols.toUpperCase());
        }

        if ((symbols.length === 28)) {
            const control1 = symbols.substr(0, 2);
            const control2 = symbols.substr(16, 2);

            if (control1 === "01" && (control2 === "10" || control2 === "21")) {
                this.props.scanItem(symbols);
            }
        }

        if (symbols.length === 16) {
            const control1 = symbols.substr(0, 2);
            if (control1 === "01") {
                this.props.scanItem(symbols);
            }
        }

        if (symbols.length === 13) {
            const control1 = symbols.substr(0, 2);
            if (control1 === "57") {
                this.props.scanItem(symbols);
            }
        }

        if (symbols.length === 6) // sku
        {
            this.props.scanItem(symbols);
        }

        if (symbols.length === 9) {
            this.props.scanLocation(symbols);
        }
    }

    private handleCloseDialog = () => {
        this.props.scanClear();
        this.setState({ quantity: "", containerIsClosed: undefined });
    }


    private showConfirmAlreadyCountedDialog = async (result: ValidateResult) => {
        return new Promise<"add" | "overwrite" | "cancel">((accept, reject) => {

            this.setState({
                showAlreadyCountedDialog: {
                    confirm: (v) => accept(v),
                    result
                },
                action: "cancel"
            });

        });
    }

    private handleSaveQuantity = async () => {
        const {
            match: { params: { draft, warehouse } },
            scannedItem, scannedLocation, currentItem
        } = this.props;

        let overrideQuantity: number | undefined = undefined;

        // TODO - skip for container

        const result: ValidateResult = await apiCall(`stock-management/inventory/${warehouse}/report/${scannedLocation}/item/${scannedItem}`);
        if (result.previouslyCounted || (result.draft !== null && draft !== result.draft)) {
            const dialogResult = await this.showConfirmAlreadyCountedDialog(result);
            if (dialogResult === "add") {
                overrideQuantity = parseFloat(this.state.quantity) + result.qty;
            }
            else if (dialogResult === "overwrite") {
                overrideQuantity = parseFloat(this.state.quantity);
            }

            this.setState({ showAlreadyCountedDialog: undefined });
            this.props.scanClear();

            if (dialogResult === "cancel") {
                return;
            }
        }


        if (scannedItem !== undefined && scannedLocation !== undefined && currentItem !== undefined) {
            const quantity = this.isContainer(scannedItem) ? 0 : (overrideQuantity ?? parseFloat(this.state.quantity));
            this.props.updateItem(draft, warehouse, scannedItem, scannedLocation, quantity, currentItem.sku);
        }
    }

    private showDialog = () => this.props.currentItem !== undefined
        && (this.props.currentItem.tracking !== TRACKING_SERIAL || this.isContainer(this.props.scannedItem));

    private showLoader = () => this.props.loadingItem;

    private showDialogLoader = () => this.props.updatingItem;

    private onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const quantity = e.target.value;

        if (this.props.currentItem?.quantity !== undefined) {
            if ((Number(quantity)) >= Number(this.props.currentItem?.quantity)*1.1)
                e.target.style.color = "red";
            else
                e.target.style.color = "inherit";
        }

        this.setState({ quantity });
    }

    private renderDialog() {
        const { scannedLocation: location, currentItem: item, scannedItem: barcode } = this.props;
        const { quantity, containerIsClosed = undefined } = this.state;
        if (item === undefined) {
            return;
        }

        const onKeyPressEnterHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
            if (e.key === "Enter" && e.currentTarget.value.length > 0) {
                this.handleSaveQuantity();
            }
        }

        const save = () => {
            if ((this.isContainer(barcode) && containerIsClosed === "0")) {
                this.handleCloseDialog();
            }
            else {
                this.handleSaveQuantity();
            }
        }

        return (
            <Dialog fullScreen
                open={this.showDialog()}
                onClose={() => this.props.scanClear()}>
                <AppBar style={{ position: "relative" }} color="primary">
                    <Toolbar>
                        <IconButton color="inherit" onClick={this.handleCloseDialog} style={{ marginLeft: -8 }}>
                            <Close />
                        </IconButton>
                        <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                            {i18n`Quantity`}
                        </Typography>
                        <Button color="inherit" onClick={save} style={{ marginRight: -16 }} disabled={quantity === "" && containerIsClosed === undefined}>
                            {
                                (containerIsClosed === "0")
                                    ? i18n`ok`
                                    : i18n`save`
                            }

                        </Button>
                    </Toolbar>
                </AppBar>
                <div style={{ padding: 16 }} >
                    {this.showDialogLoader() ? this.renderLoader() : null}
                    <Grid container spacing={8} alignItems="flex-end" style={{ marginTop: 0 }}>
                        <Grid item xs={12}>
                            <Typography variant="caption">{i18n`Name`}</Typography>
                            <Typography variant="body1">{item.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">{i18n`Location`}</Typography>
                            <Typography variant="body1">{location}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">{i18n`SKU`}</Typography>
                            <Typography variant="body1">{item.sku}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">{i18n`Tracking Type`}</Typography>
                            <Typography variant="body1">{getTrackingTypeLabel(item.tracking)}</Typography>
                        </Grid>
                        {/*<Grid item xs={12}>*/}
                        {/*    <Typography variant="caption" >{i18n`Expected Qty`}</Typography>*/}
                        {/*    <Typography variant="body1" >{item.quantity}</Typography>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12}>
                            {
                                (this.isContainer(barcode)) ? (
                                    <div style={{ marginTop: 16 }}>
                                        <FormControl component={"fieldset" as any}>
                                            <FormLabel component={"legend" as any}>{i18n`You have scanned a container. Is the container closed and sealed?`}</FormLabel>
                                            <RadioButtonGroup
                                                value={containerIsClosed}
                                                onChange={v => this.setState({ containerIsClosed: v })}>
                                                <FormControlLabel value="1" control={<Radio />} label="Yes, the container is closed" />
                                                <FormControlLabel value="0" control={<Radio />} label="No, the container is open" />
                                            </RadioButtonGroup>
                                        </FormControl>
                                        <Typography variant="body1" color="secondary" style={{ marginTop: 8 }}>
                                            {
                                                (containerIsClosed === "1") ? i18n`Please keep the container closed and proceed to the next item.` :
                                                    (containerIsClosed === "0") ? i18n`Please scan and count the contents of the container individually.` :
                                                        null
                                            }
                                        </Typography>
                                    </div>
                                ) : (
                                        <FormControl fullWidth>
                                            <InputLabel>{i18n`Quantity`}</InputLabel>
                                            <Input
                                                type="number"
                                                autoFocus
                                                fullWidth
                                                endAdornment={<InputAdornment position="end">{item.unit}</InputAdornment>}
                                                value={quantity}
                                                onChange={this.onQuantityChange}
                                                inputProps={{ onKeyDown: onKeyPressEnterHandler }}

                                            />
                                        </FormControl>
                                    )
                            }
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        )
    }

    private renderLoader = (left = 16, top = 16, right = 16) => (
        <LinearProgress variant="indeterminate" style={{
            marginLeft: -left,
            marginRight: -right,
            marginTop: -top
        }} />
    );

    private onConfirmNullClick = () => {
        const { previousLocation = "" } = this.props;
        const { draft, warehouse } = this.props.match.params;
        const { confirmLocationComplete } = this.props;
        this.setState({ confirmChecked: false });
        confirmLocationComplete(warehouse, draft, previousLocation);
    }

    private onCancelNullClick = () => {
        const { revertLocation } = this.props;
        this.setState({ confirmChecked: false });
        revertLocation();
    }

    private renderLocationChanged() {
        const { pendingItems, previousLocation: location } = this.props;

        if (location === undefined) {
            // TODO: we should never be here, fix up state and go back home
            return;
        }

        if (pendingItems === undefined || pendingItems.length === 0) {
            return (
                <div>Hold on a second</div>
            );
        }

        return (
            <div>
                <Typography style={{ color: "red" }}>
                    {i18n`The following items are all registered at location ${location} but have not had quantities reported. Please check if all of these have no items present at the location before continuing.`}
                </Typography>
                <List>
                    {
                        pendingItems.map(item => (
                            <ListItem key={item.sku}>
                                <ListItemText primary={item.name} secondary={i18n`SKU: ${item.sku}`} />
                            </ListItem>
                        ))
                    }
                </List>
                <Divider variant="fullWidth" />
                <FormGroup style={{ marginTop: 16 }}>
                    <FormControlLabel
                        checked={this.state.confirmChecked}
                        onChange={(_, confirmChecked) => this.setState({ confirmChecked })}
                        control={<Checkbox />}
                        label={i18n`I confirm that none of the listed items above are present at location ${location}`}
                    />
                </FormGroup>
                <Grid container style={{ marginTop: 16 }} spacing={16}>
                    <Grid item xs>
                        <Button variant="contained" color="primary" disabled={!this.state.confirmChecked} fullWidth onClick={this.onConfirmNullClick}>{i18n`Save`}</Button>&nbsp;
                    </Grid>
                    <Grid item xs>
                        <Button variant="contained" fullWidth onClick={this.onCancelNullClick}>{i18n`Cancel`}</Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderAlreadyCounted() {
        const { showAlreadyCountedDialog, action } = this.state;
        const { draft } = this.props.match.params;

        const onDialogClose = () => {
            this.setState({ showAlreadyCountedDialog: undefined });
            this.props.scanClear();
        }

        return (
            <Dialog fullScreen open={true}>
                <AppBar title="Attention" style={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={onDialogClose} style={{ marginLeft: -8 }}>
                            <Close />
                        </IconButton>
                        <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                            {i18n`WARNING`}
                        </Typography>
                        <Button color="inherit" style={{ marginRight: -16 }} onClick={() => showAlreadyCountedDialog?.confirm(action as any)}>
                            OK
                        </Button>
                    </Toolbar>
                </AppBar>
                {
                    (showAlreadyCountedDialog?.result.draft !== null && showAlreadyCountedDialog?.result.draft !== draft) ? (
                        <div style={{ padding: 16 }}>
                            <Typography>
                                This item was already counted on a different draft. Please contact the
                                supervisor for help.
                            </Typography>
                        </div>
                    ) : (
                            <div style={{ padding: 16 }}>
                                <Typography>
                                    This item has already been counted at this location
                            </Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Quantity</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{showAlreadyCountedDialog?.result.date}</TableCell>
                                            <TableCell>{showAlreadyCountedDialog?.result.qty}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <RadioGroup onChange={event => this.setState({ action: (event.target as HTMLInputElement).value })} value={this.state.action} style={{ marginTop: 16 }}>
                                    <FormControlLabel value="overwrite" control={<Radio />} label={
                                        <><span>Overwrite the current count.</span><span style={{ fontWeight: "bold" }}>Qty = {parseFloat(this.state.quantity)}</span></>
                                    } />
                                    <FormControlLabel value="add" control={<Radio />} label={
                                        <><span>Add to the current count. </span><span style={{ fontWeight: "bold" }}>Qty = {showAlreadyCountedDialog!.result.qty + parseFloat(this.state.quantity)}.</span></>
                                    } />
                                    <FormControlLabel value="cancel" control={<Radio />} label={
                                        <><span>Do nothing.</span> <span style={{ fontWeight: "bold" }}>Qty = {showAlreadyCountedDialog!.result.qty}</span></>
                                    } />
                                </RadioGroup>
                            </div>
                        )
                }
            </Dialog>
        );
    }

    render() {
        const { warehouse, draft } = this.props.match.params;
        const { scannedItem = undefined, scannedLocation = undefined, previousLocation } = this.props;
        const { showAlreadyCountedDialog } = this.state;

        if (showAlreadyCountedDialog !== undefined) {
            return this.renderAlreadyCounted();
        }

        if (previousLocation !== undefined) {
            return this.renderLocationChanged();
        }

        return (
            <div>
                { this.showLoader() ? this.renderLoader() : null}
                <Grid container spacing={8} alignItems="flex-end" justify="center" >
                    <Grid item xs={12} style={{ marginTop: 16, textAlign: "center" }}>
                        <img src={scanIcon} style={{ width: "30vh" }} alt="Barcode Icon" />
                    </Grid>
                </Grid>
                <Paper style={{ padding: 8, marginTop: 16 }}>
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <Typography variant="caption">{i18n`Warehouse`}</Typography>
                            <Typography variant="body1">{warehouse}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption">{i18n`Draft`}</Typography>
                            <Typography variant="body1">{draft}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <ScanInput label={i18n`Location`} value={scannedLocation} />
                <ScanInput label={i18n`Item`} value={scannedItem} />
                {this.showDialog() ? this.renderDialog() : null}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);