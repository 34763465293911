import * as React from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import DayjsUtils from "@date-io/dayjs";
import leftPad from "left-pad";
import { withStyles } from '@material-ui/core/styles';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AlarmIcon from "@material-ui/icons/Alarm";

import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import { getManualEntries, TimeRecordingState, ManualEntryEvent, getActivityTypes, getElementTypes, getAbsenceTypes, CheckinEvent } from "../state";

import EventList from "../components/EventList";

import DateTitle from "./Manuel/DateTitle";
import AlertDialog from "./Manuel/AlertDialog";
import { DeleteAction } from "./Manuel/DeleteAction";
import { ModuleProps } from "../../config";
import { addManualTimeEntry, deleteManuelTimeEntry, fetchManualTimeEntries } from "../state/action";
import AddDialog from "./Manuel/AddDialog";
import i18n from "es2015-i18n-tag";
import CheckinDialog from "./Manuel/CheckinDialog";

const styles = (theme: any) => ({
    root: {
        // ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    } as any,
    picker: {
        ...theme.mixins.gutters()
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    } as any
});

interface Props extends ModuleProps
{
    classes: ReturnType<typeof styles>
}

interface State
{
    date: dayjs.Dayjs;
    eventToDelete?: ManualEntryEvent;
    addEvent: boolean;
    checkin: boolean;
}

const mapStateToProps = (state: TimeRecordingState, ownProps: Props) => ({
    entries: ownProps.username ? getManualEntries(state, ownProps.username) : undefined,
    activityTypes: getActivityTypes(state),
    elementTypes: getElementTypes(state),
    absenceTypes: getAbsenceTypes(state)
});

const mapDispatchToProps = (dispatch: any, { username }: Props) => ({
    addEntry: (entry: ManualEntryEvent) => username ? dispatch(addManualTimeEntry(username, entry)) : false,
    deleteEntry: (entry: ManualEntryEvent) => username ? dispatch(deleteManuelTimeEntry(username, entry.id)) : false,
    fetchEntries: () => username ? dispatch(fetchManualTimeEntries(username)) : false
});

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Manuel extends React.Component<Props & StateProps, State>
{
    state: State = { date: dayjs().startOf("day"), addEvent: false , checkin: false };

    componentWillMount()
    {
        this.props.fetchEntries();
    }

    private onDeleteClick = (event: ManualEntryEvent) => 
    {
        this.setState({ eventToDelete: event });
    }

    private onHandleDeleteCancel = () => this.setState({ eventToDelete: undefined });

    private onHandleDelete = () => 
    {
        const e = this.state.eventToDelete;
        if (e !== undefined)
        {
            this.props.deleteEntry(e);
        }
        this.setState({ eventToDelete: undefined });
    }

    private onHandleAddCancel = () => this.setState({ addEvent: false });

    private onHandleAdd = (entry: ManualEntryEvent) => 
    {
        this.props.addEntry(entry);
        this.setState({ addEvent: false });
    };

    private formatDuration = (duration: number) => 
        `${Math.floor(duration / 60)}:${leftPad(duration % 60, 2, "0")}`;

    private getSecondaryText = (entry: ManualEntryEvent) =>
        (entry.type === "ABSENCE" && entry.quantity !== undefined) ? i18n`Quantity: ` + entry.quantity :
        (entry.quantity !== undefined) ? this.formatDuration(entry.quantity) : "";

    private onCheckin = (info: CheckinEvent) =>
    {
        const { transactionId, date, ...rest } = info;
        const entry: ManualEntryEvent = {
            ...rest,
            day: dayjs(this.state.date).format() ,
            deleted: false
        };
        this.props.addEntry(entry);
    }

    render()
    {
        const { eventToDelete, date, addEvent, checkin } = this.state;
        const { classes, entries = [], activityTypes, elementTypes, absenceTypes, username = "" } = this.props;
        const minDate = dayjs().subtract(30, "day").toDate();
        const maxDate = dayjs().add(5, "day").toDate();

        const now = dayjs(date).startOf("day");
        const filteredEntries = entries.filter(entry => !entry.deleted && dayjs(entry.day).isSame(now));
        const isCheckedIn = filteredEntries.findIndex(e => e.type === "CHECK_IN") > -1;

        return (
            <>
                <AlertDialog open={!!eventToDelete} onHandleClose={this.onHandleDeleteCancel} onConfirm={this.onHandleDelete} />
                {
                    (addEvent) ? (
                        <AddDialog 
                            activityTypes={activityTypes} 
                            elementTypes={elementTypes} 
                            absenceTypes={absenceTypes}
                            date={date.format()} 
                            onAddEntry={this.onHandleAdd}
                            onCancel={this.onHandleAddCancel}
                            username={username} />
                    ) : null
                }
                {
                    (!isCheckedIn && checkin) ? (
                        <CheckinDialog onCheckin={this.onCheckin} />
                    ) : null
                }

                <MuiPickersUtilsProvider utils={DayjsUtils}>
                    <div className={classes.root}>
                        <div className={classes.picker}>
                            <DatePicker value={date} onChange={date => this.setState({ date })} TextFieldComponent={DateTitle} minDate={minDate} maxDate={maxDate} />
                        </div>
                        <EventList
                            events={filteredEntries.filter(e => e.type !== "CHECK_IN")}
                            getSecondaryText={(entry: ManualEntryEvent) => this.getSecondaryText(entry) }
                            secondaryAction={(e: ManualEntryEvent) => <DeleteAction event={e} onClick={this.onDeleteClick} />} />
                        { 
                            isCheckedIn ? (
                                <Fab color="primary" className={classes.fab} onClick={() => this.setState({ addEvent: true })}>
                                    <AddIcon />
                                </Fab>
                            ) : (
                                <Fab color="primary" className={classes.fab} onClick={() => this.setState({ checkin: true })}>
                                    <AlarmIcon />
                                </Fab>
                            )
                        }
                    </div>
                </MuiPickersUtilsProvider>
            </>
        );
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Manuel));
