import { Dispatch } from "redux";
import { Event, CheckinEvent } from "../typings";
import { generateUuid } from "../../../../util/uuid";
import { OfflineAction } from "../../../../types";
import Api from "../../../../api/api";
import { createOfflineMeta } from "../../../../util/offline";

//
// Action: TRANSACTION_ADD
//
export const TRANSACTION_OPEN = "TRANSACTION_OPEN";
export interface TransactionOpenAction extends OfflineAction
{
    type: "TRANSACTION_OPEN";
    id: string;
    username: string;
    event: CheckinEvent;
}
export const transactionOpen = (username: string, event: CheckinEvent, id = generateUuid()): TransactionOpenAction => ({
    type: TRANSACTION_OPEN,
    id,
    username,
    event: { ...event, transactionId: id },
    meta: createOfflineMeta(username, "time-tracking/entry", "PUT", { ...event, transactionId: id })
});

//
// Action: TRANSACTION_CLOSE
//
export const TRANSACTION_CLOSE = "TRANSACTION_CLOSE";
export interface TransactionCloseAction
{
    type: "TRANSACTION_CLOSE";
    id: string;
    username: string;
}
export const transactionClose = (username: string, id: string): TransactionCloseAction => ({
    type: TRANSACTION_CLOSE,
    id,
    username
});

//
// Action: EVENT_ADD
//
export const TRANSACTION_EVENT_ADD = "TRANSACTION_EVENT_ADD";
export interface TransactionEventAddAction extends OfflineAction
{
    type: "TRANSACTION_EVENT_ADD";
    transactionId: string;
    event: Event;
}
export const transactionEventAdd = (event: Event): TransactionEventAddAction => ({
    type: TRANSACTION_EVENT_ADD,
    transactionId: event.transactionId,
    event,
    meta: createOfflineMeta(event.username, "time-tracking/entry", "PUT", event)
});

//
// Action: TRANSACTION_CHECKIN
//
export const TRANSACTION_CHECKIN = "TRANSACTION_CHECKIN";
export interface TransactionCheckinAction
{
    type: "TRANSACTION_CHECKIN";
    checkin: string;
    absence?: string;
}
/**
 * Adds a check-in event to the given transaction
 * @param checkin 
 * @param absence 
 */
export const transactionCheckin = (checkin: string, absence?: string): TransactionCheckinAction => ({
    type: TRANSACTION_CHECKIN,
    checkin,
    absence
});

export const fetchTransactions = () => (dispatch: Dispatch<any>) => 
{
    Api.request("time-tracking/entry").then(result => console.log(result));
};