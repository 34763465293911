import * as React from "react";
import { useState } from "react";
import i18n from "es2015-i18n-tag";
import { Grid, TextField, MenuItem, Button } from "@material-ui/core";
import { Draft } from "../../api/StockApi";

export interface Props
{
    warehouse: string;
    drafts: Draft[];
    navigate: (relativePath: string) => void;
}

function SelectDraft(props: Props)
{
    const { warehouse = "", drafts, navigate } = props;
    const [draft, setDraft] = useState("");
    
    return (
        <>
            <Grid container spacing={8} alignItems="flex-end">
                <Grid item xs={12}>
                    <TextField 
                        select fullWidth 
                        value={draft}
                        name="draft"
                        onChange={e => setDraft(e.target.value)}
                        label={i18n`Draft`}>
                        <MenuItem value="" disabled/>
                        {
                            drafts.map(draft => (
                                <MenuItem key={draft.id} value={draft.id}>{draft.name}</MenuItem>
                            ))
                        }
                    </TextField>
                </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="baseline" style={{marginTop: 16}}>
                <Grid item>
                    <Button size="large" fullWidth variant="outlined" 
                        disabled={warehouse === "" || draft === undefined || draft === ""}
                        onClick={() => navigate(`${draft}`)}>
                        {i18n`Select`}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default SelectDraft;