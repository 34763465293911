import * as React from "react";
import { findDOMNode } from "react-dom";
import { useState, useCallback, useMemo } from "react";
import i18n from "es2015-i18n-tag";
import { ManualEntryEvent, JobActivity, JobElement, Absence } from "../../state";
import { generateUuid } from "../../../../util/uuid";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment  from "@material-ui/core/InputAdornment";
import withMobileDialog, { InjectedProps } from '@material-ui/core/withMobileDialog';

export interface Props
{
    date: string;
    username: string;
    elementTypes: JobElement[];
    activityTypes: JobActivity[];
    absenceTypes: Absence[];
    onAddEntry: (entry: ManualEntryEvent) => void;
    onCancel: () => void;
}

interface AddFormProps
{
    entry: ManualEntryEvent;
    onChange: (entry: ManualEntryEvent) => void;
}

const isValidNumber = (q: number | undefined): q is number => q !== undefined && !isNaN(q);

const fieldStyle: React.CSSProperties = { marginTop: 16 };

function useOutlinedSelect(): [(input: InputLabel) => void, JSX.Element]
{
    const [labelWidth, setLabelWidth] = useState(0);
    const ref = useCallback((label: InputLabel) => {
        const node = findDOMNode(label) as HTMLLabelElement;
        if(node !== null)
        {
            setLabelWidth(node.getBoundingClientRect().width);
        }
    }, []);
    
    const input = useMemo(() => <OutlinedInput labelWidth={labelWidth} />, [labelWidth]);
    return [ref, input];
}

interface OrderFormProps extends AddFormProps
{
    elementTypes: JobElement[];
}

function AddOrderForm(props: OrderFormProps)
{
    const { entry, onChange, elementTypes } = props;
    const [ref, input] = useOutlinedSelect();

    const onOrderNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange({ ...entry, orderNo: parseInt(e.target.value) });
    const onElementNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange({ ...entry, elementNumber: parseInt(e.target.value) });
    const onTypeFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => onChange({ ...entry, elementType: e.target.value, quantity: 0 });

    return (
        <>
            <TextField type="number" fullWidth variant="outlined" style={fieldStyle} label={i18n`Order Number`} value={entry.orderNo || ""} onChange={onOrderNumberChange} />
            <TextField type="number" fullWidth variant="outlined" style={fieldStyle} label={i18n`Element Number`} value={entry.elementNumber || ""} onChange={onElementNumberChange} />
            <FormControl fullWidth style={fieldStyle} variant="outlined">
                <InputLabel ref={ref}>{i18n`Type`}</InputLabel>
                <Select value={entry.elementType || ""} onChange={onTypeFieldChange} input={input}>
                {
                    elementTypes.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)
                }
                </Select>
            </FormControl>
                <TextField 
                    fullWidth
                    key={entry.elementType}
                    variant="outlined"
                    label={
                        (entry.elementType === "122") ? i18n`Distance` : 
                        (entry.elementType === "120") ? i18n`Duration` : "Distance / duration"
                    }
                    style={fieldStyle}
                    value={entry.quantity !== undefined && isNaN(entry.quantity) ? "" : entry.quantity} 
                    type="number"
                    disabled={!entry.elementType}
                    onChange={e => onChange({...entry, quantity: parseInt(e.target.value)})} 
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{
                            (entry.elementType === "122") ? "km" : 
                            (entry.elementType === "120") ? "min" : ""
                        }</InputAdornment>,
                    }}
                />
        </>
    );
}

function AddOtherForm(props: AddFormProps & { activityTypes: JobActivity[] })
{
    const { entry, onChange, activityTypes } = props;
    const [ref, input] = useOutlinedSelect();
    const onSelectFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => onChange({ ...entry, activityType: e.target.value });
    const onDurationFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange({ ...entry, quantity: parseInt(e.target.value) })

    return (
        <>
            <FormControl fullWidth style={fieldStyle} variant="outlined">
                <InputLabel ref={ref}>{i18n`Type`}</InputLabel>
                <Select value={entry.activityType || ""} onChange={onSelectFieldChange} input={input}>
                {
                    activityTypes.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)
                }
                </Select>
            </FormControl>
            <TextField 
                fullWidth
                variant="outlined"
                label={i18n`Duration (in minutes)`}
                style={fieldStyle}
                value={!isValidNumber(entry.quantity) ? "" : entry.quantity} 
                type="number"
                onChange={onDurationFieldChange} 
                InputProps={{
                    endAdornment: <InputAdornment position="end">min</InputAdornment>,
                    }}
                />
        </>
    );
}

interface AddAbsenceProps extends AddFormProps
{
    absenceTypes: Absence[];
}

function AddAbsence(props: AddAbsenceProps)
{
    const { entry, onChange, absenceTypes } = props;
    const [ref, input] = useOutlinedSelect();
    
    const onSelectFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => onChange({ ...entry, absence: e.target.value });
    const onQuantityFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange({ ...entry, quantity: parseInt(e.target.value) });

    return (
        <>
            <FormControl fullWidth style={fieldStyle} variant="outlined">
                <InputLabel ref={ref}>{i18n`Activity`}</InputLabel>
                <Select value={entry.absence || ""} onChange={onSelectFieldChange} input={input}>
                {
                    absenceTypes.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)
                }
                </Select>
            </FormControl>
            <TextField 
                fullWidth
                variant="outlined"
                label={i18n`Duration (in minutes)`}
                style={fieldStyle}
                value={!isValidNumber(entry.quantity) ? "" : entry.quantity} 
                type="number"
                onChange={onQuantityFieldChange} 
                // InputProps={{
                //     endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                //     }}
                />
        </>    );
}

const isEntryValid = (entry: ManualEntryEvent) => 
    (isValidNumber(entry.quantity) && entry.quantity > 0)
    && ((entry.type === "ACTIVITY_ORDER" && entry.orderNo !== undefined && entry.orderNo !== undefined && !isNaN(entry.orderNo))
     || (entry.type === "ACTIVITY_OTHER" && entry.activityType !== undefined)
     || (entry.type === "ABSENCE" && entry.absence !== undefined && isValidNumber(entry.quantity))
    );
     //|| (entry.type === "ACTIVITY_PROJECT" && entry.elementType !== undefined));

export function AddDialog(props: Props & InjectedProps)
{
    const { elementTypes, activityTypes, fullScreen, absenceTypes } = props;
    const [ref, input] = useOutlinedSelect();
    const [entry, setEntry] = useState<ManualEntryEvent>({
        id: generateUuid(),
        type: "ACTIVITY_ORDER",
        day: props.date,
        deleted: false,
        username: props.username
    });

    return (
        <Dialog open={true} fullScreen={fullScreen}>
            <DialogTitle>{i18n`Add Entry`}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth variant="outlined" style={fieldStyle}>
                    <InputLabel ref={ref}>{i18n`Type`}</InputLabel>
                    <Select 
                        input={input}
                        value={entry.type} 
                        onChange={e => setEntry({ ...entry, type: e.target.value as any })}>
                        <MenuItem value="ACTIVITY_ORDER">{i18n`Order`}</MenuItem>
                        <MenuItem value="ACTIVITY_OTHER">{i18n`Other`}</MenuItem>
                        <MenuItem value="ABSENCE">{i18n`Absence`}</MenuItem>
                    </Select>
                </FormControl>
                {
                    (entry.type === "ACTIVITY_ORDER") ? (
                        <AddOrderForm entry={entry} onChange={setEntry} elementTypes={elementTypes} />
                    ) : (entry.type === "ACTIVITY_OTHER") ? (
                        <AddOtherForm entry={entry} onChange={setEntry} activityTypes={activityTypes} />
                    ) : (entry.type === "ABSENCE") ? (
                        <AddAbsence entry={entry} onChange={setEntry} absenceTypes={absenceTypes} />
                    ) : null
                }

            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onCancel}>
                    {i18n`Cancel`}
                </Button>
                <Button color="primary" disabled={!isEntryValid(entry)} variant="contained" onClick={() => props.onAddEntry(entry)} >
                    {i18n`Add`}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog<Props>()(AddDialog);