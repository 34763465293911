import { ManualEntryEvent } from "..";
import Api from "../../../../api/api";
import { createOfflineMeta } from "../../../../util/offline";
import { OfflineAction } from "../../../../types";

//
// Action: FETCH_MANUAL_TIME_ENTRIES
//
export const FETCH_MANUAL_TIME_ENTRIES = "FETCH_MANUAL_TIME_ENTRIES";
export interface FetchManualTimeEntriesAction
{
    type: "FETCH_MANUAL_TIME_ENTRIES";
}

export const fetchManualTimeEntries = (username: string) => (dispatch: any) => (async () => 
{
    const initAction: FetchManualTimeEntriesAction = { type: "FETCH_MANUAL_TIME_ENTRIES" };
    dispatch(initAction);
    try
    {
        const response = await Api.request("time-tracking/manual");
        if (response.ok)
        {
            const events: ManualEntryEvent[] = await response.json();
            dispatch(fetchManualTimeEntriesSuccess(username, events));
        }
        else
        {
            throw response.statusText;
        }
    }
    catch(e)
    {
        dispatch(FetchManualTimeEntriesFailure(e));
    }
})();

//
// Action: FETCH_MANUAL_TIME_ENTRIES_SUCCESS
//
export const FETCH_MANUAL_TIME_ENTRIES_SUCCESS = "FETCH_MANUAL_TIME_ENTRIES_SUCCESS";
export interface FetchManualTimeEntriesSuccessAction
{
    type: "FETCH_MANUAL_TIME_ENTRIES_SUCCESS";
    username: string;
    events: ManualEntryEvent[];
}
export const fetchManualTimeEntriesSuccess = (username: string, events: ManualEntryEvent[]): FetchManualTimeEntriesSuccessAction => ({
    type: FETCH_MANUAL_TIME_ENTRIES_SUCCESS,
    username,
    events
});

//
// Action: FETCH_MANUAL_TIME_ENTRIES_FAILURE
//
export const FETCH_MANUAL_TIME_ENTRIES_FAILURE = "FETCH_MANUAL_TIME_ENTRIES_FAILURE";
export interface FetchManualTimeEntriesFailureAction
{
    type: "FETCH_MANUAL_TIME_ENTRIES_FAILURE";
    error: any;
}
export const FetchManualTimeEntriesFailure = (error: any): FetchManualTimeEntriesFailureAction => ({
    type: FETCH_MANUAL_TIME_ENTRIES_FAILURE,
    error
});

//
// Action: DELETE_MANUEL_TIME_ENTRY
//
export const DELETE_MANUEL_TIME_ENTRY = "DELETE_MANUEL_TIME_ENTRY";
export interface DeleteManuelTimeEntryAction extends OfflineAction
{
    type: "DELETE_MANUEL_TIME_ENTRY";
    id: string;
    username: string;
}
export const deleteManuelTimeEntry = (username: string, id: string): DeleteManuelTimeEntryAction => ({
    type: DELETE_MANUEL_TIME_ENTRY,
    id,
    username,
    meta: createOfflineMeta(username, `time-tracking/manual/${id}`, "DELETE")
});

//
// Action: ADD_MANUAL_TIME_ENTRY
//
export const ADD_MANUAL_TIME_ENTRY = "ADD_MANUAL_TIME_ENTRY";
export interface AddManualTimeEntryAction extends OfflineAction
{
    type: "ADD_MANUAL_TIME_ENTRY";
    entry: ManualEntryEvent;
    username: string;
}
export const addManualTimeEntry = (username: string, entry: ManualEntryEvent): AddManualTimeEntryAction => ({
    type: ADD_MANUAL_TIME_ENTRY,
    entry, username,
    meta: createOfflineMeta(username, "time-tracking/manual", "PUT", entry)
});