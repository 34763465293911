import ClientOAuth2 from "client-oauth2";
import { Token } from "client-oauth2";
import { TokenStore } from "../service/TokenStore";
import jwt_decode from "jwt-decode";

interface JwtToken
{
    sub: string;
    role?: string[] | string;
}

class Auth
{
    private client_: ClientOAuth2;
    private token_?: Token;
    private store_?: TokenStore;
    private callbacks_: { [key: string]: Function[] } = {};

    constructor(store?: TokenStore) 
    {
        this.client_ = new ClientOAuth2({
            clientId: "mobility.app",
            clientSecret: "Motedulghot9",
            authorizationUri: "https://identity.guldmann.com/connect/authorize",
            accessTokenUri: "https://identity.guldmann.com/connect/token",
            scopes: ["mobility", "offline_access"]
        });
        this.store_ = store;
    }

    // addEventListener(type: "logout", callback: () => void);
    addEventListener(type: string, callback: () => void)
    {
        (this.callbacks_[type] = this.callbacks_[type] || []).push(callback);
    }

    removeEventListener(type: string, callback: () => void)
    {
        if(this.callbacks_[type])
        {
            this.callbacks_[type] = this.callbacks_[type].filter(c => c !== callback);
        }
    }

    private emit(type: string) 
    {
        if(this.callbacks_[type])
        {
            this.callbacks_[type].forEach(cb => cb());
        }
    }

    async loadToken()
    {
        if(this.store_ !== undefined)
        {
            const token = await this.store_.load();
            if(token !== null)
            {
                this.token_ = this.client_.createToken(token.data);

                const decoded: any = jwt_decode(token.accessToken);
                if(decoded && typeof decoded.exp === "number")
                {
                    // XXX token.expires
                    (this.token_ as any).expires = new Date(decoded.exp * 1000);
                }
                else 
                {
                    // XXX token.expires
                    (this.token_ as any).expires = new Date(0);
                }
            }
        }
    }

    private async saveToken()
    {
        if(this.store_ !== undefined)
        {
            if(this.token !== undefined)
            {
                await this.store_.save(this.token);
            }
            else
            {
                this.store_.clear();
            }
        }
    }

    async login(username: string, password: string): Promise<void>
    {
        const token = await this.client_.owner.getToken(username, password);
        this.token_ = token;
        await this.saveToken();
        this.emit("login");
    }

    async logout(): Promise<void>
    {
        this.token_ = undefined;
        await this.saveToken();
        this.emit("logout");
    }

    get token(): Token | undefined
    {
        return this.token_;
    }

    async isAuthenticated() : Promise<boolean>
    {
        if(this.token_ === undefined)
        {
            return false;
        }

        // XXX token.expireds
        if((this.token_ as any).expires < new Date())
        {
            try 
            {
                await this.refresh();
            }
            catch (e)
            {
                this.logout();
                await this.logout();
                return false;
            }
        }
        return true;
    }

    async refresh()
    {
        if(this.token_ === undefined)
        {
            throw Error("You must call login before using fetch with authentication");
        }
        this.token_ = await this.token_.refresh();
        await this.saveToken();
    }

    get username(): string | undefined {
        if(this.token_)
        {
            try 
            {
                const token = jwt_decode(this.token_.accessToken) as JwtToken;
                return token.sub;
            }
            catch(e)
            {
                console.error(e);
            }
        }
        return undefined;
    }

    get roles() : string[]
    {
        if(this.token_)
        {
            try 
            {
                const token = jwt_decode(this.token_.accessToken) as JwtToken;
                if(typeof token.role === "string")
                {
                    return [token.role];
                }
                return token.role || [];
            }
            catch(e)
            {
                console.error(e);
            }
        }
        return [];
    }

    getAuthenticatedFetch() 
    {
        const af = async (input: Request | string, init?: RequestInit): Promise<Response> =>
        {
            if(this.token_ === undefined)
            {
                throw Error("You must call login before using fetch with authentication");
            }

            const req: Request = new Request(input, { ...(init || {}), credentials: "include", });
            req.headers.set("Authorization", `Bearer ${this.token_.accessToken}`);
            try 
            {
                const response = await fetch(req);
                if(response.status === 401) // access-token expired
                {
                    await this.refresh();
                    return await af(input, init);
                }
                if(response.status === 400)
                {
                    const body = await response.json();
                    await this.logout();
                    throw body.error; // invalid_grant, etc...
                }
                return response;
            }
            catch(e)
            {
                console.error({e});
                throw e;
            }
        }
        return af;
    }
}

export default Auth;