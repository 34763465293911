import * as React from "react";
import { useMemo } from "react";
import { Feature } from "../../types";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";

export type CheckedFeature = Feature & { checked: boolean };

interface FeatureListProps
{
    className?: string;
    features: CheckedFeature[];
    onFeatureCheck: (feature: Feature, checked: boolean) => void;
} 

export function FeatureList(props: FeatureListProps)
{
    const { features, className, onFeatureCheck } = props;

    const onCheckboxChange = useMemo(() => features
        .map(f => (e: React.ChangeEvent<HTMLInputElement>) => onFeatureCheck(f, e.target.checked))
    , [features, onFeatureCheck]);

    return (
        <List className={className}>
            {features.map((feature, i) => (
                <ListItem key={feature.bit} button>
                    {/* <ListItemIcon>
                        <div />
                    </ListItemIcon> */}
                    <ListItemText primary={feature.label} />
                    <ListItemSecondaryAction>
                        <Checkbox onChange={onCheckboxChange[i]} checked={feature.checked} />
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
}