import i18n from "es2015-i18n-tag";
import Timer from "@material-ui/icons/Timer";
import TimeRecording from ".";
import { Module } from "../config";

const config: Module =
{
    title: i18n`Time Recording`, 
    icon: Timer, 
    component: TimeRecording, 
    url: "/time-recording",
    roles: ["mobility.time"]
}

export default config;
