import produce from "immer";
import { LabelActions } from "../action/label";
import { Printer } from "../types";

interface State
{
    // labels: LabelTypes[];
    printers: Printer[];

    ui: {
        fetchingLabels: boolean;
        fetchingPrinters: boolean;
        errorLabels: null | string;
        errorPrinters: null | string;
        requestingLabel: boolean;
        errorLabelRequest: null | string;
    }
}

const initialState: State = 
{
    // labels: [],
    printers: [],
    ui: {
        fetchingLabels: false,
        fetchingPrinters: false,
        errorLabels: null,
        errorPrinters: null,
        errorLabelRequest: null,
        requestingLabel: false,
    }
};

const reducer = (state = initialState, action: LabelActions): State => produce(state, () => 
{
    if(action.type ===  "FETCH_PRINTER_LIST")
    {
        state.ui.fetchingPrinters = true;
        state.ui.errorPrinters = null;
        return;
    }

    if(action.type === "FETCH_PRINTER_LIST_SUCCESS")
    {
        state.ui.fetchingPrinters = false;
        state.printers = action.printers;
        return;
    }

    if(action.type === "FETCH_PRINTER_LIST_FAILURE")
    {
        state.ui.fetchingPrinters = false;
        state.ui.errorPrinters = action.error.toString();
        return;
    }

    if(action.type ===  "FETCH_LABEL_TYPES")
    {
        state.ui.fetchingLabels = true;
        state.ui.errorLabels = null;
        return;
    }

    // if(action.type === "FETCH_LABEL_TYPES_SUCCESS")
    // {
    //     state.ui.fetchingLabels = false;
    //     state.labels = action.labels;
    //     return;
    // }

    // if(action.type === "FETCH_LABEL_TYPES_FAILURE")
    // {
    //     state.ui.fetchingLabels = false;
    //     state.ui.errorLabels = action.error.toString();
    //     return;
    // }

    if(action.type === "REQUEST_LABEL_PRINT")
    {
        state.ui.requestingLabel = true;
        state.ui.errorLabelRequest = null;
        return;
    }

    if(action.type === "REQUEST_LABEL_PRINT_SUCCESS")
    {
        state.ui.requestingLabel = false;
        return;
    }

    if(action.type === "REQUEST_LABEL_PRINT_FAILURE")
    {
        state.ui.requestingLabel = false;
        state.ui.errorLabelRequest = action.error.toString();
        return;
    }

    if(action.type === "INIT_LABEL_STATE")
    {
        state.ui = { ...initialState.ui };
        return;
    }

});

// export const getLabelPrinters = (state: State) => state.printers;
// export const getLabelTypes = (state: State) => state.labels;
// export const getLabelPrintersLoading = (state: State) => state.ui.fetchingPrinters;
// export const getLabelTypesLoading = (state: State) => state.ui.fetchingLabels;
// export const getLabelTypesError = (state: State) => state.ui.errorLabels;
// export const getLabelPrintersError = (state: State) => state.ui.errorPrinters;
// export const getLabelRequestActive = (state: State) => state.ui.requestingLabel;
// export const getLabelRequestError = (state: State) => state.ui.errorLabelRequest;

export default reducer;