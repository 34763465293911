import { produce } from "immer";
import { toMap } from "../../../util/collection";
import { TimeRecordingState, Transaction, OrderedById, Event, ManualEntryEvent } from "./typings";
import { transactionType, absenceType, elementType, activityType } from "./initial";
import { TRANSACTION_OPEN, TransactionOpenAction, TRANSACTION_EVENT_ADD, TransactionEventAddAction, 
    FetchManualTimeEntriesSuccessAction, DeleteManuelTimeEntryAction, 
    AddManualTimeEntryAction } from "./action";
import { TRANSACTION_CLOSE, TransactionCloseAction } from "./action";
import { assertNever } from "../../../util";

const toOrderedById = <T>(collection: T[], keySelector: (item: T) => string) => ({
    byId: toMap(collection, keySelector),
    list: collection.map(keySelector)
});

const initialState: TimeRecordingState =
{
    data: 
    {
        checkinout: toOrderedById(transactionType, x => x.id),
        absence: toOrderedById(absenceType, x => x.id),
        activity: toOrderedById(activityType, x => x.id),
        element: toOrderedById(elementType, x => x.id)
    },
    
    transaction: {
        current: {},
        byId: {},
        list: []
    },

    checkin: {},
    
    manual: {},
}

type Action = 
    TransactionOpenAction | TransactionCloseAction | TransactionEventAddAction 
    | FetchManualTimeEntriesSuccessAction
    | DeleteManuelTimeEntryAction
    | AddManualTimeEntryAction
    ;

type Dict<T> = { [id: string]: T };
const createDict = <T>(ts: T[], keySelector: (t: T) => string): Dict<T> => 
    ts.reduce((result: Dict<T>, v) => { result[keySelector(v)] = v; return result; }, {});

const mergeEvents = (a: ManualEntryEvent[], b: ManualEntryEvent[]): ManualEntryEvent[] => 
{
    const index = createDict(a, x => x.id);
    for(let b0 of b)
    {
        if(b0.id in index)
        {
            const a0 = index[b0.id];
            a0.deleted = a0.deleted || b0.deleted;
        }
        else
        {
            a.push(b0);
        }
    }
    return a;
}

const reducer = (state = initialState, action: Action): TimeRecordingState => produce(state, draft => 
{
    if(action.type === TRANSACTION_OPEN)
    {
        const { id, event, username } = action;
        draft.transaction.current[username] = id;
        draft.transaction.byId[id] = { id, events: [event], username };
        draft.transaction.list.push(id);
        return;
    }

    if(action.type === TRANSACTION_CLOSE)
    {
        const { id, username } = action;
        delete draft.transaction.current[username];
        draft.transaction.byId[id] = { ...draft.transaction.byId[id] };
        return;
    }

    if(action.type === TRANSACTION_EVENT_ADD)
    {
        const { event, transactionId } = action;
        draft.transaction.byId[transactionId].events.push(event);
        return;
    }

    if (action.type === "FETCH_MANUAL_TIME_ENTRIES_SUCCESS")
    {
        const { events, username } = action;
        draft.manual[username] = mergeEvents(draft.manual[username] || [], events);
        return;
    }

    if (action.type === "ADD_MANUAL_TIME_ENTRY")
    {
        const { username, entry } = action;
        draft.manual[username] = draft.manual[username] || [];
        draft.manual[username].push(entry);
        return;
    }

    if (action.type === "DELETE_MANUEL_TIME_ENTRY")
    {
        const { id, username } = action;
        if(username in draft.manual)
        {
            const entry = draft.manual[username].find(v => v.id === id)
            if(entry !== undefined)
            {
                entry.deleted = true;
            }
        }
        return;
    }
    
    assertNever(action);
});

const getOrderedList = <T>(state: OrderedById<T>) =>
    state.list.map(id => state.byId[id]).filter(item => item !== undefined);

export const getTransactions = (state: TimeRecordingState): Transaction[] =>
    state.transaction.list.map(id => state.transaction.byId[id]);

export const getTransaction = (state: TimeRecordingState, transactionId: string) =>
    state.transaction.byId[transactionId];

export const getCurrentTransaction = (username: string, state: TimeRecordingState): Transaction | undefined => 
{
    const { current, byId } = state.transaction;
    return current[username] ? byId[current[username]] : undefined;
}

export const getEvents = (state: TimeRecordingState, transactionId: string): Event[] =>
{
    const transaction = getTransaction(state, transactionId);
    return (transaction === undefined) ? [] : transaction.events;
}

export const getCheckinTypes = (state: TimeRecordingState) =>
    getOrderedList(state.data.checkinout);

export const getAbsenceTypes = (state: TimeRecordingState) =>
    getOrderedList(state.data.absence);

export const getElementTypes = (state: TimeRecordingState) =>
    getOrderedList(state.data.element);

export const getActivityTypes = (state: TimeRecordingState) =>
    getOrderedList(state.data.activity);

export const getCheckin = (state: TimeRecordingState) => 
    state.checkin.type;

export const getManualEntries = (state: TimeRecordingState, username: string) => 
    state.manual[username];

export * from "./typings";
export default reducer;