
export type BarcodeType = "container" | "item" | "location" | "unknown";

export class Scanner
{
    private scanHandle: number = -1;
    private scannedSymbols: string = "";
    private onSuccess: (barcode: string, type: BarcodeType) => void;
    private detectType: boolean;

    constructor(onSuccess: (barcode: string) => void);
    constructor(onSuccess: (barcode: string, type: BarcodeType) => void, detectType: true);
    constructor(onSuccess: (barcode: string, type: BarcodeType) => void, detectType = false)
    {
        this.onSuccess = onSuccess;
        this.detectType = detectType;
        window.addEventListener("keyup", this.onKeyup);
    }

    destroy()
    {
        window.removeEventListener("keyup", this.onKeyup);
    }

    private isContainer = (barcode: string | undefined) => (barcode !== undefined) && (barcode.length === 10) && (barcode.substr(0, 1).toUpperCase() === "C");

    private getBarcodeType(symbols: string): BarcodeType
    {
        if(this.isContainer(symbols))
        {
            return "container";
        }

        if((symbols.length === 28))
        {
            const control1 = symbols.substr(0, 2);
            const control2 = symbols.substr(16, 2);

            if(control1 === "01" && (control2 === "10" || control2 === "21"))
            {
                return "item";
            }
        }

        if(symbols.length === 16)
        {
            const control1 = symbols.substr(0, 2);
            if(control1 === "01")
            {
                return "item";
            }
        }

        if(symbols.length === 13)
        {
            const control1 = symbols.substr(0, 2);
            if(control1 === "57")
            {
                return "item";
            }
        }

        if(symbols.length === 6) // sku
        {
            return "item";
        }

        if(symbols.length === 9)
        {
            return "location";
        }
        
        return "unknown";
    }

    private onKeyup = (e: KeyboardEvent) => 
    {
        const ch = e.key;
        window.clearTimeout(this.scanHandle);
        if(ch !== undefined && ch.length === 1)
        {
            this.scannedSymbols += e.shiftKey ? ch.toUpperCase() : ch;
        }
        this.scanHandle = window.setTimeout(this.scanTimeout, 200);
    }

    private scanTimeout = () =>
    {
        this.onSuccess(this.scannedSymbols, this.getBarcodeType(this.scannedSymbols));
        this.scannedSymbols = "";
    }
}

