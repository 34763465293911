import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { ModuleProps } from "../../../config";

import { withStyles } from "@material-ui/core/styles";

import { Feature, LookupResult } from "../../types";
import { Route } from "react-router";
import { ItemList } from "./ItemList";
import { FeatureList } from "./FeatureList";
import { ManualInput } from "./ManuelInput";
import { useQuery } from "react-query";
import Api from "../../../../api/api";
import { useSetRecoilState } from "recoil";
import { searchStatusAtom } from "../../atoms/base36";
import { DeliveryContextObject, DeliveryContext } from "../Main";

const styles = (theme: any) => ({
    root: {
        padding: "16px"
    },
    list: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    code: {
        marginBottom: 16
    }
});

type StylesClasses<T extends (...args: any[]) => any> = {
    [P in keyof ReturnType<T>]: string;
};

interface StylesProps<T extends (...args: any[]) => any>
{
    classes: StylesClasses<T>;
}

export interface Props extends ModuleProps
{
}

export type CheckedFeature = Feature & { checked: boolean };

const setBit = (n: number, bit: number, on: boolean) => on ? (n | (1 << bit)) : (n & ~(1 << bit));

const fetchFeatures = () => 
    Api.request("tools/features/labels/en-US").then<Feature[]>(x => x.json());

const fetchItems = (code: string) => 
    Api.request(`tools/features/lookup/base36/${code}`).then<LookupResult>(x => x.json());

function Main(props: Props & StylesProps<typeof styles>)
{
    const setSearchStatus = useSetRecoilState(searchStatusAtom);
    const [base36value, setbase36value] = useState("");
    const { classes } = props;
    const { setBusyIndicatorVisible } = React.useContext(DeliveryContext);
    const value = parseInt(base36value, 36);

    const { data: featuresConfiguration, isLoading: featureIsLoading } = useQuery("tools.feature.features", fetchFeatures);
    const { data: lookupResult, isLoading: itemsIsLoading } = useQuery(["tools.features", "lookup", base36value || "0"], () => fetchItems(base36value || "0" ))

    // sync loading indicator
    useEffect(() => { 
        setBusyIndicatorVisible(featureIsLoading || itemsIsLoading) 
    }, [featureIsLoading, itemsIsLoading, setBusyIndicatorVisible])

    const onFeatureCheck = useCallback((f: Feature, c: boolean) => {
        setbase36value(setBit(value, f.bit, c).toString(36).toUpperCase());
    }, [value]);

    useEffect(() =>  
    {
        setSearchStatus({ 
            itemsCount: lookupResult?.items.length ?? 0, 
            additionalItemsCount: lookupResult?.additionalItems.length ?? 0
        });

    }, [lookupResult, setSearchStatus])

    const featureList = featuresConfiguration?.map(fc => ({
        ...fc, 
        checked: (value & (1 << fc.bit)) > 0 
    }));

    const baseUrl = props.match.url.replace(/\/+$/, "");

    return (
        <div className={classes.root}>
            <Route path={[`${baseUrl}/lookup`, `${props.match.url}`]} exact render={() => (
            <>
                <ManualInput className={classes.code} base36value={base36value} onValueChange={setbase36value} />
                <FeatureList features={featureList ?? []} onFeatureCheck={onFeatureCheck} />
            </>
            )} />
            <Route path={`${baseUrl}/items/any`} exact render={() => <ItemList items={lookupResult?.additionalItems ?? []} />} />
            <Route path={`${baseUrl}/items`} exact render={() => <ItemList items={lookupResult?.items ?? []} />} />
        </div>
    );
}

export default (withStyles(styles)(Main));