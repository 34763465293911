import { combineReducers } from "redux";
import ui from "./ui";
import user from "./user";
import module from "./module";

const rootReducer = combineReducers({
    ui,
    user,
    module
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;