import * as React from "react";
import { useState, useEffect } from "react";
import i18n from "es2015-i18n-tag";
import { formatGroupedBinary } from "../../../../util/number";
import TextField from "@material-ui/core/TextField";

interface ManualInputProps
{
    className?: string;
    base36value: string;
    onValueChange: (base36value: string) => void;
}

const getBase2String = (base36: string) => formatGroupedBinary(parseInt(base36.replace(/ +/, ""), 36) || 0);
const restrictLength = (s: string, n: number) => s.length > n ? s.substr(0, n) : s;

export function ManualInput(props: ManualInputProps)
{
    const { className, onValueChange, base36value } = props;

    const [binaryValue, setBinaryValue] = useState(() => getBase2String(base36value));
    
    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => 
        onValueChange(restrictLength(e.target.value.replace(/[^a-zA-Z0-9]+/, ""), 5));
    
    const onBinaryTextBlur = (e: React.ChangeEvent<HTMLInputElement>) => 
        onValueChange(parseInt(binaryValue.replace(/ /g, ""), 2).toString(36));

    const onBinaryTextChange = (e: React.ChangeEvent<HTMLInputElement>) => 
        setBinaryValue(e.target.value.replace(/[^01 ]/g, ""));

    useEffect(() => setBinaryValue(getBase2String(base36value)), [base36value]);

    return (
        <div className={className}>
            <TextField fullWidth variant="filled" label={i18n`Base 36 Code`}
                onChange={onTextFieldChange}
                value={base36value}
            />
            <TextField fullWidth variant="filled" label={i18n`Base 2 Code`} style={{marginTop: 8}}
                onChange={onBinaryTextChange}
                onBlur={onBinaryTextBlur}
                value={binaryValue}
            />
        </div>
    );
}