import { Dispatch } from "redux";
import { ActionFailure, getErrorReason } from "../../action";
import { getItem, Item } from "../../../api/StockApi";
import { getQty } from "../../../api/StockApi";
import { generateUuid } from "../../../../../util/uuid";

//
// Action: STOCK_FETCH_ITEM
//
export const STOCK_FETCH_ITEM = "STOCK_FETCH_ITEM";
export interface StockFetchItemAction
{
    type: "STOCK_FETCH_ITEM";
}
export const _stockFetchItem = (): StockFetchItemAction => ({
    type: "STOCK_FETCH_ITEM"
});

export const stockFetchItem = (draft: string, barcode: string, location: string, warehouse:string) => (dispatch: Dispatch<any>) => 
{
    dispatch(_stockFetchItem());
    (async () => {
        try
        {
            const item = await getItem(barcode);

            // Added to validate Qty 10-8-2021
            const qtyInStockLoc = await getQty(warehouse, location, item.sku);
            item.quantity = qtyInStockLoc;

            dispatch(stockFetchItemSuccess(item, location));

        }
        catch(e)
        {
            let errorMessage = (e.message) 
                ? e.message
                : `${getErrorReason(e.error)}Could not fetch item`;
            dispatch(stockFetchItemFailure(generateUuid(), errorMessage));
        }
    })();
}

//
// Action: STOCK_FETCH_ITEM_FAILURE
//
export const STOCK_FETCH_ITEM_FAILURE = "STOCK_FETCH_ITEM_FAILURE";
export interface StockFetchItemFailureAction extends ActionFailure
{
    type: "STOCK_FETCH_ITEM_FAILURE";
}
export const stockFetchItemFailure = (id: string, message: string): StockFetchItemFailureAction => ({
    type: STOCK_FETCH_ITEM_FAILURE,
    notification: {
        id, message,
        severity: "error",
        source: "server"
    }
});

//
// Action: STOCK_FETCH_ITEM_SUCCESS
//
export const STOCK_FETCH_ITEM_SUCCESS = "STOCK_FETCH_ITEM_SUCCESS";
export interface StockFetchItemSuccessAction
{
    type: "STOCK_FETCH_ITEM_SUCCESS";
    item: Item;
    location: string;
}
export const stockFetchItemSuccess = (item: Item, location: string): StockFetchItemSuccessAction => ({
    type: STOCK_FETCH_ITEM_SUCCESS,
    item,
    location
});