import { StockItem, Notification } from "./types";
import { Dispatch } from "redux";
import { Draft, getDrafts, Item, fetchPendingItems, confirmLocationComplete } from "../api/StockApi";
import { generateUuid } from "../../../util/uuid";

import { StockFetchItemFailureAction, StockFetchItemSuccessAction, StockFetchItemAction } from "./action/item/fetch";
import { StockUpdateItemAction, StockUpdateItemSuccessAction, StockUpdateItemFailureAction } from "./action/item/update";
import { StockDebugClearStateAction } from "./action/debug";
import i18n from "es2015-i18n-tag";
import { ERROR_SERVER_NOT_AVAILABLE, ERROR_INTERNAL_SERVER_FAILURE } from "../constants/error";

export * from "./action/item/fetch";
export * from "./action/item/update";
export * from "./action/debug";

export const getErrorReason = (errorCode: number): string => 
    (errorCode === ERROR_SERVER_NOT_AVAILABLE)
      ? i18n`Server not reachable: `
      : (errorCode === ERROR_INTERNAL_SERVER_FAILURE)
      ? i18n`Request failed: `
      : "";


export interface ActionFailure
{
    notification: Notification;
}

//
// Action: STOCK_INIT
//
export const STOCK_INIT = "STOCK_INIT";
export interface StockInitAction
{
    type: "STOCK_INIT";
}
export const stockInit = (): StockInitAction => ({
    type: STOCK_INIT
});



//
// Action: STOCK_UPDATE_CURRENT
//
export const STOCK_UPDATE_CURRENT = "STOCK_UPDATE_CURRENT";
export interface StockUpdateCurrentAction
{
    type: "STOCK_UPDATE_CURRENT";
    item: Partial<StockItem>;
}
export const stockUpdateCurrent = (item: Partial<StockItem>): StockUpdateCurrentAction => ({
    type: STOCK_UPDATE_CURRENT, item
});

//
// Action: STOCK_RESET_CURRENT
//
export const STOCK_RESET_CURRENT = "STOCK_RESET_CURRENT";
export interface StockResetCurrentAction
{
    type: "STOCK_RESET_CURRENT";
}
export const stockResetCurrent = (): StockResetCurrentAction => ({
    type: STOCK_RESET_CURRENT
});

//
// Action: STOCK_SET_WAREHOUSE
//
export const STOCK_SET_WAREHOUSE = "STOCK_SET_WAREHOUSE";
export interface StockSetWarehouseAction
{
    type: "STOCK_SET_WAREHOUSE";
    warehouse: string;
}
export const stockSetWarehouse = (warehouse: string): StockSetWarehouseAction => ({
    type: STOCK_SET_WAREHOUSE,
    warehouse
});

//
// Action: STOCK_DRAFTS_LOAD
//
export const STOCK_DRAFTS_LOAD = "STOCK_DRAFTS_LOAD";
export interface StockDraftsLoadAction
{
    type: "STOCK_DRAFTS_LOAD";
}
const _stockDraftsLoad = (): StockDraftsLoadAction => ({
    type: STOCK_DRAFTS_LOAD
});

//
// Action: STOCK_DRAFTS_LOAD_SUCCESS
//
export const STOCK_DRAFTS_LOAD_SUCCESS = "STOCK_DRAFTS_LOAD_SUCCESS";
export interface StockDraftsLoadSuccessAction
{
    type: "STOCK_DRAFTS_LOAD_SUCCESS";
    drafts: Draft[];
    warehouse: string;
}
export const stockDraftsLoadSuccess = (warehouse: string, drafts: Draft[]): StockDraftsLoadSuccessAction => ({
    type: STOCK_DRAFTS_LOAD_SUCCESS,
    warehouse,
    drafts
});

//
// Action: STOCK_DRAFTS_LOAD_FAILURE
//
export const STOCK_DRAFTS_LOAD_FAILURE = "STOCK_DRAFTS_LOAD_FAILURE";
export interface StockDraftsLoadFailureAction extends ActionFailure
{
    type: "STOCK_DRAFTS_LOAD_FAILURE";
}
export const stockDraftsLoadFailure = (id: string, message: string): StockDraftsLoadFailureAction => ({
    type: STOCK_DRAFTS_LOAD_FAILURE,
    notification: {
        id, message,
        severity: "error",
        source: "server"
    }
});

export const stockDraftsLoad = (warehouse: string) => (dispatch: Dispatch<any>) => 
{
    (async () => 
    {
        dispatch(_stockDraftsLoad());
        try
        {
            const drafts = await getDrafts(warehouse);
            dispatch(stockDraftsLoadSuccess(warehouse, drafts));
        } 
        catch(e)
        {
            const prefix = getErrorReason(e.error);
            dispatch(stockDraftsLoadFailure(generateUuid(), `${prefix}Could not load drafts for ${warehouse}`)); /* TODO i18n for error messages */
        }
    })();
};

//
// Action: STOCK_SCANNED_LOCATION
//
export const STOCK_SCANNED_LOCATION = "STOCK_SCANNED_LOCATION";
export interface StockScannedLocationAction
{
    type: "STOCK_SCANNED_LOCATION";
    location: string;
}
export const stockScannedLocation = (location: string): StockScannedLocationAction => ({
    type: STOCK_SCANNED_LOCATION,
    location
});

//
// Action: STOCK_SCANNED_ITEM
//
export const STOCK_SCANNED_ITEM = "STOCK_SCANNED_ITEM";
export interface StockScannedItemAction
{
    type: "STOCK_SCANNED_ITEM";
    item: string;
}
export const stockScannedItem = (item: string): StockScannedItemAction => ({
    type: STOCK_SCANNED_ITEM,
    item
});

//
// Action: STOCK_SCANNED_CLEAR
//
export const STOCK_SCANNED_CLEAR = "STOCK_SCANNED_CLEAR";
export interface StockScannedClearAction
{
    type: "STOCK_SCANNED_CLEAR";
}
export const stockScannedClear = (): StockScannedClearAction => ({
    type: STOCK_SCANNED_CLEAR
});

//
// Action: STOCK_DISMISS_ERROR
//
export const STOCK_DISMISS_ERROR = "STOCK_DISMISS_ERROR";
export interface StockDismissErrorAction
{
    type: "STOCK_DISMISS_ERROR";
    id: string;
}
export const stockDismissError = (id: string): StockDismissErrorAction => ({
    type: STOCK_DISMISS_ERROR,
    id
});

//
// Action: STOCK_REVERT_LOCATION
//
export interface StockRevertLocationAction
{
    type: "STOCK_REVERT_LOCATION";
}
export const stockRevertLocation = (): StockRevertLocationAction => ({
    type: "STOCK_REVERT_LOCATION"
});

//
// Action: STOCK_FETCH_PENDING_ITEMS
//
export interface StockFetchPendingItemsAction
{
    type: "STOCK_FETCH_PENDING_ITEMS";
    warehouse: string;
    draft: string;
    location: string;
}
export const _stockFetchPendingItems = (warehouse: string, draft: string, location: string): StockFetchPendingItemsAction => ({
    type: "STOCK_FETCH_PENDING_ITEMS",
    warehouse, draft, location
});

export const stockFetchPendingItems = (warehouse: string, draft: string, location: string) => (dispatch: Dispatch<any>) => 
{
    dispatch(_stockFetchPendingItems(warehouse, draft, location));
    (async () => {
        try 
        {  
            const items = await fetchPendingItems(warehouse, draft, location);
            dispatch(stockFetchPendingItemsSuccess(items));
        }
        catch(e)
        {
            dispatch(stockFetchPendingItemsFailure(e.message));
        }
    })();

};

//
// Action: STOCK_FETCH_PENDING_ITEMS_SUCCESS
//
export interface StockFetchPendingItemsSuccessAction
{
    type: "STOCK_FETCH_PENDING_ITEMS_SUCCESS";
    items: Item[];
}
export const stockFetchPendingItemsSuccess = (items: Item[]): StockFetchPendingItemsSuccessAction => ({
    type: "STOCK_FETCH_PENDING_ITEMS_SUCCESS",
    items
});

//
// Action: STOCK_FETCH_PENDING_ITEMS_FAILURE
//
export interface StockFetchPendingItemsFailureAction
{
    type: "STOCK_FETCH_PENDING_ITEMS_FAILURE";
    message: string;
}
export const stockFetchPendingItemsFailure = (message: string): StockFetchPendingItemsFailureAction => ({
    type: "STOCK_FETCH_PENDING_ITEMS_FAILURE",
    message
});

//
// Action: STOCK_CLEAR_PREVIOUS_LOCATION_AND_ITEMS
//
export interface StockClearPreviousLocationAndItemsAction
{
    type: "STOCK_CLEAR_PREVIOUS_LOCATION_AND_ITEMS";
}
export const stockClearPreviousLocationAndItems = (): StockClearPreviousLocationAndItemsAction => ({
    type: "STOCK_CLEAR_PREVIOUS_LOCATION_AND_ITEMS"
});

//
// Action: STOCK_CONFIRM_LOCATION_COMPLETE
//
export interface StockConfirmLocationCompleteAction
{
    type: "STOCK_CONFIRM_LOCATION_COMPLETE";
    warehouse: string;
    location: string;
    draft: string;
}
export const _stockConfirmLocationComplete = (warehouse: string, draft: string, location: string): StockConfirmLocationCompleteAction => ({
    type: "STOCK_CONFIRM_LOCATION_COMPLETE",
    warehouse, draft, location
});

export const stockConfirmLocationComplete = (warehouse: string, draft: string, location: string) => (dispatch: Dispatch<any>) => 
{
    dispatch(_stockConfirmLocationComplete(warehouse, draft, location));
    (async () => {
        try 
        {
            await confirmLocationComplete(warehouse, draft, location);
            dispatch(stockConfirmLocationCompleteSuccess());
        }
        catch(e)
        {
            dispatch(stockConfirmLocationCompleteFailure(e.message));
        }
    })();
}

//
// Action: STOCK_CONFIRM_LOCATION_COMPLETE_SUCCESS
//
export interface StockConfirmLocationCompleteSuccessAction
{
    type: "STOCK_CONFIRM_LOCATION_COMPLETE_SUCCESS";
}
export const stockConfirmLocationCompleteSuccess = (): StockConfirmLocationCompleteSuccessAction => ({
    type: "STOCK_CONFIRM_LOCATION_COMPLETE_SUCCESS"
});

//
// Action: STOCK_CONFIRM_LOCATION_COMPLETE_FAILURE
//
export interface StockConfirmLocationCompleteFailureAction
{
    type: "STOCK_CONFIRM_LOCATION_COMPLETE_FAILURE";
    message: string;
}
export const stockConfirmLocationCompleteFailure = (message: string): StockConfirmLocationCompleteFailureAction => ({
    type: "STOCK_CONFIRM_LOCATION_COMPLETE_FAILURE",
    message
});

export type StockAction = StockFetchItemAction
| StockFetchItemFailureAction
| StockFetchItemSuccessAction
| StockUpdateCurrentAction
| StockResetCurrentAction
| StockSetWarehouseAction
| StockDraftsLoadAction
| StockDraftsLoadSuccessAction
| StockDraftsLoadFailureAction
| StockScannedLocationAction
| StockScannedItemAction
| StockScannedClearAction
| StockInitAction
| StockUpdateItemAction
| StockUpdateItemSuccessAction
| StockUpdateItemFailureAction
| StockDismissErrorAction
| StockDebugClearStateAction
| StockFetchPendingItemsAction
| StockFetchPendingItemsSuccessAction
| StockFetchPendingItemsFailureAction
| StockClearPreviousLocationAndItemsAction
| StockRevertLocationAction
| StockConfirmLocationCompleteAction
| StockConfirmLocationCompleteSuccessAction
| StockConfirmLocationCompleteFailureAction
;
