import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import { Grid, TextField, Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { Field, DateField, NumberField, StringField, ChoiceField } from "../../api/label";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import DayjsUtils from "@date-io/dayjs";
import dayjs from "dayjs";

export type FieldValues = {
    [fieldName: string]: string;
};

export interface Props
{
    fields: Field[];
    onChange?: (values: FieldValues) => void;
}

function AdditionalFields(props: Props)
{
    const { fields, onChange } = props;

    const [values, setValues] = useState<FieldValues>({});

    const setFieldValue = useCallback((fieldName: string, value: string) => {
        setValues(values => {
            const newValues = ({...values, [fieldName]: value });
            onChange?.(newValues)
            return newValues;
        });
        
    }, [onChange]);

    useEffect(() => {
        const defaultValues: FieldValues = {};
        fields
            .filter(f => f.value !== undefined)
            .forEach(f => defaultValues[f.name] = f.value as string);

        // const newValues = ({...defaultValues, ...values});
        setValues(values => {
            const newValues = {...defaultValues, ...values};
            onChange?.(values);
            return newValues;
        });
    }, [fields, onChange]);

    return (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            {
                fields.map(field => (
                    <Grid item xs={12} key={field.name} >
                    {
                        (field.type === "date") ? (
                            <DateFieldRender field={field} onChange={setFieldValue} />
                        ) : (field.type === "number") ? (
                            <NumberFieldRender field={field} onChange={setFieldValue} />
                        ) : (field.type === "string") ? (
                            <StringFieldRender field={field} onChange={setFieldValue} />
                        ) : (field.type === "choice") ? (
                            <ChoiceFieldRender field={field} onChange={setFieldValue} />
                        ) : null
                    }
                    </Grid>
                ))
            }
        </MuiPickersUtilsProvider>
    )
}

interface AdditionalFieldProps<T extends Field>
{
    field: T;
    onChange?: (fieldName: string, value: string) => void;
}

function DateFieldRender(props: AdditionalFieldProps<DateField>)
{
    const { field, onChange } = props;
    const [value, setValue] = useState<dayjs.Dayjs>(dayjs(field.value));
    
    const onChangeCallback = useCallback((date: dayjs.Dayjs) => {
        setValue(date);
        if(onChange)
        {
            date = date.add(date.utcOffset(), "minute");
            onChange(field.name, date.toISOString());
        }
}, [onChange, field.name]);

    return (
        <DatePicker fullWidth
            margin="normal"
            label={field.displayLabel}
            value={value}
            onChange={onChangeCallback}
            />
    )
}

function NumberFieldRender(props: AdditionalFieldProps<NumberField>)
{
    const { field: { displayLabel, value: defaultValue, name }, onChange } = props;
    const [value, setValue] = useState(defaultValue);

    const onChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        onChange?.(name, e.target.value);
    }, [onChange, name]);

    return (
        <TextField fullWidth
            margin="normal"
            type="number"
            label={displayLabel}
            value={value}
            onChange={onChangeCallback} />
    )
}

function StringFieldRender(props: AdditionalFieldProps<StringField>)
{
    const { field: { displayLabel, value: defaultValue, name }, onChange } = props;
    const [value, setValue] = useState(defaultValue);

    const onChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        onChange?.(name, e.target.value);
    }, [onChange, name]);

    return (
        <TextField fullWidth
            margin="normal"
            label={displayLabel}
            value={value}
            onChange={onChangeCallback} />
    )
}

function ChoiceFieldRender(props: AdditionalFieldProps<ChoiceField>)
{
    const { field: { displayLabel, value: values, name }, onChange } = props;
    const [value, setValue] = useState("");

    const onChangeCallback = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(e.target.value);
        onChange?.(name, e.target.value);
    }, [onChange, name]);

    return (
        <FormControl margin="normal">
            <InputLabel>{displayLabel}</InputLabel>    
            <Select fullWidth
                value={value}
                onChange={onChangeCallback}>
            {
                values?.map(item => (
                    <MenuItem value={item.value} key={item.value}>
                        {item.label}
                    </MenuItem>
                ))
            }
            </Select>
        </FormControl>
    )
}
export default AdditionalFields;