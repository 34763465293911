import inventory from "./inventory";
import relocation from "./relocation";
import label from "./label";
import module from "./module";
import { combineReducers } from "redux";

import {
    getCurrentWarehouse as getInventoryCurrentWarehouse_,
    getDrafts as getInventoryDrafts_,
    getCurrentItem as getInventoryCurrentItem_,
    getScannedItem as getInventoryScannedItem_,
    getScannedLocation as getInventoryScannedLocation_,
    getPreviousLocaiton as getInventoryPreviousLocation_,
    getPendingItems as getInventoryPendingItems_,
    getIsLoadingItem as getInventoryIsLoadingItem_,
    getIsUpdatingItem as getInventoryIsUpdatingItem_,
    getNotifications as getInventoryNotifications_
} from "./inventory";

import {
    getCurrentItem as getCurrentItem_,
    getRelocationRequest as getRelocationRequest_
} from "./relocation";

import {
    getModuleNotifications as getModuleNotifications_
} from "./module"
import { StockAction } from "../action";

const busy = (state: boolean = false, action: StockAction) =>
{
    if("uiMeta" in action && "busy" in action["uiMeta"])
    {
        return !! action["uiMeta"]["busy"];
    }

    switch(action.type)
    {
        case "STOCK_FETCH_ITEM":
        case "STOCK_DRAFTS_LOAD":
        case "STOCK_UPDATE_ITEM":
        case "STOCK_FETCH_PENDING_ITEMS":
        case "STOCK_CONFIRM_LOCATION_COMPLETE":
            return true;

        case "STOCK_UPDATE_ITEM_FAILURE":
        case "STOCK_UPDATE_ITEM_SUCCESS":
        case "STOCK_FETCH_PENDING_ITEMS_SUCCESS":
        case "STOCK_FETCH_ITEM_FAILURE":
        case "STOCK_FETCH_ITEM_SUCCESS":
        case "STOCK_DRAFTS_LOAD_FAILURE":
        case "STOCK_DRAFTS_LOAD_SUCCESS":
        case "STOCK_CONFIRM_LOCATION_COMPLETE_FAILURE":
        case "STOCK_CONFIRM_LOCATION_COMPLETE_SUCCESS":
            return false;

        default:
            return state;
    }
}

const reducer = combineReducers({
    busy,
    inventory, 
    relocation,
    label,
    module
});

export type StockState = ReturnType<typeof reducer>;


const retarget = <TChild, TTarget, TResult>(fn: (s: TTarget) => TChild, selector: (s: TChild) => TResult) => 
    (t: TTarget) => selector(fn(t));

export const getInventoryCurrentWarehouse = retarget((s: StockState) => s.inventory, getInventoryCurrentWarehouse_);
export const getInventoryDrafts = retarget((s: StockState) => s.inventory, getInventoryDrafts_);
export const getInventoryCurrentItem = retarget((s: StockState) => s.inventory, getInventoryCurrentItem_);
export const getInventoryScannedItem = retarget((s: StockState) => s.inventory, getInventoryScannedItem_);
export const getInventoryScannedLocation = retarget((s: StockState) => s.inventory, getInventoryScannedLocation_);
export const getInventoryPreviousLocation = retarget((s: StockState) => s.inventory, getInventoryPreviousLocation_);
export const getInventoryPendingItems = retarget((s: StockState) => s.inventory, getInventoryPendingItems_);
export const getInventoryIsLoadingItem = retarget((s: StockState) => s.inventory, getInventoryIsLoadingItem_);
export const getInventoryIsUpdatingItem = retarget((s: StockState) => s.inventory, getInventoryIsUpdatingItem_);
export const getInventoryNotifications = retarget((s: StockState) => s.inventory, getInventoryNotifications_);

export const getCurrentItem = retarget((s: StockState) => s.relocation, getCurrentItem_);
export const getRelocationRequest = retarget((s: StockState) => s.relocation, getRelocationRequest_);

export const getModuleNotifications = retarget((s: StockState) => s.module, getModuleNotifications_);

export const getIsBusy = ((s: StockState) => !!s.busy);

// export const getLabelPrinters = retarget((s: StockState) => s.label, labelSelector.getLabelPrinters);
// export const getLabelTypes = retarget((s: StockState) => s.label, labelSelector.getLabelTypes);
// export const getLabelPrintersLoading = retarget((s: StockState) => s.label, labelSelector.getLabelPrintersLoading);
// export const getLabelTypesLoading = retarget((s: StockState) => s.label, labelSelector.getLabelTypesLoading);
// export const getLabelTypesError = retarget((s: StockState) => s.label, labelSelector.getLabelTypesError);
// export const getLabelPrintersError = retarget((s: StockState) => s.label, labelSelector.getLabelPrintersError);
// export const getLabelRequestActive = retarget((s: StockState) => s.label, labelSelector.getLabelRequestActive);
// export const getLabelRequestError = retarget((s: StockState) => s.label, labelSelector.getLabelRequestError);



export default reducer;