import * as React from "react";
import { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import i18n from "es2015-i18n-tag";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import DeliveryCard from "./DeliveryCard";
import Api from "../../../../api/api";
import { Delivery } from "../../state";

interface Props extends RouteComponentProps<any>
{

}

export default function Index(props: Props)
{
    const [delivery, setDelivery] = useState<Delivery | null>(null);
    const [query, setQuery] = useState<string | null>(null);

    const onKeyup = (e: React.KeyboardEvent<HTMLInputElement>) => 
    {
        if(e.keyCode === 13)
        {
            setQuery(e.currentTarget.value);
        }
        else
        {
            setDelivery(null);
        }
    }

    useEffect(() => {
        if(query !== null)
        {
            Api.request(`delivery/${query}/address`)
                .then(res => res.ok ? res.json() : Promise.reject(res))
                .then(setDelivery)
                .catch(() => setDelivery(null));
        }
    }, [query]);


    return (
        <div className="" style={{padding: "16px 8px"}}>
            <Paper>
                <TextField variant="filled" label={i18n`Delivery number`} fullWidth 
                    inputProps={{onKeyUp: onKeyup}}
                 />
            </Paper>
            {
                (delivery !== null && query !== null) ? (
                    <div style={{marginTop: 16}}>
                        <DeliveryCard delivery={delivery} deliveryNumber={query} />
                    </div>
                ) : null
            }
        </div>
    );
}