import { Token } from "client-oauth2";

export interface TokenStore
{
    save(token: Token): Promise<void>;
    load(): Promise<Token | null>;
    clear(): Promise<void>;
}

export class LocalStorageTokenStore implements TokenStore
{
    private key_: string = "___TOKEN";

    save(token: Token): Promise<void> 
    {
        const { client, ...dataToken } = token;
        localStorage.setItem(this.key_, JSON.stringify(dataToken));
        return Promise.resolve();
    }    
    
    load(): Promise<Token | null> 
    {
        const json = localStorage.getItem(this.key_);
        if(json === null)
        {
            return Promise.resolve(null);
        }
        try
        {
            const token = JSON.parse(json) as Token;
            return Promise.resolve(token);
        }
        catch(e)
        {
            return Promise.reject(e);
        }
    }

    clear(): Promise<void>
    {
        localStorage.removeItem(this.key_);
        return Promise.resolve();
    }
}
