import * as React from "react";
import { useMemo } from "react";
import { ModuleProps } from "../../config";

import { Route, Switch } from "react-router";
import Features from "./base36/Index";
import { Button } from "@material-ui/core";
import Delivery from "./delivery/Index";
import { QueryClient, QueryClientProvider } from "react-query";

export interface DeliveryContextObject
{
    toast: ModuleProps["toast"];
    setBusyIndicatorVisible: (visible: boolean) => void;
};

export const DeliveryContext = React.createContext<DeliveryContextObject>({
    toast: () => {},
    setBusyIndicatorVisible: () => {}
});

export interface Props extends ModuleProps
{
}

const queryClient = new QueryClient();

function Main(props: Props)
{
    const { match: { path }, history, toast, isBusy } = props;

    const onFeaturesClick = () => history.push(`${path}/features`);
    const onDeliveryClick = () => history.push(`${path}/delivery`);

    // useEffect(() => { isBusy(busy) }, [isBusy, busy]);

    const context = useMemo(() => ({
        toast, 
        setBusyIndicatorVisible: isBusy
    }), [toast, isBusy]);


    return (
        <QueryClientProvider client={queryClient}>
            <DeliveryContext.Provider value={context}>
            <Switch>
                <Route path={`${path}/features`} component={Features} />
                <Route path={`${path}/delivery`} component={Delivery} />
                <Route render={() => (
                    <div style={{padding: "16px 8px"}}>
                        <Button variant="outlined" onClick={onFeaturesClick} fullWidth>
                            Feature Lookup
                        </Button>
                        <Button variant="outlined" onClick={onDeliveryClick} fullWidth style={{marginTop: 8}}>
                            Delivery Lookup
                        </Button>
                    </div>
                )} />
            </Switch>
            </DeliveryContext.Provider>
        </QueryClientProvider>
    );
}

export default Main;