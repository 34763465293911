import * as React from "react";
import { Reducer, createStore, compose, applyMiddleware, Store } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

import  { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import { OfflineAction } from "@redux-offline/redux-offline/lib/types";
import { createTransform } from "redux-persist";

import auth from "../service/auth";
import Api from "../api/api";
import { RouteComponentProps } from "react-router";
import { ModuleProps } from "./config";
import { Provider } from "react-redux";

export interface Effect
{
    username: string;
    url: string;
    method?: string;
    body?: any
}

interface DiscardError
{
    username?: string;
    status?: number;
}

const createOfflineStoreEnhancer = (key: string, stateVersion: number, options: PersistConfig) =>
{
    const config = 
    {
        ...offlineConfig,
        discard: (error: DiscardError, _action: OfflineAction, _retries: number) =>
        {
            if(error.username !== undefined)
            {
                return false;
            }
            if(error.status !== undefined)
            {
                return (400 <= error.status) && (error.status < 500); // discard on client errors
            }
            return true;
        },

        effect: ({username, url, method = "GET", body}: Effect, action: OfflineAction) =>
            auth.username === username 
                ? Api.request(url, method, body).then(res => res.ok ? Promise.resolve() : Promise.reject({ status: res.status }))
                : Promise.reject({ username })
    };

    const upgradeTransform = createTransform(
        (inState: object, key) => { 
            if(key === "module")
            {
                return { ...inState, _version: stateVersion };
            }
            return inState;
        },
        (outState: any, key) => 
        { 
            if(key === "module" && (outState._version === undefined || outState._version < stateVersion))
            {
                console.info("Module state cleared for upgrade");
                return undefined;
            }
            return outState;
        }
    );
    return offline({ ...config, persistOptions: { 
        keyPrefix: `reduxPersist_${key}`,
        transforms: [upgradeTransform],
        ...options
    }});
}

interface PersistConfig
{
    whitelist?: string[];
    blacklist?: string[];
}

export const createModuleStore = <T extends any>(reducer: Reducer<T, any>, moduleKey: string, version: number, options: PersistConfig = {}) =>
{
    const middleware = applyMiddleware(
        thunkMiddleware,
        createLogger({ collapsed: true })
    );

    return createStore(reducer, compose(
        middleware,
        createOfflineStoreEnhancer(moduleKey, version, options)
    ));
}

export const createModule = <T, P extends RouteComponentProps<any> & ModuleProps>(store: Store<T, any>, App: React.ComponentType<P>) => 
    (props: P) => (
        <Provider store={store}>
            <App {...props} />
        </Provider>
    );
    
