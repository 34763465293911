import { atom } from "recoil";

interface SearchStatus
{
    additionalItemsCount: number;
    itemsCount: number;
}

export const searchStatusAtom = atom<SearchStatus>({
    key: "tools.base36",
    default: {
        additionalItemsCount: 0,
        itemsCount: 0
    }
});


