import styled from "styled-components";

export interface Props
{
    fullHeight?: boolean;
}


const Center = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(props: Props) => props.fullHeight ? "100%" : "inherit"}
`;

export default Center;