import * as React from "react"
import i18n from "es2015-i18n-tag";
import styled from "styled-components";
import { connect } from "react-redux";
import { RouteComponentProps, Route, Switch, Redirect } from "react-router";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction, { BottomNavigationActionProps} from "@material-ui/core/BottomNavigationAction";
import Alarm from "@material-ui/icons/Alarm";
import Assignment from "@material-ui/icons/Assignment";

import { getCurrentTransaction, TimeRecordingState } from "../state";

import CurrentJob from "./CurrentJob";
import CheckInOut from "./CheckInOut";

import { fetchTransactions } from "../state/action";
import { ModuleProps } from "../../config";

const MyBottomNavigation = styled(BottomNavigation)`
    bottom: 0;
    position: fixed;
    width: 100vw;
` as any;

const MainView = styled.div`
    margin-bottom: 56px;
    min-height: calc(100vh - 112px);
` ;

export interface Props extends ModuleProps
{
}

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type ViewType = "activity" | "log" | "check-in-out";

type ViewProps = { username: string } & RouteComponentProps<any>;

interface View 
{
    urlPart: ViewType;
    view: React.ComponentType<ViewProps>;
    icon: React.ComponentType<any>;
    label: string;
    default?: boolean;
    props?: (props: Props & StateProps) => Partial<BottomNavigationActionProps>;
}

interface State
{
}

class Auto extends React.Component<Props & StateProps, State>
{
    componentWillMount()
    {
        this.props.fetchTransactions();
    }

    state: State =
    {
        currentView: "check-in-out"
    }

    private views: View[] = [
        { urlPart: "check-in-out", view: CheckInOut, icon: Alarm, label: i18n`Check In / Out`, default: true },
        { urlPart: "activity", view: CurrentJob, label: i18n`Activity`, icon: Assignment, props: ({transaction}) => ({ disabled: !transaction}) }
        // { urlPart: "log", view: Log, label: i18n`Log`, icon: PlaylistAddCheck, props: ({transaction}) => ({ disabled: !transaction}) }

    ];

    private onValueChange = (_event: React.ChangeEvent<any>, value: ViewType) =>
    {
        this.props.history.replace(this.getPath(value));
    }

    private getPath(path: string)
    {
        return this.props.match.url + "/" + path;
    }

    private renderView() : React.ReactNode
    {
        const { username } = this.props;
        if(username === undefined)
        {
            return;
        }

        return (
            <Switch>
            {
                this.views.map(({ urlPart, view: View }) => (
                    <Route path={this.getPath(urlPart)} key={urlPart} 
                        render={(props) => <View {...props} username={username} />} />
                ))
            }
                <Route path={this.getPath("")} exact>
                    <Redirect to={this.getPath(this.views.filter(v => v.default)[0].urlPart)} />
                </Route>
            </Switch>
        );
    }

    private getActiveView(): ViewType
    {
        const { location } = this.props;
        return this.views.map(view => view.urlPart)
                .find(urlPart => location.pathname.indexOf(this.getPath(urlPart)) === 0)
            || this.views[0].urlPart;
    }

    render()
    {
        return (
            <>
                <MainView>
                    {this.renderView()}
                </MainView>
                <MyBottomNavigation onChange={this.onValueChange} value={this.getActiveView()} showLabels>
                {
                    this.views.map(({label, icon: Icon, urlPart, props}) => (
                        <BottomNavigationAction key={urlPart} label={label} icon={<Icon />} value={urlPart} {...(props ? props(this.props) : {})} />
                    ))
                }
                </MyBottomNavigation>
            </>
        );
    }
}

const mapStateToProps = (state: TimeRecordingState, ownProps: Props) => ({
    transaction: ownProps.username ? getCurrentTransaction(ownProps.username, state) : undefined
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchTransactions: () => dispatch(fetchTransactions() as any)
});

export default connect(mapStateToProps, mapDispatchToProps)(Auto); 