import { Dispatch } from "redux";
import { Item, getItem, RelocationRequest, moveItem } from "../../../api/StockApi";
import { getErrorReason } from "../../action";
import { generateUuid } from "../../../../../util/uuid";
import { BusyAction } from "../../../../../state/ui/busy";

//
// Action: STOCK_RELOCATION_FETCH_ITEM
//
export interface StockRelocationFetchItemAction extends BusyAction
{
    type: "STOCK_RELOCATION_FETCH_ITEM";
}
export const _stockRelocationFetchItem = (barcode: string): StockRelocationFetchItemAction => ({
    type: "STOCK_RELOCATION_FETCH_ITEM", uiMeta: { busy: true }
});
export const stockRelocationFetchItem = (barcode: string) => (dispatch: Dispatch<any>) => 
{
    dispatch(_stockRelocationFetchItem(barcode));
    getItem(barcode)
        .then(item => { dispatch(stockRelocationFetchItemSuccess(item)) })
        .catch(e => {
            let errorMessage = (e.message) 
                ? e.message
                : `${getErrorReason(e.error)}Could not fetch item`;
            dispatch(stockRelocationFetchItemFailure(generateUuid(), errorMessage));
        });
}

//
// Action: STOCK_RELOCATION_FETCH_ITEM_SUCCESS
//
export interface StockRelocationFetchItemSuccessAction extends BusyAction
{
    type: "STOCK_RELOCATION_FETCH_ITEM_SUCCESS";
    item: Item;
}
export const stockRelocationFetchItemSuccess = (item: Item): StockRelocationFetchItemSuccessAction => ({
    type: "STOCK_RELOCATION_FETCH_ITEM_SUCCESS",
    item,
    uiMeta: { busy: false }
});

//
// Action: STOCK_RELOCATION_FETCH_ITEM_FAILED
//
export interface StockRelocationFetchItemFailureAction extends BusyAction
{
    type: "STOCK_RELOCATION_FETCH_ITEM_FAILURE";
    id: string;
    message: string;
}
export const stockRelocationFetchItemFailure = (id: string, message: string): StockRelocationFetchItemFailureAction => ({
    type: "STOCK_RELOCATION_FETCH_ITEM_FAILURE",
    id, message,
    uiMeta: { busy: false }
});

//
// Action: STOCK_RELOCATION_COMMIT
//
export interface StockRelocationCommitAction
{
    type: "STOCK_RELOCATION_COMMIT";
    request: RelocationRequest;
}
export const _stockRelocationCommit = (request: RelocationRequest): StockRelocationCommitAction => ({
    type: "STOCK_RELOCATION_COMMIT",
    request
});

export const stockRelocationCommit = (request: RelocationRequest) => (dispatch: Dispatch<any>) =>
{
    dispatch(_stockRelocationCommit(request));
    moveItem(request).then(
        () => dispatch(stockRelocationCommitSuccess(request)),
        e => dispatch(stockRelocationCommitFailure(request, generateUuid(), e?.message || "Could not move item"))
    );
};

//
// Action: STOCK_RELOCATION_COMMIT_SUCCESS
//
export interface StockRelocationCommitSuccessAction
{
    type: "STOCK_RELOCATION_COMMIT_SUCCESS";
    request: RelocationRequest;
}
export const stockRelocationCommitSuccess = (request: RelocationRequest): StockRelocationCommitSuccessAction => ({
    type: "STOCK_RELOCATION_COMMIT_SUCCESS",
    request
});

//
// Action: STOCK_RELOCATION_COMMIT_FAILURE
//
export interface StockRelocationCommitFailureAction
{
    type: "STOCK_RELOCATION_COMMIT_FAILURE";
    request: RelocationRequest;
    id: string;
    message: string;
}
export const stockRelocationCommitFailure = (request: RelocationRequest, id:string, message: string): StockRelocationCommitFailureAction => ({
    type: "STOCK_RELOCATION_COMMIT_FAILURE",
    request, id, message
});

//
// Action: STOCK_RELOCATION_CLEAR
//
export interface StockRelocationClearAction
{
    type: "STOCK_RELOCATION_CLEAR";
}
export const stockRelocationClear = (): StockRelocationClearAction => ({
    type: "STOCK_RELOCATION_CLEAR"
});

//
// Action: STOCK_RELOCATION_SET_SOURCE_LOCATION
//
export interface StockRelocationSetSourceLocationAction
{
    type: "STOCK_RELOCATION_SET_SOURCE_LOCATION";
    location: string;
}
export const stockRelocationSetSourceLocation = (location: string): StockRelocationSetSourceLocationAction => ({
    type: "STOCK_RELOCATION_SET_SOURCE_LOCATION",
    location
});

//
// Action: STOCK_RELOCATION_SET_DESTINATION_LOCATION
//
export interface StockRelocationSetDestinationLocationAction
{
    type: "STOCK_RELOCATION_SET_DESTINATION_LOCATION";
    location: string;
}
export const stockRelocationSetDestinationLocation = (location: string): StockRelocationSetDestinationLocationAction => ({
    type: "STOCK_RELOCATION_SET_DESTINATION_LOCATION",
    location
});

//
// Action: STOCK_RELOCATION_SET_QUANTITY
//
export interface StockRelocationSetQuantityAction
{
    type: "STOCK_RELOCATION_SET_QUANTITY";
    qty: number;
}
export const stockRelocationSetQuantity = (qty: number): StockRelocationSetQuantityAction => ({
    type: "STOCK_RELOCATION_SET_QUANTITY",
    qty
});

//
// Action: STOCK_RELOCATION_SET_ITEM
//
export interface StockRelocationSetItemAction
{
    type: "STOCK_RELOCATION_SET_ITEM";
    item: Item;
}
export const stockRelocationSetItem = (item: Item): StockRelocationSetItemAction => ({
    type: "STOCK_RELOCATION_SET_ITEM",
    item
});

export type StockRelocationAction = StockRelocationFetchItemAction
    | StockRelocationFetchItemSuccessAction
    | StockRelocationFetchItemFailureAction
    | StockRelocationCommitAction
    | StockRelocationCommitSuccessAction
    | StockRelocationCommitFailureAction
    | StockRelocationClearAction
    | StockRelocationSetSourceLocationAction
    | StockRelocationSetDestinationLocationAction
    | StockRelocationSetQuantityAction
    | StockRelocationSetItemAction
    ;