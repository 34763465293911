export const isAllowed = (roles: string[] | string, allowedRoles: string[]) =>
{
    if(typeof roles === "string")
    {
        roles = [roles];
    }
    
    const literalMatch = roles.map(role => allowedRoles.findIndex(s => role === s))
        .reduce((result, item) => result || (item > -1), false);
    
    if(literalMatch)
    {
        return true;
    }

    const wilcards = allowedRoles
        .filter(role => role.length > 2 && role[role.length - 1] === "*" && role[role.length - 2] === ".")
        .map(role => role.substring(0, role.length - 1)) // remove trailing *
        ;
    
    return roles.findIndex(role => wilcards.findIndex(s => role.indexOf(s) === 0) > -1) > -1;
} 