import * as React from "react"
import i18n from "es2015-i18n-tag";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import Center from "../components/Center";

import { login } from "../state/user/action";
import { RootState, isLoggedIn, getLoginPending, getLoginFailed } from "../state";

export interface Props
{
}

interface State
{
    username: string;
    password: string;
}

const mapStateToProps = (state: RootState) => ({
    isLoggedIn: isLoggedIn(state),
    loginPending: getLoginPending(state),
    loginFailed: getLoginFailed(state)
})

const mapDispatchToProps = (dispatch: any) => ({
    login: (username: string, password: string) => dispatch(login(username, password) as any)
});

const LoginForm = styled.div`
    width: 65%;
`;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Login extends React.Component<Props & ConnectedProps, State>
{
    private passwordInput?: HTMLInputElement;

    state: State = {
        username: "",
        password: ""
    };

    componentWillReceiveProps(nextProps: Props & ConnectedProps)
    {
        if(nextProps.loginFailed && !this.props.loginFailed)
        {
            this.setState({ password: "" });
            if(this.passwordInput !== undefined)
            {
                this.passwordInput.focus();
            }
        }
    }

    onLoginClick = () => 
    {
        let { username, password } = this.state;
        if(username.indexOf("\\") < 0)
        {
           username = "GULDMANN\\" + username
        }
        this.props.login(username, password);
    };

    onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    {

        this.setState({username: e.currentTarget.value});
    };

    onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        this.setState({password: e.currentTarget.value});
    };

    render()
    {
        const { loginFailed, isLoggedIn } = this.props;
        const { username, password } = this.state;
        const loginDisabled = (this.props.loginPending) || (username === "") || (password === "");

        if(isLoggedIn)
        {
            return <Redirect to="/" />;
        }

        return (
            <>
                {
                    loginFailed ? (
                        <Center style={{marginTop: 16}}>
                            <Typography gutterBottom color="error">{i18n`Login failed. Username or password is invalid.`}</Typography>
                        </Center>
                    ) : null
                }
                <Center fullHeight>
                    <LoginForm>
                        <TextField label={i18n`Username`} value={username} onChange={this.onUsernameChange} margin="normal" fullWidth />
                        <TextField inputRef={input => this.passwordInput = input}  label={i18n`Password`} value={password} onChange={this.onPasswordChange} margin="normal" type="password" fullWidth /> 
                        <Button disabled={loginDisabled} color="primary" variant="contained" onClick={this.onLoginClick}>{i18n`Login`}</Button>
                    </LoginForm>
                </Center>
            </>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);