import i18n from "es2015-i18n-tag";
import Domain from "@material-ui/icons/Domain";
import StockManagement from ".";
import { Module } from "../config";

const module_: Module = { 
    title: i18n`Stock Management`, 
    icon: Domain, 
    component: StockManagement, 
    url: "/stock",
    roles: ["mobility.stock.*"]
};

export default module_;
