import { AnyAction } from "redux";

export interface BusyAction
{
    uiMeta: { busy: boolean };
}

const isBusyAction = (action: any): action is BusyAction => 
       typeof action.uiMeta === "object" 
    && typeof action.uiMeta.busy === "boolean"
    ;

const busyReducer = (state = false, action: AnyAction): boolean =>
    isBusyAction(action) ? action.uiMeta.busy : state;

export default busyReducer;