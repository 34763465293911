import * as React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CalendarIcon from "mdi-material-ui/Calendar";

function DateTitle(props: TextFieldProps)
{
    return (
        <TextField {...props}  variant="outlined" onClick={props.onClick}
            fullWidth
            InputProps={{
                readOnly: true,
                style: {
                    fontSize: "1.4rem",
                },
                inputProps: {
                    style: {
                        padding: "12px 12px 10px"
                    }
                },
                startAdornment: (
                    <InputAdornment position="start" style={{marginTop: 0}}>
                        <CalendarIcon />
                    </InputAdornment>)
            }}>         {props.value}</TextField>
    );
}

export default DateTitle;