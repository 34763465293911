import {  
    USER_LOGIN_PENDING,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    UserLoginSuccess,
    UserLogout,
    UserLoginPending,
    USER_LOGIN_FAILED,
    UserLoginFailed,
    UserClearRoles,
    UserUpdateRoles
} from "./action";

type UserActions = UserLoginSuccess | UserLogout | UserLoginPending | UserLoginFailed | UserUpdateRoles | UserClearRoles;

export interface UserState
{
    username?: string;
    pending: boolean;
    failedLogin: boolean;
    roles: string[];
}

const initialState: UserState =
{
    // username: a
    pending: false,
    failedLogin: false,
    roles: []
}

const user = (state = initialState, action: UserActions) =>
{
    if(action.type === USER_LOGIN_SUCCESS)
    {
        const { username, roles } = action;
        return {
            ...state,
            username,
            roles,
            pending: false,
            failedLogin: false
        }
    }

    if(action.type === USER_LOGOUT)
    {
        return {
            ...state,
            username: undefined,
            pending: false,
            failedLogin: false
        }
    }

    if(action.type === USER_LOGIN_PENDING)
    {
        return {
            ...state,
            pending: true,
            failedLogin: false
        }
    }

    if(action.type === USER_LOGIN_FAILED)
    {
        return {
            ...state,
            pending: false,
            username: undefined,
            failedLogin: true
        }
    }

    if (action.type === "USER_CLEAR_ROLES") 
    {
        return {
            ...state,
            roles: []
        };
    }

    if (action.type === "USER_UPDATE_ROLES") 
    {
        const { roles } = action;
        return {
            ...state,
            roles
        };
    }

    return state;
}

export const getUsername = (state: UserState) => state.username;
export const isLoggedIn = (state: UserState) => state.username !== undefined;
export const getLoginPending = (state: UserState) => state.pending;
export const getLoginFailed = (state: UserState) => state.failedLogin;
export const getUserRoles = (state: UserState) => state.roles;

export default user;