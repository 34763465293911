import { createElement } from "react";
import i18n from "es2015-i18n-tag";
import Icon from "@material-ui/icons/Build";
import MainComponent from ".";
import { Module } from "../config";
import AppBarTabs from "./components/AppBarTabs";

const config: Module =
{
    title: i18n`Tools`, 
    icon: Icon, 
    component: MainComponent, 
    url: "/tools",
    roles: ["mobility.tools.*"],
    tabs: createElement(AppBarTabs)
}

export default config;
