import * as React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import Main from "./views/Main";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppDrawer from "./components/AppDrawer";
import { store } from "./state";
// import { PersistGate } from "redux-persist/integration/react";
import jwtDecode from "jwt-decode";

import theme from"./theme";
import auth from "./service/auth";
import { userLoginSuccess, userLoggedOut } from "./state/user/action";
import { MuiThemeProvider } from "@material-ui/core";
import { RecoilRoot } from "recoil";

document.addEventListener("visibilitychange", async () => {
    if(document.visibilityState === "visible")
    {
        const authenticated = await auth.isAuthenticated();
        if(!authenticated && navigator.onLine)
        {
            await auth.logout();
        }
    }
});

auth.addEventListener("logout", () => {
    store.dispatch(userLoggedOut());
});

const checkAuthPromise = auth.loadToken()
    .then(() => auth.isAuthenticated())
    .then(result => 
    {
        if(result && auth.token !== undefined)
        {
            const tokenData = jwtDecode(auth.token.accessToken) as any;
            if(tokenData !== undefined && tokenData.sub !== undefined)
            {
                store.dispatch(userLoginSuccess(tokenData.sub, auth.roles));
            }   
        }
    });

export default () => 
{
    const app = (
        <RecoilRoot>
            <Provider store={store}>
                {/* <PersistGate persistor={persistor}> */}
                <MuiThemeProvider theme={theme}>
                    <Router>
                        <>
                            <CssBaseline />
                            <AppDrawer />
                            <Main />
                        </>
                    </Router>
                {/* </PersistGate> */}
                </MuiThemeProvider>
            </Provider>
        </RecoilRoot>
    );
    
    checkAuthPromise.then(() => render(app, document.getElementById("root")));
}
