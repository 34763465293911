import timeRecording, {
    getCheckinTypes as _getCheckinData
} from "../../modules/time-recording/state";
import stockManagement from "../../modules/stock-management/state";
import { combineReducers } from "redux";

const reducer = combineReducers(
{ 
    timeRecording, stockManagement,
    _version: (state = 0) => state
});
type CombinedState = ReturnType<typeof reducer>;

export type Module = keyof CombinedState;

export const getCheckinData = (state: CombinedState) => _getCheckinData(state.timeRecording);

export default reducer;
