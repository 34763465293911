import * as React from "react";
import i18n from "es2015-i18n-tag";
import MenuIcon from "@material-ui/icons/Menu";
import { RootState } from "../state";
import { getDrawerOpenState } from "../state";
import { connect } from "react-redux";
import { drawerOpen } from "../state/ui/actions";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import modules from "../modules/config";
import { Switch, Route } from "react-router";

export interface Props
{
    title?: string;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: RootState) => ({
    isDrawerOpen: getDrawerOpenState(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    drawerOpen: (open: boolean) => dispatch(drawerOpen(open))
});

class TitleBar extends React.Component<Props & ConnectedProps>
{
    toggleDrawer = () => this.props.drawerOpen(!this.props.isDrawerOpen);

    render()
    {
        return (
            <AppBar position="static" color="primary">
                <Toolbar>
                    <IconButton aria-label="Menu" color="inherit" onClick={this.toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="title" color="inherit">
                        {this.props.title || i18n`Guldmann Mobility`}
                    </Typography>
                </Toolbar>
                <Switch>
                {
                    modules.map(module => (
                        (module.tabs) ? (
                            <Route key={module.url} path={module.url}>{module.tabs}</Route>
                        ) : null
                    ))
                }
                </Switch>
            </AppBar>  
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TitleBar);