import React, { useEffect, useState, useCallback, FormEvent } from "react";
import { Paper, withStyles, TextField, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, IconButton, Snackbar, LinearProgress, Grid, Card, CardContent } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import i18n from "es2015-i18n-tag";
import { Scanner } from "../../../../util/scanner";
import Api from "../../../../api/api";
import { RouteComponentProps } from "react-router";

export interface Props extends RouteComponentProps<{ warehouse: string }>
{
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactChildren;
}

const styles = (theme: any) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    errorBar: {
        backgroundColor: theme.palette.error.dark
    }
})

interface InquiryItem
{
    location: string;
    locationName: string;
    itemName: string;
    sku: string;
    qty: number;
    lastUpdate?: string;
    prioDate: string;
    statusBalanceId: string;
    name: string;
}

interface InquiryResult
{
    id: string;
    name: string;
    type: string;
    items: InquiryItem[];
}

const getId = (type: string, item: InquiryItem) => (type === "item") ? item.location : item.sku;

const performInquiry = async (warehouse: string, barcode: string) => 
{
    const res = await Api.request(`stock-management/inquiry/${warehouse}/${barcode}`);
    if(!res.ok)
    {
        if(res.status === 404)
        {
            throw new Error(i18n`Item ${barcode} not found`);
        }
        else
        {
            throw new Error(await res.text());
        }
    }
    const data: InquiryResult = await res.json();
    return data;
}

function InquiryItemRow(props: {item: InquiryItem, type: string, onClick?: (item: InquiryItem) => void})
{
    const {item, type, onClick } = props;
    const [active, setActive] = useState(false);
    return (
        <TableRow 
            onClick={() => onClick?.(item)} 
            onMouseDown={() => setActive(true)} 
            onMouseUp={() => setActive(false)}
            onTouchStart={() => setActive(true)} 
            onTouchEnd={() => setActive(false)}
            selected={active}
        >
            <TableCell>{getId(type, item)}</TableCell>
            <TableCell align="right">{i18n`${new Date(item.prioDate)}:t(d)`}</TableCell>
            <TableCell align="right">{item.qty}</TableCell>
        </TableRow>
    )
}

function InquiryView(props: Props & { classes: any })
{
    const { classes, match: { params: { warehouse } } } = props;
    const [barcode, setBarcode] = useState("");
    const [result, setResult] = useState<InquiryResult | undefined>(undefined);
    const [busy, setBusy] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined);
    const [showDetails, setShowDetails] = useState<InquiryItem | undefined>(undefined);

    useEffect(() => {
        const scanner = new Scanner(async (barcode, type) => {
            setShowDetails(undefined);
            setBusy(true);
            setBarcode(barcode);
            performInquiry(warehouse, barcode)
                .then(data => setResult(data))
                .catch((err: Error) => setErrorMessage(err.message))
                .finally(() => setBusy(false))
                ;
        }, true);
        return () => {
            scanner.destroy();
        }
    }, [warehouse]);

    useEffect(() => {
        setResult(undefined);
    }, [barcode])

    const onSubmit = useCallback(async (e: FormEvent<any>) => {
        e.preventDefault();
        try {
            setBusy(true);
            const data = await performInquiry(warehouse, barcode);
            setResult(data);
        }
        catch(e)
        {
            setErrorMessage(e.message);
        }
        finally{
            setBusy(false);
        }
    }, [barcode, warehouse]);

    const onItemClick = (item: InquiryItem) => setShowDetails(item);

    if(showDetails !== undefined)
    {
        const item = showDetails;
        const type = result!.type;
        return (
            <div>
                <Card>
                    {/* <CardHeader title={getId(result!.type, item)} subheader={"Item"} /> */}
                    <CardContent>
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <Typography variant="caption">{i18n`SKU`}</Typography>
                                <Typography variant="body1">{item.sku}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption">{i18n`Item`}</Typography>
                                <Typography variant="body1">{item.itemName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption">{i18n`Location Id.`}</Typography>
                                <Typography variant="body1">{item.location}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption">{i18n`Location`}</Typography>
                                <Typography variant="body1">{item.locationName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption">{i18n`Prio. Date`}</Typography>
                                <Typography variant="body1">{i18n`${new Date(item.prioDate)}:t(d)`}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption">{i18n`Counted`}</Typography>
                                <Typography variant="body1">
                                {   
                                    (item.lastUpdate) 
                                        ? i18n`${new Date(item.lastUpdate)}:t(d)`
                                        : i18n`unknown`
                                }
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption">{i18n`Quantity`}</Typography>
                                <Typography variant="body1">{item.qty}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption">{i18n`BalSts`}</Typography>
                                <Typography variant="body1">{item.statusBalanceId}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
               </Card>
               <div style={{marginTop: 8}}>
                 <Button variant="contained" color="primary" onClick={() => setShowDetails(undefined)}>{i18n`Back`}</Button>
               </div>
            </div>

        );
    }

    return (
        <div>
            { busy ? <LinearProgress /> : null}
            <Paper elevation={1} className={classes.root}>
                <form onSubmit={onSubmit}>
                    <TextField 
                        disabled={busy}
                        inputMode="numeric"
                        label={i18n`Item / Location barcode`}
                        helperText={i18n`Scan or type the item no. to see stock status`}
                        margin="normal" 
                        variant="standard" 
                        fullWidth 
                        InputProps={{
                            endAdornment: (barcode.length > 0) ? (
                                <InputAdornment position="end">
                                    <IconButton aria-label={i18n`Clear`} style={{padding: 0}} onClick={() => setBarcode("")}>
                                        <Clear />
                                    </IconButton>
                                </InputAdornment>
                            ) : undefined
                        }}
                        value={barcode} 
                        onChange={e => setBarcode(e.target.value)} 
                        onKeyDown={e => e.stopPropagation()}
                        onKeyUp={e => e.stopPropagation()}
                        onKeyPress={e => e.stopPropagation()}
                        />
                    <Button variant="contained" color="primary" onClick={onSubmit} disabled={busy || barcode.length === 0}>{i18n`Lookup`}</Button>
                </form>
            </Paper>

            { (result) ? (
                <>
                <Paper elevation={1} style={{marginTop: 8}} className={classes.root}>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <Typography variant="caption">{result.type === "item" ? i18n`Item` : i18n`Location`}</Typography>
                            <Typography variant="body1">{result.id}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="caption">{i18n`Name`}</Typography>
                            <Typography variant="body1">{result.name}</Typography>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={1} style={{marginTop: 8}}>
                    <Table padding="dense">
                        <TableHead>
                            <TableRow>
                                <TableCell>{result.type === "item" ? i18n`Location` : i18n`Item`}</TableCell>
                                <TableCell align="right">{i18n`Prio. Date`}</TableCell>
                                <TableCell align="right">{i18n`Qty`}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            result.items.map(line => (
                                <InquiryItemRow key={getId(result.type, line)} 
                                    item={line} 
                                    onClick={onItemClick}
                                    type={result.type} /> 
                                ))
                                // <TableRow key={getId(result, line)} onClick={() => alert("ok")}>
                                //     <TableCell>{getId(result, line)}</TableCell>
                                //     <TableCell align="right">{i18n`${new Date(line.prioDate)}:t(d)`}</TableCell>
                                //     <TableCell align="right">{line.qty}</TableCell>
                                // </TableRow>
                            // ))
                        }
                        </TableBody>
                    </Table>
                </Paper>
                </>
            ) : null
            }
            <Snackbar 
                ContentProps={{className: classes.errorBar}}
                autoHideDuration={5000} 
                message={errorMessage} 
                open={errorMessage !== undefined}
                onClose={() => setErrorMessage(undefined)} />
        </div>
    );
}

export default withStyles(styles)(InquiryView);