import { CheckInOut, Absence, JobElement, JobActivity } from "./typings";

export const transactionType: CheckInOut[] = [
    { id: "A0", label: "Indstempling normal",                   type: "in",  lang: "da-dk", next: "B0" },
    { id: "B0", label: "Udstempling normal",                    type: "out", lang: "da-dk" },
    { id: "C0", label: "Indstempling overtid mod betaling",     type: "in",  lang: "da-dk", next: "D0" },
    { id: "D0", label: "Udstempling overtid mod betaling",      type: "out", lang: "da-dk" },
    { id: "E0", label: "Indstempling overtid mod afspadsering", type: "in",  lang: "da-dk", next: "F0" },
    { id: "F0", label: "Udstempling overtid mod afspadsering",  type: "out", lang: "da-dk" }
];

export const absenceType: Absence[] = [
    { "id": "1", "lang": "da-dk", "label": "Ferie" },
    { "id": "2", "lang": "da-dk", "label": "Ferie/fri" },
    { "id": "3", "lang": "da-dk", "label": "Barn 1. sygedag / hospitalsophold" },
    { "id": "4", "lang": "da-dk", "label": "Sygdom (ansat > 6 mdr.)" },
    { "id": "5", "lang": "da-dk", "label": "Sygdom (ansat < 6 mdr.)" },
    { "id": "6", "lang": "da-dk", "label": "Barsel" },
    { "id": "7", "lang": "da-dk", "label": "Afspadsering" },
    { "id": "8", "lang": "da-dk", "label": "Fri uden løn" },
    { "id": "9", "lang": "da-dk", "label": "Fridag 31/12" },
    { "id": "10", "lang": "da-dk", "label": "Blodbank, begravelse" },
    { "id": "11", "lang": "da-dk", "label": "Arbejdsulykke" },
    { "id": "12", "lang": "da-dk", "label": "Plejeorlov" },
    { "id": "13", "lang": "da-dk", "label": "Flex afholdelse" },
    { "id": "14", "lang": "da-dk", "label": "Flexjob ordning" },
    { "id": "15", "lang": "da-dk", "label": "Ledighedsdage" },
    { "id": "16", "lang": "da-dk", "label": "Børneomsorgsdag" }
];

export const elementType: JobElement[] = [
    { "id": "120", "lang": "da-dk", "label": "Timer" },
    { "id": "122", "lang": "da-dk", "label": "Køretid" }
];

export const activityType: JobActivity[] = [
    { "id": "4", "lang": "da-dk",  "label": "Møde og administration" },
    { "id": "6", "lang": "da-dk",  "label": "Status og optælling" },
    { "id": "7", "lang": "da-dk",  "label": "Oprydning" },
    { "id": "10", "lang": "da-dk", "label": "Uddannelse" },
    { "id": "13", "lang": "da-dk", "label": "RMA" },
    { "id": "19", "lang": "da-dk", "label": "Vedligeholdelse" }
];