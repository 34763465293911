import * as  React from "react";
import { useMemo } from "react";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete"

import { ManualEntryEvent } from "../../state";

export function DeleteAction(props: { event: ManualEntryEvent, onClick?: (event: ManualEntryEvent) => void })
{
    const { event, onClick } = props;

    const clickHandler = useMemo(() =>
        onClick ? () => onClick(event) : undefined
        , [event, onClick]);

    return (
        <ListItemSecondaryAction>
            <IconButton aria-label="Menu" onClick={clickHandler}>
                <DeleteIcon />
            </IconButton>
        </ListItemSecondaryAction>
    );
}
