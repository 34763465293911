import { Dispatch } from "redux";
import { updateItem } from "../../../api/StockApi";
import { generateUuid } from "../../../../../util/uuid";
import { Notification } from "../../types";
import { ActionFailure } from "../../action";
import i18n from "es2015-i18n-tag";

export const STOCK_UPDATE_ITEM = "STOCK_UPDATE_ITEM";
export interface StockUpdateItemAction
{
    
    type: "STOCK_UPDATE_ITEM";
    draftId: string;
    barcode: string;
    location: string;
    quantity: number;
    warehouse: string;
    sku: string;
}
export const _stockUpdateItem = (draftId: string, warehouse: string, barcode: string, location: string, quantity: number, sku: string): StockUpdateItemAction => ({
    type: STOCK_UPDATE_ITEM,
    draftId, barcode, location, quantity, warehouse, sku
});

export const stockUpdateItem = (draftId: string, warehouse: string, barcode: string, location: string, quantity: number, sku: string) => (dispatch: Dispatch<any>) => 
{
    dispatch(_stockUpdateItem(draftId, warehouse, barcode, location, quantity, sku));
    let errorMessage = i18n`Could not update inventory draft.`;
    (async () => {
        try
        {
            const result = await updateItem(draftId, warehouse, barcode, location, quantity);
            if(result)
            {
                const message = `Updated ${sku} quantity to ${quantity} at ${location}`;
                const notification: Notification = {
                    id: generateUuid(),
                    message,
                    severity: "success",
                    source: "client"
                }
                dispatch(stockUpdateItemSuccess(draftId, warehouse, barcode, location, quantity, notification));
                return;
            }
        }
        catch(e)
        {
            if(e.message)
            {
                errorMessage = e.message;
            }
            console.warn(e);
        }

        dispatch(stockUpdateItemFailure(generateUuid(), errorMessage)); 
    })();
}

export const STOCK_UPDATE_ITEM_SUCCESS = "STOCK_UPDATE_ITEM_SUCCESS";
export interface StockUpdateItemSuccessAction
{
    type: "STOCK_UPDATE_ITEM_SUCCESS";
    draftId: string;
    barcode: string;
    location: string;
    quantity: number;
    warehouse: string;
    notification?: Notification;
}
export const stockUpdateItemSuccess = (draftId: string, warehouse: string, barcode: string, location: string, quantity: number, notification?: Notification): StockUpdateItemSuccessAction => ({
    type: STOCK_UPDATE_ITEM_SUCCESS,
    draftId, barcode, location, quantity, warehouse, notification
});

export const STOCK_UPDATE_ITEM_FAILURE = "STOCK_UPDATE_ITEM_FAILURE";
export interface StockUpdateItemFailureAction extends ActionFailure
{
    type: "STOCK_UPDATE_ITEM_FAILURE";
}
export const stockUpdateItemFailure = (id: string, message: string): StockUpdateItemFailureAction => ({
    type: STOCK_UPDATE_ITEM_FAILURE,
    notification: {
        id, message,
        source: "server",
        severity: "error"
    }
});
