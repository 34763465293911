import * as React from "react"
import Typography from "@material-ui/core/Typography";

export interface Props
{
}


class Overview extends React.Component<Props>
{
    render()
    {
        return (
            <div style={{padding: 16}}>
                <Typography variant="title" gutterBottom>
                    Welcome to Guldmann Mobility
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Open the menu in the upper left corner to 
                    start using the app.
                </Typography>
            </div>
        );
    }
}

export default Overview;