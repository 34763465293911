import { Action } from "redux";

export const DRAWER_OPEN = "DRAWER_OPEN";
export interface DrawerOpenAction extends Action
{
    type: "DRAWER_OPEN",
    open: boolean;
}
export const drawerOpen = (open = true): DrawerOpenAction => ({
    type: DRAWER_OPEN,
    open
});

export const BUSY = "BUSY";
export interface Busy
{
    type: "BUSY";
    busy: boolean;
}

export const busy = (busy: boolean): Busy => ({ type: BUSY, busy });
