import styled, { keyframes }from "styled-components";

const kf = keyframes`
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
`;

interface Props
{
    duration?: number;
    easing?: string;
}

const SoftBlink = styled.div<Props>`
    animation: ${kf} ${props => (props.duration || 3) + "s"} infinite ${props => props.easing || "ease-in-out"};
`;

export default SoftBlink;


