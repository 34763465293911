import  { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import { OfflineAction } from "@redux-offline/redux-offline/lib/types";

import { createStore, applyMiddleware, compose } from "redux";
import { createTransform } from "redux-persist";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
// import { persistReducer, PersistConfig, persistStore, Storage } from "redux-persist";
// import _storage from "redux-persist/lib/storage";

import rootReducer from "./reducers";
import auth from "../service/auth";
import Api from "../api/api";

export interface Effect
{
    username: string;
    url: string;
    method?: string;
    body?: any
}

interface DiscardError
{
    username?: string;
    status?: number;
}

offlineConfig.discard = (error: DiscardError, _action: OfflineAction, _retries: number) =>
{
    if(error.username !== undefined)
    {
        return false;
    }
    if(error.status !== undefined)
    {
        return (400 <= error.status) && (error.status < 500); // discard on client errors
    }
    return true;
};

offlineConfig.effect = ({username, url, method = "GET", body}: Effect) =>
    auth.username === username 
        ? Api.request(url, method, body).then(res => res.ok ? Promise.resolve() : Promise.reject({ status: res.status }))
        : Promise.reject({ username });


const logger = createLogger({ collapsed: true });
export default (stateVersion: number) => 
{
    const upgradeTransform = createTransform(
        (inState: object, key) => { 
            if(key === "module")
            {
                return { ...inState, _version: stateVersion };
            }
            return inState;
        },
        (outState: any, key) => 
        { 
            if(key === "module" && (outState._version === undefined || outState._version < stateVersion))
            {
                console.info("Module state cleared for upgrade");
                return undefined;
            }
            return outState;
        }
    );

    const store = createStore(rootReducer,
        compose(
            applyMiddleware(
                thunkMiddleware,
                logger
            ),
            offline({ ...offlineConfig, persistOptions: { transforms: [upgradeTransform] }})
        )
    );

    return { store }
}