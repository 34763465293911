import * as React from "react";
import i18n from "es2015-i18n-tag";
import { RouteComponentProps } from "react-router";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import { Scanner, BarcodeType } from "../../../../util/scanner";
import { stockRelocationFetchItem, stockRelocationSetItem, stockRelocationSetSourceLocation, stockRelocationSetDestinationLocation, stockRelocationSetQuantity, stockRelocationCommit, stockRelocationClear } from "../../state/action/relocation";
import { connect } from "react-redux";
import { Item, RelocationRequest } from "../../api/StockApi";
import { getRelocationRequest, getCurrentItem, StockState } from "../../state/reducer";

interface RouteProps
{
    warehouse: string;
}

export interface Props extends RouteComponentProps<RouteProps>
{
    
}

const mapStateToProps = (state: StockState) => ({
    item: getCurrentItem(state),
    request: getRelocationRequest(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchItem: (barcode: string) => dispatch(stockRelocationFetchItem(barcode)),
    setItem: (item: Item) => dispatch(stockRelocationSetItem(item)),
    setSourceLocation: (location: string) => dispatch(stockRelocationSetSourceLocation(location)),
    setDestLocation: (location: string) => dispatch(stockRelocationSetDestinationLocation(location)),
    setQuantity: (qty: number) => dispatch(stockRelocationSetQuantity(qty)),
    clear: () => dispatch(stockRelocationClear()),
    commit: (request: RelocationRequest) => dispatch(stockRelocationCommit(request)),
    clearNotifications: () => dispatch({ type: "NOTIFICATIONS_CLEAR" }),
});

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface State
{
    quantityInput: string;
}

const STEP_LOCATION_SOURCE = 0;
const STEP_ITEM = 1;
const STEP_QUANTITY = 2;
const STEP_LOCATION_DEST = 3;
const STEP_REVIEW = 4;

class Relocation extends React.Component<Props & StateProps, State>
{
    private qtyInput?: HTMLInputElement;
    private scanner?: Scanner;
    private clearNotificationsHandle: any = null;

    state: State = { quantityInput: "" };

    componentDidMount()
    {
        this.scanner = new Scanner(this.onItemScanned, true);
        if(this.props.item !== undefined)
        {
            this.onItemFetched(this.props.item);
        }
    }

    componentWillUnmount()
    {
        if(this.scanner !== undefined)
        {
            this.scanner.destroy();
            this.scanner = undefined;
        }
    }

    componentWillReceiveProps(nextProps: Props & StateProps)
    {
        const { clearNotifications } = nextProps;

        if (nextProps.item !== undefined)
        {
            this.onItemFetched(nextProps.item);
        }

        if (this.getActiveStep() === STEP_LOCATION_SOURCE && this.clearNotificationsHandle === null)
        {
            this.clearNotificationsHandle = setTimeout(() => {
                clearNotifications();
                this.clearNotificationsHandle = null;
            }, 3000);
        }
    }

    componentDidUpdate()
    {
        if(this.getActiveStep() === STEP_QUANTITY && this.qtyInput !== undefined)
        {
            this.qtyInput.focus();
        }
    }

    private onItemFetched(item: Item)
    {
        const step = this.getActiveStep();
        if(step === STEP_ITEM)
        {
            this.props.setItem(item);
        }
    }

    private onItemScanned = (barcode: string, type: BarcodeType) =>
    {
        const step = this.getActiveStep();
        if(step === STEP_LOCATION_SOURCE && type === "location")
        {
            this.props.setSourceLocation(barcode);
        }
        else if (step === STEP_LOCATION_DEST && type === "location")
        {
            this.props.setDestLocation(barcode);
        }
        else if(step === STEP_ITEM && type === "item")
        {
            this.props.fetchItem(barcode);
        }
    }

    private getActiveStep(): number
    {
        if(this.props.request.from === undefined)
        {
            return STEP_LOCATION_SOURCE;
        }

        if(this.props.request.item === undefined)
        {
            return STEP_ITEM;
        }

        if(this.props.request.qty === undefined)
        {
            return STEP_QUANTITY;
        }

        if(this.props.request.to === undefined)
        {
            return STEP_LOCATION_DEST;
        }

        return STEP_REVIEW;
    }

    private onQuantityKeypress = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        this.setState({ quantityInput: e.currentTarget.value });
    }

    private isValidQuantityInput = () => /^\d+(\.\d+)?$/.test(this.state.quantityInput);

    private onQuantityCommit = () => this.props.setQuantity(parseFloat(this.state.quantityInput));

    private onCommit = () =>
    {
        const request: RelocationRequest = {
            ...this.props.request as RelocationRequest,
            warehouse: this.props.match.params.warehouse
        }
        // I: to get this far all fields of request has been filled in
        this.props.commit(request);
    }

    private onClear = () => 
    {
        this.props.clear();
    }

    render()
    {
        const {
            to: locationDest = undefined,
            from: locationSource = undefined,
            item = undefined,
            qty: quantity = undefined,
        } = this.props.request;

        const activeStep = this.getActiveStep();
        return (
            <div>
                <Typography variant="h6">Relocation</Typography>
                <div style={{marginTop: 8}}>
                    <Button variant="contained" onClick={this.onClear}>{i18n`Reset`}</Button>
                </div>
                <Stepper orientation="vertical" activeStep={activeStep} elevation={0} style={{backgroundColor: "transparent"}}>
                    <Step completed={locationSource !== undefined}>
                        <StepLabel>{locationSource === undefined ? i18n`Scan source location` : i18n`Source location: ${locationSource}`}</StepLabel>
                        <StepContent>
                            <Typography>{i18n`Scan the location barcode where the items are currently located.`}</Typography>
                        </StepContent>
                    </Step>
                    <Step completed={item !== undefined}>
                        <StepLabel>{item === undefined ? i18n`Scan item`: i18n`Scan Item (SKU): "${item.sku}"`}</StepLabel>
                        <StepContent>
                            <Typography>{i18n`Scan the item that is being relocated.`}</Typography>
                        </StepContent>
                    </Step>
                    <Step completed={quantity !== undefined}>
                        <StepLabel>{quantity === undefined ? i18n`Enter quantity` : i18n`Quantity: ${quantity.toFixed(2)}`}</StepLabel>
                        <StepContent>
                            <TextField variant="outlined" label={i18n`Quantity to relocate`} 
                                       onChange={this.onQuantityKeypress}
                                       InputProps={{endAdornment: <InputAdornment position="end">pcs</InputAdornment>, type: "number" }}
                                       style={{marginTop: 8, marginBottom: 8}} 
                                       inputRef={input => this.qtyInput = input} />
                            <Button variant="contained" disabled={!this.isValidQuantityInput()} onClick={this.onQuantityCommit}>{i18n`Ok`}</Button>
                        </StepContent>
                    </Step>
                    <Step completed={locationDest !== undefined}>
                        <StepLabel>{locationDest === undefined ? i18n`Scan destination location` : i18n`Destination location: ${locationDest}`}</StepLabel>
                        <StepContent>
                            <Typography>{i18n`Scan the location where the items are being moved to.`}</Typography>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>{i18n`Review and commit`}</StepLabel>
                        <StepContent>
                            {
                                (item !== undefined && locationDest !== undefined && locationSource !== undefined) ? (
                                    <Grid container>
                                        <Grid item xs={12}><Typography variant="caption">{i18n`Item`}</Typography></Grid>
                                        <Grid item xs={12}><Typography gutterBottom>{item.name}</Typography></Grid>
        
                                        <Grid item xs={6}><Typography variant="caption">{i18n`From`}</Typography></Grid>
                                        <Grid item xs={6}><Typography variant="caption">{i18n`To`}</Typography></Grid>
                                        <Grid item xs={6}><Typography gutterBottom>{locationSource}</Typography></Grid>
                                        <Grid item xs={6}><Typography gutterBottom>{locationDest}</Typography></Grid>
        
                                        <Grid item xs={12}><Typography variant="caption">{i18n`Quantity`}</Typography></Grid>
                                        <Grid item xs={12}><Typography gutterBottom>{quantity}</Typography></Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" onClick={this.onCommit}>{i18n`Commit Move`}</Button>
                                        </Grid>
                                    </Grid>
                                ) : null
                            }
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Relocation);