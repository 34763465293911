import { StockRelocationCommitSuccessAction, StockRelocationCommitFailureAction } from "../action/relocation";
import {produce } from "immer";
import i18n from "es2015-i18n-tag";

export interface Notification
{
    id: string;
    message: string;
    source: "server" | "client";
    severity: "warning" | "error" | "success";
}

export interface State
{
    notifications: Notification[];
}

const initialState: State = { notifications: [] };

type Actions =     
    | StockRelocationCommitSuccessAction
    | StockRelocationCommitFailureAction
    | { type: "NOTIFICATIONS_CLEAR" }
    ;


export default (state = initialState, action: Actions ): State => produce(state, draft => {

    if (action.type === "STOCK_RELOCATION_COMMIT_SUCCESS")
    {
        draft.notifications.push({
            id: "relocation_success",
            message: i18n`Item was successfully moved`,
            severity: "success",
            source: "server"
        });
    }

    if (action.type === "STOCK_RELOCATION_COMMIT_FAILURE")
    {
        draft.notifications.push({
            id: "relocation_failed",
            message: action.message,
            severity: "error",
            source: "server"
        });
    }

    if (action.type === "NOTIFICATIONS_CLEAR")
    {
        if (draft.notifications.length > 0)
        {
            draft.notifications = [];
        }
    }
});

export const getModuleNotifications = (state: State) => state.notifications;
