import * as React from "react"
import i18n from "es2015-i18n-tag"
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import RadioButtonGroup from "../../../components/RadioButtonGroup";
import RadioButton from "../../../components/RadioButton";
import { getCheckinTypes, getAbsenceTypes, CheckinEvent, getCurrentTransaction, TimeRecordingState } from "../state";
import { connect } from "react-redux";
import { checkIn } from "../events";
import { RouteComponentProps } from "react-router";

export interface Props extends ReturnType<typeof mapStateToProps>, RouteComponentProps<any>
{
    username: string;
    onCheckin?: (username: string, event: CheckinEvent) => void;
}

const Block = styled.div`
    padding: 16px;
    margin-top: 16px;
`;

const ButtonBar = styled.div`
    margin-top: 32px;
`;

interface State
{
    checkin?: string;
    absence?: string;
}

class CheckInOut extends React.Component<Props, State>
{
    state: State = 
    {
        absence: "__NONE__"
    }
    
    private onCheckinChange = (checkin: string) =>
    {
        this.setState({ checkin });
    }

    private onAbsenceChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    {
        this.setState({ absence: e.target.value });
    }

    private onCheckinClick = () =>
    {
        if(this.state.checkin !== undefined)
        {
            const { absence } = this.state;
            const { username } = this.props;
            const event = checkIn(username, absence === "__NONE__" ? undefined : absence);
            if(this.props.onCheckin !== undefined)
            {
                this.props.onCheckin(username, event);
            }
        }
    }

    render()
    {
        const { transactionType, absenceType } = this.props;
        const { checkin } = this.state;
        const c2: any = "fieldset";
        return (
            <Block>
                <RadioButtonGroup title={i18n`Check In`} onChange={this.onCheckinChange} value={checkin} required>
                {
                    transactionType.filter(t => t.type === "in").map(t => (
                        <RadioButton value={t.id} key={t.id} label={t.label} />
                    ))
                }
                </RadioButtonGroup>
                <FormControl component={"legend" as any} fullWidth style={{marginTop: 16}}>
                    <FormLabel component={c2}>{i18n`Absence`}</FormLabel>
                    <FormControl fullWidth>
                        <Select value={this.state.absence} onChange={this.onAbsenceChange}>
                            <MenuItem value="__NONE__">{i18n`None`}</MenuItem>
                            {
                                absenceType.map(type => <MenuItem key={type.id} value={type.id}>{type.label}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </FormControl>

                <ButtonBar>
                    <Button variant="contained" color="primary" disabled={checkin === undefined} onClick={this.onCheckinClick}>{i18n`Check In`}</Button>
                </ButtonBar>
            </Block>
        );
    }
}

const mapStateToProps = (state: TimeRecordingState, ownProps: {username: string}) => {
    return ({
        transactionType: getCheckinTypes(state),
        absenceType: getAbsenceTypes(state),
        transaction: getCurrentTransaction(ownProps.username, state)
    });
};

export default connect(mapStateToProps)(CheckInOut);