export const toMap = <TNode>(nodes: TNode[], keySelector: (node: TNode) => string | number) => 
    nodes.reduce((result, node) => { 
        result[keySelector(node)] = node;
        return result;
    }, {} as { [id: string]: TNode });

export const groupBy = <TNode>(nodes: TNode[], keySelector: (node: TNode) => string) => 
    nodes.reduce((result, node) => { 
        result[keySelector(node)] = [...(result[keySelector(node)] || []), node];
        return result;
    }, {} as { [id: string]: TNode[] });

export const getObjectValues = <T>(obj: { [id: string]: T } ): T[] =>
    Object.keys(obj).map(key => obj[key]);