import * as React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

export interface Props
{
    value: string;
    label: string;
    disabled?: boolean;
}

class RadioButton extends React.Component<Props>
{
    render()
    {
        return (
            <FormControlLabel {...this.props} control={<Radio />} />
        )
    }
}

export default RadioButton;