import * as React from "react";
import { useCallback, useState, useRef, useContext, useEffect } from "react";
import i18n from "es2015-i18n-tag";
import { Grid, withStyles, TextField, FormControl, InputLabel, Button, CardContent, Typography, Snackbar, FormLabel, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { API_BASE_URL } from "../../../../api/api";
import { ModuleContext, ModuleContextData } from "../../../../context/ModuleContext";
import Api from "../../../../api/api";
import { RouteComponentProps } from "react-router";
import auth from "../../../../service/auth";

type InputElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

enum searchTypeEnum { Manufacturing, Purchase, Item }

const fetch = auth.getAuthenticatedFetch();

export interface Props extends RouteComponentProps<{ warehouse: string }> {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactChildren;
}

const styles = (theme: any) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    errorBar: {
        backgroundColor: theme.palette.error.dark
    }
})

function useChangeCallback<T extends InputElement>(setValue: (s: string) => void) {
    return useCallback((e: React.ChangeEvent<T>) => setValue(e.target.value), [setValue]);
}

function useFormState<T extends InputElement>(initialValue: string): [string, (e: React.ChangeEvent<T>) => void, (v: string) => void] {
    const [value, setValue] = useState<string>(initialValue);
    return [value, useChangeCallback<T>(setValue), setValue];
}

interface Values {
    qtySmall: string;
    qtyLarge: string;
    qtyBoxSmall: string;
    qtyBoxLarge: string;
    itemNumber: string;
    searchType: string;
}

interface itemResult {
    ean: string;
    sku: string;
    unit: string;
    name: string;
    tracking: string
    lotNumber: string
    defaultLoc: string
    edition: string
    quantity: number
}

const ProductLabel = (props: Props & { classes: any }) => {

    const { classes, match: { params: { warehouse } } } = props;
    const context = useContext(ModuleContext);
    const inputRef = useRef<HTMLInputElement>();
    const [qtySmall, onSmallQtyChanged, setQtySmall] = useFormState("0");
    const [qtyLarge, onLargeQtyChanged, setQtyLarge] = useFormState("0");
    const [qtyBoxSmall, onBoxSmallQtyChanged, setQtyBoxSmall] = useFormState("0");
    const [qtyBoxLarge, onBoxLargeQtyChanged, setQtyBoxLarge] = useFormState("0");
    const [item, setItem] = useState<itemResult | undefined>(undefined);
    const [itemNumber, onItemNumberChanged, setItemNumber] = useFormState("");
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [searchType, setTypeOfSearch] = useState(searchTypeEnum[searchTypeEnum.Item]);

    const handleSearchTypeChange = (event: React.ChangeEvent<any>) => {
        setTypeOfSearch(event.currentTarget.value);
        setItemNumber("");
        setQtyBoxLarge("0");
        setQtyBoxSmall("0");
        setQtyLarge("0");
        setQtySmall("0");
        setItem(undefined);
        setErrorMessage(undefined);
    }

    const qtyIsValid = (quantity: string) => { return /^\s*[0-4]?[0-9]?[0-9]\s*$/.test(quantity); }

    const values = useRef<Values>();
    values.current = {
        itemNumber,
        qtySmall,
        qtyLarge,
        qtyBoxSmall,
        qtyBoxLarge,
        searchType
    }

    const onItemBlur = React.useCallback(() => {
        performInquiry("001", itemNumber)
            .catch((err: Error) => setErrorMessage(err.message));
    }, [itemNumber]);

    const performInquiry = async (warehouse: string, barcode: string) => {
        const res = await Api.request(`lookup/itemnumber/${itemNumber}/${searchType}`);
        if (!res.ok) {
            if (res.status === 404) {
                throw new Error(i18n`Search criteria ${itemNumber} not found`);
            }
            else {
                throw new Error(await res.text());
            }
        }

        const data: itemResult = await res.json();
        setQtySmall(data.quantity.toString());

        const resEdition = await Api.request(`lookup/edition/${data.sku}`);
        if (!resEdition.ok) {
            if (resEdition.status === 404) {
                throw new Error(i18n`Editon not found for ${itemNumber}`);
            }
            else {
                throw new Error(await resEdition.text());
            }
        }
        else {
            data.edition = await resEdition.json();
        }

        setItem(data);
        return data;
    }

    const onCommit = React.useCallback(() => {
        console.log(values.current);
        fetch(`${API_BASE_URL}/stock-management/label/printproductlabel`, {
            method: "POST",
            body: JSON.stringify({ ...values.current }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.ok) {
                setItemNumber("");
                setQtyBoxLarge("0");
                setQtyBoxSmall("0");
                setQtyLarge("0");
                setQtySmall("0");
                setItem(undefined);
                setErrorMessage(undefined);
                context.toast({
                    message: i18n`Label printing request successful.`,
                    severity: "success"
                }, 3000);
            }
            else {
                return res.json().then(Promise.reject);
            }
        }).catch(e => {
            context.toast({
                message: e.errorMessage || i18n`Label printing failed.`,
                severity: "error"
            });
        })
            ;
    }, [item?.sku]);

    return (
        <>
            <Grid container spacing={8} >
                <Grid item xs={12}>
                    <div style={{ flexGrow: 1 }}>
                        <RadioGroup name="SearchType" defaultValue={searchType} onChange={e => handleSearchTypeChange(e)} row>
                            <FormControlLabel value={searchTypeEnum[searchTypeEnum.Item]} control={<Radio />} label="Item" />
                            <FormControlLabel value={searchTypeEnum[searchTypeEnum.Manufacturing]} control={<Radio />} label="Manufacturing" />
                            <FormControlLabel value={searchTypeEnum[searchTypeEnum.Purchase]} control={<Radio />} label="Purchase" />
                        </RadioGroup>
                    </div>
                </Grid>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ flexGrow: 1 }}>
                        <TextField label={i18n`Search`} fullWidth
                            value={itemNumber}
                            onBlur={onItemBlur}
                            onChange={onItemNumberChanged}
                            inputRef={inputRef}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <TextField label={i18n`Small Qty`}
                        helperText={qtyIsValid(qtySmall) ? undefined : i18n`Value must be between 0 and 499`}
                        value={qtySmall}
                        onChange={onSmallQtyChanged}
                        type="number" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField label={i18n`Large Qty`}
                        helperText={qtyIsValid(qtyLarge) ? undefined : i18n`Value must be between 0 and 499`}
                        value={qtyLarge}
                        onChange={onLargeQtyChanged}
                        type="number" fullWidth />
                </Grid>

                <Grid item xs={12}>
                    <div>
                        <TextField label={i18n`Box Qty small`}
                            helperText={qtyIsValid(qtyBoxSmall) ? undefined : i18n`Value must be between 0 and 499`}
                            value={qtyBoxSmall}
                            onChange={onBoxSmallQtyChanged}
                            type="number" />
                        <TextField label={i18n`Box Qty large`}
                            helperText={qtyIsValid(qtyBoxLarge) ? undefined : i18n`Value must be between 0 and 499`}
                            value={qtyBoxLarge}
                            onChange={onBoxLargeQtyChanged}
                            type="number" />
                    </div>
                </Grid>

                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid container>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 8 }}>
                    <Button
                        variant="contained"
                        onClick={onCommit}
                    >{i18n`Print`}</Button>
                </Grid>

                <Grid item xs={12}>
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            Item
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {item?.sku} {item?.name}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            Std. Location
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {item?.defaultLoc}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            Edition
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {item?.edition}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
            <Snackbar
                ContentProps={{ className: classes.errorBar }}
                autoHideDuration={5000}
                open={errorMessage !== undefined}
                message={errorMessage}
                onClose={() => setErrorMessage(undefined)} />
        </>
    );
}

export default withStyles(styles)(ProductLabel);
