import * as React from "react";
import i18n from "es2015-i18n-tag";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { createOrderActivityEvent } from "../../events";
import { Event, getCurrentTransaction, TimeRecordingState } from "../../state";
import { transactionEventAdd } from "../../state/action";

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export interface Props extends RouteComponentProps<any>, StateProps
{
    username: string;
}

interface State
{
    orderNo: string;
}

class AddOrder extends React.Component<Props, State>
{
    state: State = { orderNo: "" };

    private onOrderNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => 
    {
        this.setState({ orderNo: e.target.value });
    };

    private onAdd = () => 
    {
        const { transaction, transactionEventAdd, username } = this.props;
        const { orderNo } = this.state;
        if(transaction !== undefined)
        {
            const event = createOrderActivityEvent(username, transaction.id, orderNo);
            transactionEventAdd(event);
        }
        this.props.history.goBack();
    };

    private onCancel = () =>
    {
        this.props.history.goBack();
    }

    render()
    {
        const { orderNo } = this.state;

        return (
            <div style={{padding: 16}}>
                <Typography variant="headline" gutterBottom> {i18n`Add Order Activity`}</Typography>
                <TextField fullWidth style={{marginTop: 8}} label={i18n`Order Number`}
                    value={orderNo} onChange={this.onOrderNumberChange} />

                <div style={{marginTop: 16}}>
                    <Button variant="raised" color="primary" onClick={this.onAdd} disabled={orderNo.length < 4}>{i18n`Add`}</Button>
                    <Button variant="raised" color="default" onClick={this.onCancel} style={{ marginLeft: 8 }}>{i18n`Cancel`}</Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: TimeRecordingState, ownProps: { username: string }) => 
{
    const username = ownProps.username;
    return ({
        transaction: getCurrentTransaction(username, state)
    });
};

const mapDispatchToProps = (dispatch: any) => ({
    transactionEventAdd: (event: Event) => dispatch(transactionEventAdd(event))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOrder);