import React from "react";
import cx from "classnames";
import Snackbar from "@material-ui/core/Snackbar";
import { Severity, NotificationMessage } from "../modules/config";

import { Theme, SnackbarContent, IconButton, withStyles } from "@material-ui/core";
import { green, amber } from "@material-ui/core/colors";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";

import { StylesProps, StylesClasses } from "../types";

export interface Props extends StylesProps<typeof styles>
{
    messages: NotificationMessage[];
    onDismiss: (message: NotificationMessage) => void;
}

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
  };

const styles = (theme: Theme) => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});
type Classes = StylesClasses<typeof styles>;

const SnackbarIcon = ({severity, classes}: {severity: Severity, classes: Classes}) => {
    const Icon = variantIcon[severity];
    return <Icon className={cx(classes.icon, classes.iconVariant)}/>;
};

interface NotificationSnackbarContentProps
{
    message: NotificationMessage; 
    classes: Classes; 
    onClose: (message: NotificationMessage) => void;
}

const NotificationSnackbarContent = ({ message, classes, onClose }: NotificationSnackbarContentProps) => (
    <SnackbarContent
        className={classes[message.severity]}
        message={
            <span className={classes.message}>
                <SnackbarIcon severity={message.severity} classes={classes} />
                {message.message}
            </span>
        }
        action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => onClose(message)}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
     />
);

function NotificationManager(props: Props)
{
    const { messages, classes, onDismiss } = props;
    const snacks = messages.map(message => (
        <Snackbar open>
            <NotificationSnackbarContent message={message} classes={classes} onClose={onDismiss} />
        </Snackbar>
    ));
    return <>{snacks}</>;
};

const NotificationManagerWithStyles = withStyles(styles)(NotificationManager);

export { NotificationManagerWithStyles as NotificationManager };
