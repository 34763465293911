import * as React from "react";
import i18n from "es2015-i18n-tag";
import { connect } from "react-redux";
import { Event, TimeRecordingState, getActivityTypes, getElementTypes, ManualEntryEvent, getAbsenceTypes } from "../state";

import green from "@material-ui/core/colors/green";

import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";

import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Receipt from "@material-ui/icons/Receipt";
import Flag from "@material-ui/icons/Flag";
import MoreHoriz from "@material-ui/icons/MoreHoriz";

interface Props
{
    currentEvent?: Event;
    events: Event[];
    currentTitle?: string;
    restTitle?: string;
    getSecondaryText?: (event: Event) => string;
    secondaryAction?: (event: Event) => typeof ListItemSecondaryAction;
    // itemContextMenu?: (event: Event) => Menu
}

const mapStateToProps = (state: TimeRecordingState) => ({
    activityTypes: getActivityTypes(state),
    elementTypes: getElementTypes(state),
    absenceTypes: getAbsenceTypes(state)
});

export const formatEventTime = (event: Event) => i18n`${new Date(new Date(event.date))}:t(t)`;

class EventList extends React.Component<Props & ReturnType<typeof mapStateToProps>>
{
    private getEventIcon(event: Event)
    {
        switch(event.type)
        {
            case "ACTIVITY_ORDER":
                return  <ShoppingCart />;
            case "ACTIVITY_OTHER":
                return <Flag />;
            case "ACTIVITY_PROJECT":
                return <Receipt />;
            default:
                return "C";
        }
    }

    private formatEventTitle(event: Event | ManualEntryEvent)
    {
        switch(event.type)
        {
            case "ACTIVITY_OTHER":
                const activity = this.props.activityTypes.find(a => a.id === event.activityType);
                return activity ? activity.label : event.activityType;
            case "ACTIVITY_ORDER":
                return `Order ${event.orderNo}`;
            case "ACTIVITY_PROJECT":
                const elementType = this.props.elementTypes.find(e => e.id === event.elementType);
                return elementType ? elementType.label : event.elementType;
            case "ABSENCE":
                const absenceType = this.props.absenceTypes.find(e => e.id === event.absence);
                return absenceType ? absenceType.label : i18n`Absence`;
            default:
                return "[TODO:TITLE]";
        }
    }

    render()
    {
        const { 
            currentEvent: current, events, restTitle, currentTitle,
            getSecondaryText = formatEventTime,
            secondaryAction: getSecondaryAction
         } = this.props;
        return (
            <List dense>
                {
                    currentTitle ? <ListSubheader>{currentTitle}</ListSubheader> : null
                }
                {
                    (current !== undefined) ? (
                        <ListItem>
                            <Avatar style={{ backgroundColor: green[400] }}>
                                {this.getEventIcon(current)}
                            </Avatar>
                            <ListItemSecondaryAction>
                                <IconButton aria-label="Menu">
                                    <MoreHoriz />
                                </IconButton>
                            </ListItemSecondaryAction>
                            <ListItemText 
                                primary={this.formatEventTitle(current)} 
                                secondary={getSecondaryText(current)} />
                        </ListItem>
                    ) : null
                }
                {
                    (events.filter(e => e !== current).length > 0) ? (
                        <>
                            {
                                restTitle ? <ListSubheader>{restTitle}</ListSubheader> : null
                            }
                            {
                                events.map(event => (
                                    <ListItem key={event.id}>
                                        <Avatar>{this.getEventIcon(event)}</Avatar>
                                        { getSecondaryAction ? getSecondaryAction(event) : null }
                                        <ListItemText 
                                            primary={this.formatEventTitle(event)} 
                                            secondary={getSecondaryText(event)} />
                                    </ListItem>
                                ))
                            }
                        </>
                    ) : null
                }
            </List>
        )
    }
}

export default connect(mapStateToProps)(EventList);