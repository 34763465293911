import * as React from "react";
import i18n from "es2015-i18n-tag";
import { connect } from "react-redux";
import { Event, getCurrentTransaction, getElementTypes, TimeRecordingState } from "../../state";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { transactionEventAdd } from "../../state/action";
import { createProjectActivityEvent } from "../../events";
import { RouteComponentProps } from "react-router";

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export interface Props extends RouteComponentProps<any>, StateProps
{
    username: string;    
}

interface State
{
    elementType: string;
}

class AddOther extends React.Component<Props, State>
{
    state: State = { elementType: "" };

    private onElementTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => 
    {
        this.setState({ elementType: e.target.value });
    };

    private onAdd = () => 
    {
        const { transaction, transactionEventAdd, username } = this.props;
        const { elementType } = this.state;
        if(transaction !== undefined)
        {
            const event = createProjectActivityEvent(username, transaction.id, elementType);
            transactionEventAdd(event);
        }
        this.props.history.goBack();
    };

    private onCancel = () =>
    {
        this.props.history.goBack();
    }

    render()
    {
        const { elementTypes } = this.props;
        const { elementType } = this.state;

        return (
            <div style={{padding: 16}}>
                <Typography variant="headline" gutterBottom> {i18n`Add Project Activity`}</Typography>
                <FormControl fullWidth style={{marginTop: 8}}>
                    <InputLabel>{i18n`Choose Activity`}</InputLabel>
                    <Select value={elementType} onChange={this.onElementTypeChange}>
                        {
                            elementTypes.map(({id, label}) => (
                                <MenuItem key={id} value={id}>{label}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <div style={{marginTop: 16}}>
                    <Button variant="raised" color="primary" onClick={this.onAdd} disabled={elementType === ""}>{i18n`Add`}</Button>
                    <Button variant="raised" color="default" onClick={this.onCancel} style={{ marginLeft: 8 }}>{i18n`Cancel`}</Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: TimeRecordingState, ownProps: { username: string }) => 
{
    const username = ownProps.username;
    return ({
        transaction: getCurrentTransaction(username, state),
        elementTypes: getElementTypes(state)
    });
}

const mapDispatchToProps = (dispatch: any) => ({
    transactionEventAdd: (event: Event) => dispatch(transactionEventAdd(event))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOther);