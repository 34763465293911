import * as React from "react";
import { Provider } from "react-redux";
import { withRouter, RouteComponentProps, Switch, Route } from "react-router";
import Base36Tabs from "../views/base36/Tabs";

import { store } from "..";
import { useLocation } from "../../../hooks/useLocation";

type Props = RouteComponentProps<any>;

function AppBarTabs(props: Props)
{
    const { match: { path }, history } = props;
    const location = useLocation(history);

    return (
        <Switch location={location}>
            <Route path={`${path}/features`} component={Base36Tabs} />
            {/* <Route path={`${path}/delivery`} component={} */}
        </Switch>
    )
}

// hookup the redux provider here as the tabs are rendered further up the UI tree
// and immediately connect the AppBarTabs to our module store
export default () => (
    <Provider store={store}>
    {
        React.createElement(withRouter(AppBarTabs))
    }
    </Provider>
);