import { default as React, useEffect, useState } from "react";
import i18n from "es2015-i18n-tag";
import Snackbar from "@material-ui/core/Snackbar";
import Button, { ButtonProps } from "@material-ui/core/Button";
import yellow from "@material-ui/core/colors/yellow";
import withStyles from "@material-ui/core/styles/withStyles";
import UpdateNotificationService from "../service/UpdateNotificationService";

const styles = {
    yellowButton: {
        color: yellow[500]
    }
};

const YellowButton = withStyles(styles)(({ classes, ...other }: ButtonProps & { classes: any }) => 
    <Button className={classes.yellowButton} {...other} />);

type UpdateAvailableResult = null | { updateAvailable: boolean };

function useUpdateAvailable()
{
    const [hasUpdate, setHasUpdate] = useState<UpdateAvailableResult>(null);

    useEffect(() => {
        const handler = () => setHasUpdate({ updateAvailable: true });
        UpdateNotificationService.addEventListener("updateavailable", handler);
        return () => UpdateNotificationService.removeEventListener("updateavailable", handler);
    }, []);

    return hasUpdate;
}


function UpdateNotification()
{
    const updateAvailable = useUpdateAvailable();

    if(updateAvailable && updateAvailable.updateAvailable)
    {
        return (
            <Snackbar 
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={true}
                message={i18n`A new version of Guldmann Mobility is available.`}
                action={[
                    <YellowButton key="refresh" size="small" onClick={() => window.location.reload()}>Update</YellowButton>
                ]}
                />
        );
    }

    return null;
}

export default UpdateNotification;