import * as React from "react";
import { useState, useCallback, useEffect, useContext } from "react";
import i18n from "es2015-i18n-tag";

import IconPrint from "@material-ui/icons/Print";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment  from "@material-ui/core/InputAdornment";
import { TypographyProps } from "@material-ui/core/Typography";

import { Delivery } from "../../state";
import Api from "../../../../api/api";
import { DeliveryContext } from "../Main";

interface GLSReceive
{
    consignmentId: string;
    parcels: ReceiveParcel[];
}

interface ReceiveParcel
{
    ndiNumber: string;
    parcelNumber: string;
    uniqueNumber: string;
}

export interface Props
{
    className?: string;
    style?: React.CSSProperties;
    delivery: Delivery;
    deliveryNumber: string;
}

const t_headline: TypographyProps["variant"] = "caption";
const t_data: TypographyProps["variant"] = "body2";

const update = <T extends any>(arr: T[], index: number, value: T): T[] => 
{
    const result = arr.slice();
    result[index] = value;
    return result;
}

export default function DeliveryCard(props: Props)
{
    const { className, style, delivery, deliveryNumber } = props;
    const [weights, setWeights] = useState<string[]>([]);
    const [tracking, setTracking] = useState<string[]>([]);
    const context = useContext(DeliveryContext);

    const addParcel = useCallback(() => setWeights(ws => [...ws, ""]), []);
    const onWeightChange = useCallback(
        (value: string, index: number) => setWeights(es => update(es, index, value)),
        []
    );

    // reset weights and tracking data when delivery number changes
    useEffect(() => {
        setWeights([]);
        setTracking([]);
    }, [delivery]);

    const onPrintLabelsClick = async () => {
        const res = await Api.request(`delivery/${deliveryNumber}/print`, "POST", weights.map(w => parseFloat(w)));
        if(res.ok)
        {
            const data: GLSReceive = await res.json();
            setTracking(data.parcels.map(p => p.parcelNumber));
        }
        else
        {
            const data = await res.json();
            context.toast({
                message: data.errorMessage || i18n`Something went wrong`,
                severity: "error"
            });
        }
    };

    return (
        <Card className={className} style={style}>
            <CardActionArea>
                <Grid container style={{padding: "16px 16px 0px 16px"}}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Grid item xs={12}><Typography variant={t_headline}>{i18n`Name`}</Typography></Grid>
                            <Grid item xs={12}>
                                <Typography variant={t_data}>
                                    {delivery.name1}<br/>
                                    {delivery.name2}<br/>
                                    {delivery.name3}<br/>
                                    {delivery.street1}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item xs={12}><Typography variant={t_headline}>{i18n`Zipcode`}</Typography></Grid>
                            <Grid item xs={12}><Typography variant={t_data}>{delivery.zipCode}</Typography></Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item xs={12}><Typography variant={t_headline}>{i18n`City`}</Typography></Grid>
                            <Grid item xs={12}><Typography variant={t_data}>{delivery.city}</Typography></Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12}><Typography variant={t_headline}>{i18n`Country`}</Typography></Grid>
                            <Grid item xs={12}><Typography variant={t_data}>{delivery.countryNum}</Typography></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardActionArea>
            <CardContent>
            {
                (tracking.length === 0) ? (
                    <Grid container spacing={8}>
                    {
                        weights.map((weight, i) => 
                            <ParcelWeight key={i} index={i} value={weight} onChange={onWeightChange} />
                        )
                    }
                    </Grid>
                ) : (
                    <Grid container spacing={8}>
                        <Grid item><Typography variant="body1">{i18n`Labels have been printed for parcels:`}</Typography></Grid>
                    {
                        tracking.map(number => (
                            <Grid item xs={12}><Typography variant="body2">{number}</Typography></Grid>
                        ))
                    }
                    </Grid>
                )
            }
            </CardContent>
            {/* <Divider /> */}
            <CardActions style={{display: "flex"}}>
            {
                 (tracking.length === 0) ? (
                    <>
                    <Button size="small" color="primary" onClick={addParcel} component="span">{i18n`Add Parcel`}</Button>
                    {
                        (weights.length > 0 && weights[0].length > 0) ? (
                            <IconButton color="primary" onClick={onPrintLabelsClick} component="span" style={{marginLeft: "auto", fontSize: "0.8125rem"}}>
                                <IconPrint />
                            </IconButton>
                        ) : null
                    }
                    </>
                ) : null
            }
            </CardActions>
        </Card>
    );
}

interface ParcelWeightProps
{
    value: string;
    index: number;
    onChange: (value: string, index: number) => void;
}

function ParcelWeight(props: ParcelWeightProps)
{
    const { onChange, value, index } = props;

    const onTextFieldChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value, index),
        [index, onChange]
    );

    return (
        <Grid item xs={12}>
            <TextField 
                variant="filled" 
                label={i18n`Parcel Weight (${index+1})`} 
                value={value}
                onChange={onTextFieldChange}
                InputProps={{
                    endAdornment: <InputAdornment position="end">kg</InputAdornment>
                }}
                fullWidth />
        </Grid>
    )
}