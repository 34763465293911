import { History } from "history";
import { useState, useEffect } from "react";

export function useLocation(history: History<any>)
{
    const [location, setLocation] = useState(history.location);
    useEffect(
        () => history.listen(location => setLocation(location)),
        [history]
    );
    return location;
}