import { Action } from "redux";
import { busy } from "../ui/actions";
import auth from "../../service/auth";

export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export interface UserLoginSuccess extends Action
{
    type: "USER_LOGIN_SUCCESS";
    username: string;
    roles: string[];
}
export const userLoginSuccess = (username: string, roles: string[]): UserLoginSuccess => ({
    type: USER_LOGIN_SUCCESS,
    username, roles
});

export const USER_LOGOUT = "USER_LOGOUT"
export interface UserLogout extends Action
{
    type: "USER_LOGOUT";
}
export const userLoggedOut = (): UserLogout => ({
    type: USER_LOGOUT
});

export const USER_LOGIN_PENDING = "USER_LOGIN_PENDING";
export interface UserLoginPending extends Action
{
    type: "USER_LOGIN_PENDING";
}
export const userLoginPending = (): UserLoginPending => ({ type: USER_LOGIN_PENDING });

export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
export interface UserLoginFailed extends Action
{
    type: "USER_LOGIN_FAILED";
    reason: any;
}
export const userLoginFailed = (reason: any): UserLoginFailed => ({ type: USER_LOGIN_FAILED, reason });

export interface UserUpdateRoles
{
    type: "USER_UPDATE_ROLES";
    roles: string[];
}
export const userUpdateRoles = (roles: string[]): UserUpdateRoles => ({ type: "USER_UPDATE_ROLES", roles });

export interface UserClearRoles
{
    type: "USER_CLEAR_ROLES";
}

export const userClearRoles = (): UserClearRoles => ({ type: "USER_CLEAR_ROLES" });

export const login = (username: string, password: string) => async (dispatch: any) =>
{
    // TODO - perform authentication
    dispatch(userLoginPending());
    dispatch(busy(true));

    try 
    {
        await auth.login(username, password);
        dispatch(userLoginSuccess(username, auth.roles));
    }
    catch(reason)
    {
        dispatch(userLoginFailed(reason));
        dispatch(userClearRoles());
    }

    dispatch(busy(false));
};

export const logout = () => async (dispatch: any) =>
{
    await auth.logout();
    dispatch(userLoggedOut());
    dispatch(userClearRoles());
}