import React from "react";
import { RouteComponentProps, matchPath } from "react-router";
import i18n from "es2015-i18n-tag";
import { useRecoilValue } from "recoil";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { History } from "history";
import { useLocation } from "../../../../hooks/useLocation";
import { searchStatusAtom } from "../../atoms/base36";

type Props = RouteComponentProps<any>;

const tabRoutes = [
    "lookup",
    "items",
    "items/any"
];

const getUrl = (urls: string[], pathname: string) => 
    Math.max(0, urls.findIndex(url => isMatch(pathname, url)));

const isMatch = (url: string, matchUrl: string, exact = true) => 
    matchPath(url, { path: matchUrl, exact }) !== null;

function useLocationTabs(history: History, urls: string[])
{
    const location = useLocation(history);
    return getUrl(urls, location.pathname);
}

const joinUrl = (baseUrl: string, url: string) => 
    (baseUrl + "/" + url).replace(/\/{2,}/, "/");

const Base36Tabs = function (props: Props)
{
    const { itemsCount, additionalItemsCount } = useRecoilValue(searchStatusAtom);
    const { match: { path: baseUrl }, history } = props;
    
    const activeTab = useLocationTabs(history, tabRoutes.map(url => joinUrl(baseUrl, url)));
    const setActiveTab = (index: number) => history.replace(`${joinUrl(baseUrl, tabRoutes[index])}`);

    return (
        <Tabs value={activeTab} variant="fullWidth" onChange={(_, v) => setActiveTab(v)}>
            <Tab label={i18n`Features`} />
            <Tab label={i18n`Exact (${itemsCount})`} />
            <Tab label={i18n`Any (${additionalItemsCount})`} />
        </Tabs>
    )
}

export default Base36Tabs;