export interface Delivery
{
    city: string;
    contact: string;
    countryNum: string;
    name1: string;
    name2: string;
    name3: string;
    phone: string;
    street1: string;
    zipCode: string;
}

const reducer = (state = {}, action: any) => state;

export default reducer;