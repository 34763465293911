import { RootState } from ".";

import {
    isLoggedIn as isLoggedIn_,
    getUsername as getUsername_,
    getLoginPending as getLoginPending_,
    getLoginFailed as getLoginFailed_,
    getUserRoles as getUserRoles_
} from "./user"

import { 
    getDrawerOpenState as getDrawerOpenState_,
    isBusy as isBusy_
} from "./ui";



// ui
export const isBusy = (state: RootState) => isBusy_(state.ui);
export const getDrawerOpenState = (state: RootState) => getDrawerOpenState_(state.ui);

// user
export const getUsername = (state: RootState) => getUsername_(state.user);
export const isLoggedIn = (state: RootState) => isLoggedIn_(state.user);
export const getLoginPending = (state: RootState) => getLoginPending_(state.user);
export const getLoginFailed = (state: RootState) => getLoginFailed_(state.user);
export const getUserRoles = (state: RootState) => getUserRoles_(state.user);

// module
export const getModuleState = (state: RootState) => state.module;
