import * as React from "react";
import { LookupItem } from "../../types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

export function ItemList(props: { items: LookupItem[] })
{
    const { items } = props;
    return (
        <List>
        {
            items.map(item => <ItemListItem key={item.itemNumber} item={item} />)
        }
        </List>
    );
}

function ItemListItem(props: {item: LookupItem})
{
    const { item } = props;
    return (
        <ListItem>
        <ListItemText 
            primary={item.itemName}
            secondary={
                <>
                    <Typography component="span" style={{display: "inline"}} color="textPrimary">
                        {item.itemNumber}
                    </Typography>
                    {" - " + item.itemDescription}
                </>}
            />
        </ListItem>
    );
}