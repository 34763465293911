import { createModuleStore, createModule } from "../helper";
import reducer from "./state";

import App from "./views/Main";

const store = createModuleStore(reducer, "stock", 1);
const Stock = createModule(store, App as any); // TODO Fix App module connect types

export default Stock;

