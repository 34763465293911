import * as React from "react";
import { NotificationMessage } from "../modules/config";

export interface ModuleContextData
{
    isBusy: (busy: boolean) => void;
    toast: (message: NotificationMessage, timeout?: number) => void;
    username: string | undefined;
}

export const ModuleContext = React.createContext<ModuleContextData>({
    isBusy: () => {},
    toast: (message: NotificationMessage, timeout?: number) => {},
    username: undefined
});
