import * as React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { ChangeEvent } from "react";

export interface Props 
{
    title?: string;
    name?: string;
    required?: boolean;

    value?: string;
    onChange?: (value: string) => void;
}

interface State
{
    value: string;
}

class RadioButtonGroup extends React.Component<Props, State>
{

    private handleChange = (event: ChangeEvent<any>) => 
    {
        if(this.props.onChange)
        {
            this.props.onChange(event.currentTarget.value);
        }
    };

    render() {
        const c1: any = "fieldset";
        const c2: any = "legend";
        const { title, name, value, required = false } = this.props;
        return (
            <FormControl component={c1} required={required}>
                <FormLabel component={c2}>{title}</FormLabel>
                <RadioGroup
                    aria-label={title}
                    name={name}
                    value={value}
                    onChange={this.handleChange}>
                    {this.props.children}
                </RadioGroup>
            </FormControl>
        )
    }
}

export default RadioButtonGroup;