import * as React from "react"
import { connect } from "react-redux";
import i18n from "es2015-i18n-tag"
import styled from "styled-components";
import { Switch, RouteComponentProps, Route } from "react-router";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ListSubheader from "@material-ui/core/ListSubheader";
import AddIcon from "@material-ui/icons/Add";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Receipt from "@material-ui/icons/Receipt";
import Flag from "@material-ui/icons/Flag";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import green from "@material-ui/core/colors/green";

import AddOrder from "./AddOrder";
import AddProject from "./AddProject";
import AddOther from "./AddOther";
import { getCurrentTransaction, Event, getActivityTypes, getElementTypes, TimeRecordingState } from "../../state";

export interface Props extends RouteComponentProps<any>
{
    username: string;
}

interface State
{
    newDialogOpen: boolean;
}

const Block = styled.div`
    padding: 16px;
    min-height: 100vh;
`;

const LowerRightButton = styled.div`
    position: fixed;
    bottom: ${56 + 16}px;
    right: 16px;
`;

type ViewProps = { username: string } & RouteComponentProps<any>;

interface View 
{
    urlPart: "add-order" | "add-project" | "add-other";
    view: React.ComponentType<ViewProps>;
    icon: React.ComponentType;
    label: string;
}

const views: View[] = [
    { urlPart: "add-order", label: i18n`Order`, view: AddOrder, icon: ShoppingCart },
    { urlPart: "add-project", label: i18n`Project`, view: AddProject, icon: Receipt },
    { urlPart: "add-other", label: i18n`Other`, view: AddOther, icon: Flag },
]

class CurrentJob extends React.Component<Props & StateProps, State>
{
    state: State =
    {
        newDialogOpen: false
    }

    private onNewDialogClose = () =>
    {
        this.setState({ newDialogOpen: false });
    }

    private onNewDialogChoose = (type: string) => () =>
    {
        this.props.history.push(this.props.match.url + "/" + type);
        this.setState({ newDialogOpen: false });
    }

    private onAddClick = () =>
    {
        this.setState({ newDialogOpen: true });
    }

    private renderNewDialog()
    {
        return (
            <Dialog onClose={this.onNewDialogClose} open={this.state.newDialogOpen} fullWidth>
                {/* <DialogTitle>{i18n`Select Type`}</DialogTitle> */}
                <div>
                    <List>
                    {
                        views.map(({urlPart, icon: Icon, label}) => (
                            <ListItem key={urlPart} button onClick={this.onNewDialogChoose(urlPart)}>
                                <ListItemIcon>
                                    <Icon />
                                </ListItemIcon>
                                <ListItemText>{label}</ListItemText>
                            </ListItem>
                        ))
                    }
                    </List>
                </div>
            </Dialog>
        );
    }

    private getEventIcon(event: Event)
    {
        switch(event.type)
        {
            case "ACTIVITY_ORDER":
                return  <ShoppingCart />;
            case "ACTIVITY_OTHER":
                return <Flag />;
            case "ACTIVITY_PROJECT":
                return <Receipt />;
            default:
                return "C";
        }
    }

    private formatEventTitle(event: Event)
    {
        switch(event.type)
        {
            case "ACTIVITY_OTHER":
                const activity = this.props.activityTypes.find(a => a.id === event.activityType);
                return activity ? activity.label : event.activityType;
            case "ACTIVITY_ORDER":
                return `Order ${event.orderNo}`;
            case "ACTIVITY_PROJECT":
                const elementType = this.props.elementTypes.find(e => e.id === event.elementType);
                return elementType ? elementType.label : event.elementType;
            default:
                return "[TODO:TITLE]";
        }
    }

    private formatEventTime(event: Event)
    {
        return i18n`${new Date(new Date(event.date))}:t(t)`;
    }

    private renderEventList()
    {
        const { transaction } = this.props;
        if(transaction !== undefined)
        {
            // pick out the events in reverse order
            // and filter out the CHECK_IN/CHECK_OUT events
            const [current, ...rest] = [...transaction.events]
                .filter(e => (e.type !== "CHECK_IN") && (e.type !== "CHECK_OUT"))
                .reverse();

            return (
                <List dense>
                    <ListSubheader>{i18n`Current activity`}</ListSubheader>
                    {
                        (current !== undefined) ? (
                            <ListItem>
                                <Avatar style={{backgroundColor: green[400]}}>
                                    {this.getEventIcon(current)}
                                </Avatar>
                                <ListItemSecondaryAction>
                                    <IconButton aria-label="Menu">
                                        <MoreHoriz />
                                    </IconButton>
                                </ListItemSecondaryAction>
                                <ListItemText primary={this.formatEventTitle(current)} secondary={this.formatEventTime(current)} />
                            </ListItem>
                        ) : (
                            null
                        )
                    }
                    {
                        (rest.length > 0) ? (
                            <>
                                <ListSubheader>{i18n`Former activities`}</ListSubheader>
                                {
                                    rest.map(event => (
                                        <ListItem key={event.id}>
                                            <Avatar>{this.getEventIcon(event)}</Avatar>
                                            <ListItemText primary={this.formatEventTitle(event)} secondary={this.formatEventTime(event)} />
                                        </ListItem>
                                    ))
                                }
                            </>
                        ) : (
                            null
                        )
                    }
                </List>
            );
        }

    }

    private renderViews()
    {
        const { match: { url } } = this.props;
        return (
            <Switch>
            {
                views.map(({urlPart, view: View}) => (
                    <Route key={urlPart} path={url + "/" + urlPart} render={(props) => 
                        <View {...props} username={this.props.username} />
                    } />
                ))
            }
                <Route>
                    <>
                        {this.renderEventList()}
                        <Block>
                            <LowerRightButton>
                                <Button variant="fab" color="secondary" onClick={this.onAddClick}>
                                    <AddIcon />
                                </Button>
                            </LowerRightButton>
                        </Block>
                    </>
                </Route>
            </Switch>
        )
    }

    render()
    {
        return (
            <>
                {this.renderNewDialog()}
                {this.renderViews()}
            </>
        );
    }
}

type StateProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: TimeRecordingState, ownProps: Props) => {
    const { username } = ownProps;
    return ({
        transaction: getCurrentTransaction(username, state),
        activityTypes: getActivityTypes(state),
        elementTypes: getElementTypes(state)
    });
}

export default connect(mapStateToProps)(CurrentJob);