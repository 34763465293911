import * as React from "react"
import i18n from "es2015-i18n-tag"
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import RadioButtonGroup from "../../../components/RadioButtonGroup";
import RadioButton from "../../../components/RadioButton";
import { getCheckinTypes, getAbsenceTypes, getCheckin, CheckinEvent, getCurrentTransaction, TimeRecordingState } from "../state";
import { connect } from "react-redux";
import { transactionOpen, transactionClose } from "../state/action";
import { checkIn, EVENT_TYPE_CHECK_IN } from "../events";
import { Event } from "../state";
import { RouteComponentProps } from "react-router";

export interface Props extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RouteComponentProps<any>
{
    username: string;
}

const Block = styled.div`
    padding: 16px;
    margin-top: 16px;
`;

const ButtonBar = styled.div`
    margin-top: 32px;
`;

interface State
{
    checkin?: string;
    absence?: string;
}

class CheckInOut extends React.Component<Props, State>
{
    state: State = 
    {
        absence: "__NONE__"
    }
    
    private onCheckinChange = (checkin: string) =>
    {
        this.setState({ checkin });
    }

    private onAbsenceChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    {
        this.setState({ absence: e.target.value });
    }

    private onCheckinClick = () =>
    {
        if(this.state.checkin !== undefined)
        {
            const { absence } = this.state;
            const { username } = this.props;
            const event = checkIn(username, absence === "__NONE__" ? undefined : absence);
            this.props.transactionOpen(username, event);
        }
    }

    private onCheckoutClick = () =>
    {
        const { transactionClose, transaction, username } = this.props;
        if(transaction)
        {
            transactionClose(username, transaction.id);
        }
    }

    private getCheckin(): CheckinEvent | undefined
    {
        const { transaction } = this.props;
        if(transaction)
        {
            return transaction.events.find(t => t.type === EVENT_TYPE_CHECK_IN) as CheckinEvent
        }
    }

    private renderCheckin()
    {
        const { transactionType, absenceType } = this.props;
        const { checkin } = this.state;
        const c2: any = "fieldset";
        return (
            <Block>
                <RadioButtonGroup title={i18n`Check In`} onChange={this.onCheckinChange} value={checkin} required>
                {
                    transactionType.filter(t => t.type === "in").map(t => (
                        <RadioButton value={t.id} key={t.id} label={t.label} />
                    ))
                }
                </RadioButtonGroup>
                <FormControl component={"legend" as any} fullWidth style={{marginTop: 16}}>
                    <FormLabel component={c2}>{i18n`Absence`}</FormLabel>
                    <FormControl fullWidth>
                        <Select value={this.state.absence} onChange={this.onAbsenceChange}>
                            <MenuItem value="__NONE__">{i18n`None`}</MenuItem>
                            {
                                absenceType.map(type => <MenuItem key={type.id} value={type.id}>{type.label}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </FormControl>

                <ButtonBar>
                    <Button variant="contained" color="primary" disabled={checkin === undefined} onClick={this.onCheckinClick}>{i18n`Check In`}</Button>
                </ButtonBar>
            </Block>
        );
    }

    private renderCheckout(checkin: CheckinEvent, events: Event[])
    {
        const { absenceType } = this.props;
        const absence = absenceType.find(a => a.id === checkin.absence);
        const absenceLabel = absence ? absence.label : i18n`None`;

        const lastActivityDate = (events.length > 0) 
            ? new Date(events[events.length - 1].date)
            : new Date(checkin.date);

        return (
            <Block>
                <Typography variant="title" paragraph>
                    {i18n`You are checked in`}
                </Typography>

                <Typography variant="caption">{i18n`Check-in Time`}</Typography>
                <Typography variant="body1" paragraph>{i18n`${new Date(checkin.date)}`}</Typography>

                <Typography variant="caption">{i18n`Absence`}</Typography>
                <Typography variant="body1" paragraph>{absenceLabel}</Typography>

                <Typography variant="caption">{i18n`Last Activity`}</Typography>
                <Typography variant="body1" paragraph>{i18n`${lastActivityDate}`}</Typography>


                <ButtonBar>
                    <Button variant="raised" color="secondary" onClick={this.onCheckoutClick}>{i18n`Check Out`}</Button>
                </ButtonBar>
            </Block>
        );
    }

    render()
    {
        const { transaction} = this.props;
        const checkin = this.getCheckin();
        const view = (checkin === undefined) ? (
            this.renderCheckin()
        ) : (
            this.renderCheckout(checkin, transaction ? transaction.events : [])
        );


        return (
            <>
                {view}
            </>
        );
    }
}

const mapStateToProps = (state: TimeRecordingState, ownProps: {username: string}) => {
    return ({
        transactionType: getCheckinTypes(state),
        absenceType: getAbsenceTypes(state),
        checkedIn: getCheckin(state),
        transaction: getCurrentTransaction(ownProps.username, state)
    });
};

const mapDispatchToProps = (dispatch: any) => ({
    // checkinSetType: (type: string) => dispatch(checkinSetType(type)),
    transactionOpen: (username: string, event: CheckinEvent) => dispatch(transactionOpen(username, event)),
    transactionClose: (username: string, id: string) => dispatch(transactionClose(username, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckInOut);