import * as React from "react"
import { RouteComponentProps, Switch, Route } from "react-router";
import styled from "styled-components";
import Inventory from "./Inventory";
import { getInventoryDrafts } from "../../state";
import { connect } from "react-redux";
import { stockDraftsLoad, stockInit, stockDismissError, stockDebugClearState } from "../../state/action";
import { StockState } from "../../state/reducer";
import SelectDraft from "./SelectDraft";

interface RouteProps
{
    warehouse: string;
}

export interface Props extends RouteComponentProps<RouteProps>
{
}

const ViewBase = styled.div`
    padding: 16px;
`;

const mapStateToProps = (state: StockState) => ({
    drafts: getInventoryDrafts(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    draftsLoad: (warehouse: string) => dispatch(stockDraftsLoad(warehouse)),
    dismissError: (id: string) => dispatch(stockDismissError(id)),
    clearState: () => dispatch(stockDebugClearState()),
    init: () => dispatch(stockInit())
});

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface State
{
    draft?: string;
}


class StockManagement extends React.Component<Props & StateProps, State>
{
    state: State = {}

    private navigateToView = (relativePath: string) => 
        this.props.history.push(`${this.props.match.url}/${relativePath}`);

    componentWillMount()
    {
        this.props.init();
        if(this.props.match.params.warehouse)
        {
            this.props.draftsLoad(this.props.match.params.warehouse);
        }
    }

    componentWillReceiveProps(nextProps: Props & StateProps)
    {
        const { warehouse: nextWarehouse = undefined  } = nextProps.match.params;
        const { warehouse: currentWarehouse = undefined } = this.props.match.params;
        if(nextWarehouse !== currentWarehouse && nextWarehouse)
        {
            this.props.draftsLoad(nextWarehouse);
        }
    }

    render()
    {
        const { match, drafts } = this.props;

        return (
            <ViewBase>
                <Switch>
                    <Route path={match.path + "/:draft"} exact component={Inventory} />
                    <Route>
                        <SelectDraft 
                            warehouse={match.params.warehouse}  
                            drafts={drafts}
                            navigate={this.navigateToView} />
                    </Route>
                </Switch>
            </ViewBase>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockManagement);