import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import red from "@material-ui/core/colors/red";

interface AlertDialogProps
{
    open?: boolean;
    onConfirm?: React.ReactEventHandler;
    onHandleClose?: React.ReactEventHandler;
}

function AlertDialog(props: AlertDialogProps)
{
    const { open, onConfirm, onHandleClose } = props;
    return (
        <Dialog
            open={!!open}
            onClose={onHandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{"Delete event?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the event? It is not possible to undo.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onHandleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} style={{color: red[500]}} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
}

export type Props = AlertDialogProps;
export default AlertDialog;