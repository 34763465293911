export function formatGroupedBinary(n: number, grouping: number | undefined = 5, padding = true)
{
    let s = n.toString(2);
    if(grouping !== undefined)
    {
        if(padding && (((s.length % grouping) !== 0) || s.length === 0))
        {
            const maxSize = s.length + (grouping - (s.length % grouping));
            s = s.padStart(maxSize, "0");
        }

        if(s.length > grouping)
        {
            let firstGroupLen = s.length % grouping;
            let groups = [s.substr(0, firstGroupLen)];
            for(let i = firstGroupLen; i < s.length; i += grouping)
            {
                groups.push(s.substr(i, grouping));
            }
            s = groups.join(" ");
        }
    }

    return s;
}
