import * as React from "react";
import { ModuleProps } from "../../config";
import { Route, Switch, RouteComponentProps } from "react-router";
import { Button } from "@material-ui/core";
import i18n from "es2015-i18n-tag";
import Manuel from "./Manuel";
import Auto from "./Auto";
import UsernameContext from "../../../context/UsernameContext";

export interface Props extends ModuleProps
{

}

type NavigationButtonProps = { path: string, children: React.ReactChildren, style?: React.CSSProperties } & Pick<RouteComponentProps<any>, "history">;

const NavigationButton = ({ path, children, history, style }: NavigationButtonProps) => (
    <Button fullWidth variant="outlined" onClick={() => history.push(path)} style={style}>
        {children}
    </Button>
);

function Index(props: Props)
{
    const urlAuto = `${props.match.url}/auto`;
    const urlManual = `${props.match.url}/manual`;
    return (
        <UsernameContext.Provider value={props.username}>
            <Switch>
                <Route path={urlAuto} render={renderProps => (
                    <Auto {...props} {...renderProps} />
                )}/>
                <Route path={urlManual} render={renderProps => (
                    <Manuel {...props} {...renderProps} />
                )} />
                <Route>
                    <div style={{padding: 16}}>
                        <NavigationButton history={props.history} path={urlAuto}>{i18n`Standard`}</NavigationButton>
                        <NavigationButton history={props.history} path={urlManual} style={{marginTop: 8}}>{i18n`Manual Entry`}</NavigationButton>
                    </div>
                </Route>
            </Switch>
        </UsernameContext.Provider>
    );
}

export default Index;
