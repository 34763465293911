import { CheckinEvent, OtherActivityEvent, OrderActivityEvent, ProjectActivityEvent } from "../state";
import { formatDate } from "../../../util/date";
import { generateUuid } from "../../../util/uuid";

export const EVENT_TYPE_CHECK_IN = "CHECK_IN";

export const checkIn = (username: string, absence?: string, id = generateUuid(), date = formatDate()): CheckinEvent => ({
    type: EVENT_TYPE_CHECK_IN,
    id,
    date, 
    username,
    transactionId: "<CHECK-IN-GUID>",
    absence
});

export const createOtherActivityEvent = (username: string, transactionId: string, activityType: string, id = generateUuid(), date = new Date().toISOString()): OtherActivityEvent => ({
    type: "ACTIVITY_OTHER",
    id,
    date,
    username,
    transactionId,
    activityType
});

export const createOrderActivityEvent = (username: string, transactionId: string, orderNo: string, id = generateUuid(), date = new Date().toISOString()): OrderActivityEvent => ({
    type: "ACTIVITY_ORDER",
    id,
    date,
    username,
    transactionId,
    orderNo
});

export const createProjectActivityEvent = (username: string, transactionId: string, elementType: string, id = generateUuid(), date = new Date().toISOString()): ProjectActivityEvent => ({
    type: "ACTIVITY_PROJECT",
    id,
    date,
    username,
    transactionId,
    elementType
});
