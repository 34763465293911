import { useReducer, Reducer, useEffect } from "react";

interface State<T>
{
    response?: T;
    error?: any;
    isLoading: boolean;
}

interface Resolved<T> 
{
    type: "SUCCESS";
    value: T;
}

interface Rejected
{
    type: "REJECTED";
    error: any;
}

interface Loading
{
    type: "LOADING";
}

interface Reset
{
    type: "RESET";
}

type Action<T> = Resolved<T> | Rejected | Loading | Reset;

const fetchReducer = <T>(state: State<T>, action: Action<T>) =>
{
    if(action.type === "LOADING")
    {
        return {
            isLoading: true
        }
    }

    if(action.type === "SUCCESS")
    {
        return {
            response: action.value,
            isLoading: false
        }
    }

    if(action.type === "REJECTED")
    {
        return {
            error: action.error,
            isLoading: false
        }
    }

    if(action.type === "RESET")
    {
        return { isLoading: false };
    }

    return state;
}

export const createUseFetch = (fetch: typeof window.fetch) => <T>(input: RequestInfo | null, init?: RequestInit): [T | undefined, boolean, any] =>
{
    const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>>(fetchReducer, { isLoading: false });

    useEffect(() =>
    {
        if (input === null)
        {
            dispatch({ type: "RESET" });
            return;
        }

        let cancelled = false;
        dispatch({ type: "LOADING" });
        fetch(input, init)
            .then(res =>
            {
                if (!cancelled)
                {
                    if(res.ok)
                    {
                        return res.json();
                    }
                    else
                    {
                        return res.json().then(v => Promise.reject(v));
                    }

                }
            })
            .then((value: T) =>
            {
                if (!cancelled)
                {
                    dispatch({ type: "SUCCESS", value })
                }
            })
            .catch(error => dispatch({ type: "REJECTED", error }));

        return () => { cancelled = true; }
    }, [input, init]);

    return [state.response, state.isLoading, state.error];
}

export const useFetch = createUseFetch(window.fetch);