import * as React from "react" ;
import { useState, useEffect } from "react";
import i18n from "es2015-i18n-tag";
import { useCallback } from "react";
import { Dialog, Toolbar, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CheckinEvent } from "../../state";
import CheckinForm from "../CheckinForm";
import UsernameContext from "../../../../context/UsernameContext";

export interface Props 
{
    onClose?: () => void;
    onCheckin?: (info: CheckinEvent) => void;
}

function CheckinDialog(props: Props)
{
    const { onClose, onCheckin } = props;
    const [open, setOpen]= useState(true);
    const username = React.useContext(UsernameContext);
    const onCheckinCallback = useCallback((username: string, event: CheckinEvent) => {
        if(onCheckin !== undefined)
        {
            onCheckin(event);
        }
    }, [onCheckin]);

    useEffect(() => () => setOpen(false), []);

    console.log("checkinDialog: render");

    return (
        <Dialog open={open} fullScreen>
            <Toolbar>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6">
                    {i18n`Check In`}
                </Typography>
            </Toolbar>
            <CheckinForm username={username} onCheckin={onCheckinCallback} />
        </Dialog>
    )
}

export default CheckinDialog;