import * as React from "react";
import { connect } from "react-redux";
import i18n from "es2015-i18n-tag";
import { withRouter, RouteComponentProps } from "react-router";

import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";


import { RootState, isLoggedIn, getUserRoles } from "../state";
import { getDrawerOpenState } from "../state";
import { drawerOpen } from "../state/ui/actions";

import modules, { Module } from "../modules/config";
import { logout } from "../state/user/action";
import { isAllowed } from "../util/role";
import auth from "../service/auth";
import { Typography } from "@material-ui/core";

export interface Props extends RouteComponentProps<any>
{
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: RootState) => ({
    isDrawerOpen: getDrawerOpenState(state),
    loggedIn: isLoggedIn(state),
    userRoles: getUserRoles(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    drawerOpen: (open: boolean) => dispatch(drawerOpen(open)),
    logout: () => dispatch(logout() as any)
});

class AppDrawer extends React.Component<Props & ConnectedProps>
{
    closeDrawer = () => this.props.drawerOpen(false);
    
    onModuleClick = (uri: string) => () =>
    {
        this.props.history.push(uri);
        this.closeDrawer();
    }

    onLogoutClick = () => this.props.logout();

    onCheckForUpdate = () => 
    {
        if("serviceWorker" in navigator)
        {
            navigator.serviceWorker.getRegistration()
                .then(reg => reg && reg.update());

        }
    };

    onPrinterSetup = () => {

    }

    refreshToken = () => 
    {
        auth.refresh();
    }

    render()
    {

        const { loggedIn, userRoles } = this.props;
        const predicate = loggedIn 
            ? (module: Module) => isAllowed(userRoles, module.roles)
            : () => true;
        
        return (
            <Drawer open={this.props.isDrawerOpen} onClose={this.closeDrawer}>
                <div style={{padding: 16}}>
                    <Typography variant="title">Guldmann Mobility</Typography>
                    <Typography variant="subtitle2">Version: {process.env["REACT_APP_VERSION"]}</Typography>
                </div>
                <List>
                    {
                        modules.filter(predicate).map(({title, url, icon: Icon }) => (
                            <ListItem key={url} button onClick={this.onModuleClick(url)} disabled={!loggedIn}>
                                <ListItemIcon>
                                    <Icon />
                                </ListItemIcon>
                                <ListItemText>
                                    {title}
                                </ListItemText>
                            </ListItem>
                        ))
                    }
                    <Divider />
                    {/* <ListItem button>
                        <ListItemText>
                            {i18n`About`}
                        </ListItemText>
                    </ListItem> */}
                    <ListItem button onClick={this.onCheckForUpdate}>
                        <ListItemText>
                            {i18n`Check for updates`}
                        </ListItemText>
                    </ListItem>
                    <ListItem button onClick={this.refreshToken}>
                        <ListItemText>
                            {i18n`Refresh Token`}
                        </ListItemText>
                    </ListItem>
                    <ListItem button onClick={this.onPrinterSetup}>
                        <ListItemText>
                            {i18n`Printer setup`}
                        </ListItemText>
                    </ListItem>
                    { loggedIn ? (
                        <ListItem button onClick={this.onLogoutClick}>
                            <ListItemText>
                                {i18n`Logout`}
                            </ListItemText>
                        </ListItem>
                    ) : (
                        null
                    )}
                </List>
            </Drawer>
        );

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppDrawer));