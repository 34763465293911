//
// Action: STOCK_DEBUG_CLEAR_STATE
//
export const STOCK_DEBUG_CLEAR_STATE = "STOCK_DEBUG_CLEAR_STATE";
export interface StockDebugClearStateAction
{
    type: "STOCK_DEBUG_CLEAR_STATE";
}
export const stockDebugClearState = (): StockDebugClearStateAction => ({
    type: STOCK_DEBUG_CLEAR_STATE
});