export const assertNever = (value: never): void => {};

type FnAction = (...args: any[]) => void;
export const debounce = <T extends FnAction>(timeout: number, f: T): T => 
{
    let handle: number | undefined = undefined;

    function wrap()
    {
        const args = Array.prototype.slice.call(arguments);
        f.apply(f, args);
    }

    function fn()
    {
        window.clearTimeout(handle);
        handle = window.setTimeout(wrap, timeout);
    }

    return (fn as T);
}