import * as React from "react";
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from "react-router";

import { RootState, isBusy, getUsername, getUserRoles } from "../state";
import { getDrawerOpenState, isLoggedIn } from "../state";
import { connect } from "react-redux";
import { drawerOpen, busy } from "../state/ui/actions";
import LinearProgress from "@material-ui/core/LinearProgress";

import Overview from "../views/Overview";
import TitleBar from "../components/TitleBar";
import Login from "./Login";

import modules, { Module, NotificationMessage } from "../modules/config";
import UpdateNotification from "../components/UpdateNotification";
import { NotificationManager } from "../components/NotificationManager";
import { ModuleContextData, ModuleContext } from "../context/ModuleContext";
import { isAllowed } from "../util/role";

export interface Props extends StateProps, DispatchProps, RouteComponentProps<any>
{
}

interface StateProps
{
    drawerIsOpen: boolean;
    loggedIn: boolean;
    busy: boolean;
    username: string | undefined;
    userRoles: string[];
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: RootState): StateProps => ({
    drawerIsOpen: getDrawerOpenState(state),
    loggedIn: isLoggedIn(state),
    username: getUsername(state),
    busy: isBusy(state),
    userRoles: getUserRoles(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    drawerOpen: (open: boolean) => dispatch(drawerOpen(open)),
    setBusy: (isBusy: boolean) => dispatch(busy(isBusy))
});

interface State
{
    messages: NotificationMessage[];
}

class Main extends React.Component<Props, State>
{
    state: State = { messages: [] };

    openDrawer = () => this.props.drawerOpen(true);
    closeDrawer = () => this.props.drawerOpen(false);
    toggleDrawer = () => this.props.drawerIsOpen ? this.closeDrawer() : this.openDrawer();

    private contextData: ModuleContextData = 
    {
        isBusy: (busy: boolean) => this.props.setBusy(busy),
        toast: (message: NotificationMessage, timeout?: number) => 
            this.setState({ messages: [...this.state.messages, message] }),
        username: this.props.username
    };

    moduleRoutes(render?: (module: Module) => React.ReactNode)
    {
        const viewProps = 
        {
            isBusy: (busy: boolean) => this.props.setBusy(busy),
            toast: (message: NotificationMessage, timeout?: number) => 
                this.setState({ messages: [...this.state.messages, message] }),
            username: this.props.username,
            hasRole: (role: string) => this.props.loggedIn && isAllowed(this.props.userRoles, [role])
        };

        if(render === undefined)
        {
            return modules.map(({url, component: Component}) => (
                <Route path={url} key={url} render={(props) => 
                    <Component {...props} {...viewProps} />
                 }/>
            ));
        }
        else 
        {
            return modules.map(module => (
                <Route path={module.url} key={module.url}>
                    {render(module)}
                </Route>
            ));
        }

    }

    private onDismissNotification = (message: NotificationMessage) =>
    {
        this.setState({ messages: this.state.messages.filter(m => m !== message) });
    }

    render()
    {
        const { loggedIn, location, busy } = this.props;
        if(!loggedIn && location.pathname !== "/login")
        {
            return <Redirect to="/login" />
        }

        return (
            <>
                <ModuleContext.Provider value={this.contextData}>
                    <Switch>
                        {this.moduleRoutes(({title}) => <TitleBar title={title} />)}
                        <Route>
                            <TitleBar />                    
                        </Route>
                    </Switch>
                    { busy ? <LinearProgress variant="indeterminate" style={{marginBottom: -4}}  /> : null }
                    <Switch>
                        {this.moduleRoutes()}
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/">
                            <Overview />
                        </Route>
                    </Switch>
                    <UpdateNotification />
                    <NotificationManager messages={this.state.messages} onDismiss={this.onDismissNotification} />
                </ModuleContext.Provider>
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));