import { 
    Busy,
    DrawerOpenAction, 
    BUSY,
    DRAWER_OPEN 
} from "./actions";
import busyReducer from "./busy";

export interface UiState
{
    drawer: { open: boolean },
    busy: boolean;
}

type UIAction = DrawerOpenAction | Busy;

const initialState: UiState = 
{
    drawer: 
    {
        open: false
    },
    busy: false
};

const uiReducer = (state = initialState, action: UIAction): UiState =>
{
    state.busy = busyReducer(state.busy, action);

    if(action.type === DRAWER_OPEN)
    {
        const { open } = action;
        return {
            ...state,
            drawer: {
                ...state.drawer,
                open
            }
        }
    }

    if(action.type === BUSY)
    {
        const { busy } = action
        return {
            ...state,
            busy
        };
    }

    return state;
};

export const getDrawerOpenState = (state: UiState) => state.drawer.open;

export const isBusy = (state: UiState) => state.busy;

export default uiReducer;