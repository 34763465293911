import auth from "../service/auth";

// it is possible to set the localStorage "guldmann_mobility:base_url" key to an url to use
// or it will default to https://mobility.guldmann.com
export const API_BASE_URL = (localStorage.getItem("guldmann_mobility:base_url") || "/api").replace(/\/+$/, "");

const fetch = auth.getAuthenticatedFetch(); //(typeof UNAUTHENTICATED_FETCH !== "undefined" && UNAUTHENTICATED_FETCH) ? window.fetch : auth.getAuthenticatedFetch();

class Api
{
    static request = (url: string, method = "GET", body?: any) =>
    {
        const init: RequestInit = { method };
        if(body !== undefined)
        {
            init.body = JSON.stringify(body);
            init.headers = new Headers({
                "Content-Type": "application/json"
            });
        }
        return fetch(`${API_BASE_URL}/${url}`, init);
    }
}

export default Api;