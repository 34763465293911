import "pwacompat";
import app from "./app.base";
import UpdateNotificationService from "./service/UpdateNotificationService";
import { register } from "./serviceWorker";

// if("serviceWorker" in navigator)
// {
//     const onNewServiceWorker = (registration: ServiceWorkerRegistration, callback: () => void) => {
//         if (registration.waiting) {
//           // SW is waiting to activate. Can occur if multiple clients open and
//           // one of the clients is refreshed.
//           return callback();
//         }
      
//         function listenInstalledStateChange()
//         {
//             if (registration.installing === null)
//             {
//                 return;
//             }
//             registration.installing.addEventListener("statechange", function (event)
//             {
//                 if (event.target && (event.target as ServiceWorker).state === "installed")
//                 {
//                     // A new service worker is available, inform the user
//                     callback();
//                 }
//             });
//         };
      
//         if (registration.installing)
//         {
//             return listenInstalledStateChange();
//         }
      
//         // We are currently controlled so a new SW may be found...
//         // Add a listener in case a new SW is found,
//         registration.addEventListener("updatefound", listenInstalledStateChange);
//       }

//     window.addEventListener("load", () => {
//         navigator.serviceWorker.register("/sw.js").then(registration => {
//             if(!navigator.serviceWorker.controller)
//             {
//                 // it's a new service worker, we're done
//                 return;
//             }

//              // When the user asks to refresh the UI, we'll need to reload the window
//             let preventDevToolsReloadLoop = false;
//             navigator.serviceWorker.addEventListener("controllerchange", function(event) {
//                 // Ensure refresh is only called once.
//                 // This works around a bug in "force update on reload".
//                 if (preventDevToolsReloadLoop) return;
//                 preventDevToolsReloadLoop = true;
//                 window.location.reload();
//             });

//             onNewServiceWorker(registration, function ()
//             {
//                 UpdateNotificationService.emit("updateavailable");
//             });
//         });
//     });
// }

app();
register({
    onUpdate: () => {
        console.log("onUpdate");
        UpdateNotificationService.emit("updateavailable");
   } 
});