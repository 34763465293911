import * as React from "react";
import { useCallback, useState, useRef, useContext, useEffect } from "react";
import i18n from "es2015-i18n-tag";
import { Grid, TextField, IconButton, FormControl, InputLabel, Select, MenuItem, Button } from "@material-ui/core";

import BarcodeScan from "mdi-material-ui/BarcodeScan";
import { API_BASE_URL } from "../../../../api/api";
import { useAuthenticatedFetch } from "../../../../hooks/useAuthenticatedFetch";
import { LabelTypes } from "../../state/types";
import { RouteComponentProps } from "react-router";
import { ModuleContext, ModuleContextData } from "../../../../context/ModuleContext";
import AdditionalFields, { FieldValues } from "./AdditionalFields";
import { LabelItemResponse } from "../../api/label";
import auth from "../../../../service/auth";

export interface Props extends RouteComponentProps<any>
{

}


type InputElement =  HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

function useChangeCallback<T extends InputElement>(setValue: (s: string) => void)
{
    return useCallback((e: React.ChangeEvent<T>) => setValue(e.target.value), [setValue]);
}

function useFormState<T extends InputElement>(initialValue: string) : [string, (e: React.ChangeEvent<T>) => void, (v: string) => void]
{
    const [value, setValue] = useState<string>(initialValue);
    return [value, useChangeCallback<T>(setValue), setValue];
}

const fetch = auth.getAuthenticatedFetch();

interface Values
{
    quantity: string;
    labelSku: string;
    printer: string;
    additionalFields: FieldValues;
    barcode: string | null;
}

function useErrorToast(toast: ModuleContextData["toast"], errorObject: { errorMessage?: string } | undefined)
{
    useEffect(() => 
    {
        if(errorObject?.errorMessage)
        {
            toast({ message: errorObject.errorMessage, severity: "error" });
        }
    }, [errorObject, toast]);
}

function LabelView()
{
    const context = useContext(ModuleContext);
    const inputRef = useRef<HTMLInputElement>();
    const [confirmedBarCode, setConfirmedBarcode] = useState<string|null>(null);
    const [barcode, onBarcodeChanged, setBarcode] = useFormState("");
    const [quantity, onQtyChanged, setQuantity] = useFormState("");
    const [label, onLabelTypeChanged, setLabel] = useFormState("");
    const [printer, onPrinterChanged, setPrinter] = useFormState("");
    const [additionaValues, setAdditionalValues] = useState<FieldValues>({});

    const values = useRef<Values>();
    values.current = {
        quantity, labelSku: label, printer, additionalFields: additionaValues, barcode: confirmedBarCode
    }

    const qtyIsValid = /^\s*[0-9]*\s*$/.test(quantity);

    const onSerialNumberKeypress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter" && inputRef.current)
        {
            setConfirmedBarcode(inputRef.current.value);
        }
    }, []);

    const [labelTypes, isLoadingLabelTypes, labelTypesError] = useAuthenticatedFetch<LabelTypes>(
        (confirmedBarCode !== null) 
            ? `${API_BASE_URL}/lookup/barcode/${confirmedBarCode}/labels/en-US`
            : null);

    const [configuration, isLoadingConfiguration, configurationError] = useAuthenticatedFetch<LabelItemResponse>(
        (label !== "") 
            ? `${API_BASE_URL}/stock-management/label/${label}/config/en-US`
            : null);

    useEffect(() => {
        if(labelTypes?.defaultValue)
        {
            setLabel(labelTypes.defaultValue);
        }
    }, [labelTypes, setLabel]);
    
    useEffect(() => {
        if(configuration !== undefined)
        {
            if(configuration.defaultQty)
            {
                setQuantity(configuration.defaultQty.toString());
            }
            if(configuration.printers?.defaultValue)
            {
                setPrinter(configuration.printers.defaultValue);
            }
            if(configuration?.additionalFields?.length)
            {
                let defaultValues: FieldValues = {};
                for(let field of configuration.additionalFields)
                {
                    if(typeof field.value === "string")
                    {
                        defaultValues[field.name] = field.value;
                    }
                }
                setAdditionalValues(defaultValues);
            }
        }
    }, [configuration, setQuantity, setPrinter]);

    useErrorToast(context.toast, configurationError);
    useErrorToast(context.toast, labelTypesError);

    const onCommit = React.useCallback(() => 
    {
        fetch(`${API_BASE_URL}/stock-management/label/print`, { 
            method: "POST", 
            body: JSON.stringify({ ...values.current, quantity: parseInt(values.current!.quantity) }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            if(res.ok)
            {
                setConfirmedBarcode(null);
                setLabel("");
                setBarcode("");
                setQuantity("");
                setPrinter("");
                setAdditionalValues({});
                context.toast({
                    message: i18n`Label printing request successful.`,
                    severity: "success"
                }, 3000);
            }
            else
            {
                return res.json().then(Promise.reject);
            }
        }).catch(e => {
            context.toast({
                message: e.errorMessage || i18n`Label printing failed.`,
                severity: "error"
            });
        })
        ;
    }, [values, context, setBarcode, setLabel, setPrinter, setQuantity]);
        
    // handle loading indicator display
    const loadingIndicator = isLoadingLabelTypes || isLoadingConfiguration;
    React.useEffect(() => { context.isBusy(loadingIndicator) }, [loadingIndicator, context]);
    
    // handle default qty from configuration
    React.useEffect(() => { 
        if(configuration !== undefined)
        {
            const { defaultQty = "" } = configuration;
            setQuantity(defaultQty + "");
        }
    }, [configuration, setQuantity]);


    return (
        <>
            {/* <ConfirmQuantityDialog open={confirm} onClose={this.onConfirmPrintPressed} /> */}
            <Grid container spacing={8} >
                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{flexGrow: 1}}>
                        <TextField label={i18n`Search`} fullWidth 
                            value={barcode} 
                            onChange={onBarcodeChanged} 
                            onKeyPress={onSerialNumberKeypress}
                            inputRef={inputRef} />
                    </div>
                    <div style={{flexShrink: 1}}>
                        <IconButton>
                            <BarcodeScan />
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={12} style={{display: "flex", justifyContent: "space-between"}}>
                    <Grid container>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth disabled={labelTypes === undefined}>
                        <InputLabel>{i18n`Label Type`}</InputLabel>
                        <Select
                            value={label}
                            onChange={onLabelTypeChanged}>
                            {
                                (labelTypes !== undefined) ? (
                                    labelTypes.values.map(label => (
                                        <MenuItem key={label.value} value={label.value}>{label.label}</MenuItem>
                                    ))
                                ) : null
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                {
                    (configuration !== undefined) ? (
                        <FormControl fullWidth>
                            <InputLabel>{i18n`Printer`}</InputLabel>
                            <Select
                                value={printer}
                                onChange={onPrinterChanged}>
                                {
                                    (configuration !== undefined) ? (
                                        configuration.printers.values.map(printer => (
                                            <MenuItem key={printer.value} value={printer.value}>{printer.label}</MenuItem>
                                        ))
                                    ) : null
                                }
                            </Select>
                        </FormControl>
                    ) : null
                }
                </Grid>
                {
                    (configuration?.additionalFields !== undefined) ? (
                        <AdditionalFields fields={configuration.additionalFields} onChange={setAdditionalValues} />
                    ) : null
                }
                {
                    (configuration !== undefined) ? (
                        <Grid item xs={12}>
                            <TextField label={i18n`Quantity`}
                                disabled={configuration === undefined}
                                error={!qtyIsValid}
                                helperText={qtyIsValid ? undefined : i18n`Value must be an integer larger than zero`}
                                value={quantity}
                                onChange={onQtyChanged}
                                type="number" fullWidth />
                            </Grid>
    
                    ) : null
                }
                

                <Grid item xs={12} style={{ marginTop: 8 }}>
                    <Button 
                    // disabled={!this.isFormValid() || labelRequestActive}
                        variant="contained" 
                        onClick={onCommit}
                        // onClick={this.onPrintPressed}
                        >{i18n`Print`}</Button>
                    {/* {
                        (labelRequestActive) ? (
                            <CircularProgress />
                        ) : null
                    } */}
                </Grid>

            </Grid>
        </>
    );
}

export default LabelView;