import { Item, RelocationRequest } from "../../api/StockApi";
import { StockRelocationAction } from "../action/relocation";
import produce from "immer";

export interface State 
{
    request: Partial<RelocationRequest>;
    currentItem?: Item;
}

const initialState: State = { request: { } };

const reducer = (state = initialState, action: StockRelocationAction): State => produce(state, state => 
{
    if(action.type === "STOCK_RELOCATION_FETCH_ITEM")
    {
        state.currentItem = undefined;
    }

    else if(action.type === "STOCK_RELOCATION_FETCH_ITEM_SUCCESS")
    {
        state.currentItem = action.item;
    }

    else if(action.type === "STOCK_RELOCATION_CLEAR" || action.type === "STOCK_RELOCATION_COMMIT_SUCCESS")
    {
        state.request = {};
    }

    else if(action.type === "STOCK_RELOCATION_SET_SOURCE_LOCATION")
    {
        state.request.from = action.location;
    }

    else if(action.type === "STOCK_RELOCATION_SET_DESTINATION_LOCATION")
    {
        state.request.to = action.location;
    }

    else if(action.type === "STOCK_RELOCATION_SET_QUANTITY")
    {
        state.request.qty = action.qty;
    }

    else if(action.type === "STOCK_RELOCATION_SET_ITEM")
    {
        state.request.item = action.item;
    }

});

export const getCurrentItem = (state: State) => state.currentItem;
export const getRelocationRequest = (state: State) => state.request;

export default reducer;