import { InventoryState } from "../types";
import { StockAction } from "../action";
import {
    STOCK_FETCH_ITEM,
    STOCK_FETCH_ITEM_FAILURE,
    STOCK_FETCH_ITEM_SUCCESS,
    STOCK_UPDATE_CURRENT,
    STOCK_RESET_CURRENT,
    STOCK_SET_WAREHOUSE,
    STOCK_DRAFTS_LOAD_SUCCESS,
    STOCK_SCANNED_ITEM, 
    STOCK_SCANNED_LOCATION,
    STOCK_SCANNED_CLEAR,
    STOCK_INIT, 
    STOCK_UPDATE_ITEM, 
    STOCK_UPDATE_ITEM_SUCCESS, 
    STOCK_UPDATE_ITEM_FAILURE, 
    STOCK_DRAFTS_LOAD_FAILURE,
    STOCK_DISMISS_ERROR
} from "../action";
import { Draft } from "../../api/StockApi";
import { STOCK_DEBUG_CLEAR_STATE } from "../action/debug";

const initialState: InventoryState =
{
    fetchingItem: false,
    updatingItem: false,
    notifications: [],
    scanned: { }
};

const reducer = (state = initialState, action: StockAction): InventoryState =>
{
    if(action.type === STOCK_INIT)
    {
        return {
            ...state,
            fetchingItem: false,
            current: undefined,
            
            updatingItem: false,
            scanned: {}
        }
    }

    if(action.type === STOCK_RESET_CURRENT)   
    {
        return {
            ...state,
            current: undefined
        };
    }

    if(action.type === STOCK_UPDATE_CURRENT)
    {
        const { item } = action;
        return {
            ...state,
            current: {
                ...state.current,
                ...item
            } as any // TODO FIX
        };
    }

    if(action.type === STOCK_FETCH_ITEM)
    {
        return {
            ...state, 
            fetchingItem: true
        };
    }

    if(action.type === STOCK_FETCH_ITEM_SUCCESS)
    {
        const { item, location } = action;
        return {
            ...state, 
            fetchingItem: false,
            current: { ...item, location }
        }
    }

    if(action.type === STOCK_FETCH_ITEM_FAILURE)
    {
        const { notification } = action;
        return {
            ...state,
            fetchingItem: false,
            scanned: {},
            notifications: [...state.notifications, notification]
        }
    }

    if(action.type === STOCK_SET_WAREHOUSE)
    {
        const { warehouse } = action;
        return {
            ...state,
            drafts: undefined,
            warehouse
        }
    }

    if(action.type === STOCK_DRAFTS_LOAD_SUCCESS)
    {
        const { drafts } = action;
        return {
            ...state,
            drafts
        };
    }

    if(action.type === STOCK_DRAFTS_LOAD_FAILURE)
    {
        const { notification } = action;
        return {
            ...state,
            notifications: [...state.notifications, notification]
        }
    }

    if(action.type === STOCK_SCANNED_ITEM)
    {
        const { item } = action;
        return {
            ...state,
            scanned: {
                ...state.scanned,
                item
            }
        }
    }

    if(action.type === STOCK_SCANNED_LOCATION)
    {
        const { location } = action;
        const { location: previousLocation } = state.scanned;
        return {
            ...state,
            scanned: {
                ...state.scanned,
                location, previousLocation,
                pendingItems: undefined
            }
        }
    }

    if(action.type === "STOCK_REVERT_LOCATION")
    {
        return {
            ...state,
            scanned: {
                ...state.scanned,
                location: state.scanned.previousLocation,
                previousLocation: undefined
            }
        };
    }

    if(action.type === "STOCK_CONFIRM_LOCATION_COMPLETE_SUCCESS")
    {
        return {
            ...state,
            scanned: {
                ...state.scanned,
                previousLocation: undefined,
                pendingItems: undefined
            }
        };
    }

    if(action.type === "STOCK_FETCH_PENDING_ITEMS_SUCCESS")
    {
        const { items: pendingItems } = action;
        return {
            ...state,
            scanned: {
                ...state.scanned,
                pendingItems
            }
        }
    }

    if(action.type === "STOCK_FETCH_PENDING_ITEMS_FAILURE")
    {
        // TODO: Handle this
    }

    if(action.type === "STOCK_CLEAR_PREVIOUS_LOCATION_AND_ITEMS")
    {
        return {
            ...state,
            scanned: {
                ...state.scanned,
                pendingItems: undefined,
                previousLocation: undefined
            }
        };
    }

    if(action.type === STOCK_SCANNED_CLEAR)
    {
        return {
            ...state, 
            updatingItem: false,
            fetchingItem: false,
            current: undefined,
            scanned: {
                location: state.scanned.location
            }
        }
    }

    if(action.type === STOCK_UPDATE_ITEM)
    {
        return {
            ...state,
            updatingItem: true
        }
    }

    if(action.type === STOCK_UPDATE_ITEM_SUCCESS)
    {
        const { notification = undefined } = action;
        const notifications = (notification === undefined) 
            ? state.notifications
            : [...state.notifications, notification];

        return {
            ...state,
            updatingItem: false,
            current: undefined,
            scanned: {
                location: state.scanned.location
            },
            notifications
        }
    }

    if(action.type === STOCK_UPDATE_ITEM_FAILURE)
    {
        const { notification } = action;
        return {
            ...state,
            updatingItem: false,
            current: undefined,
            scanned: {},
            notifications: [...(state.notifications || []), notification]
        }
    }

    if(action.type === STOCK_DISMISS_ERROR)
    {
        const { id } = action;
        return {
            ...state,
            notifications: state.notifications.filter(err => err.id !== id)
        }
    }

    if(action.type === STOCK_DEBUG_CLEAR_STATE)
    {
        return initialState;
    }

    return state;
};

export const getCurrentWarehouse = (state: InventoryState) => state.warehouse;

export const getDrafts = (state: InventoryState): Draft[] => state.drafts || [];

export const getCurrentItem = (state: InventoryState) => state.current;

export const getScannedItem = (state: InventoryState) => state.scanned.item;

export const getScannedLocation = (state: InventoryState) => state.scanned.location;

export const getPreviousLocaiton = (state: InventoryState) => state.scanned.previousLocation;

export const getPendingItems = (state: InventoryState) => state.scanned.pendingItems;

export const getIsLoadingItem = (state: InventoryState) => state.fetchingItem;

export const getIsUpdatingItem = (state: InventoryState) => state.updatingItem;

export const getNotifications = (state: InventoryState) => state.notifications || [];

export default reducer;