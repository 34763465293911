import createStore from "./configureStore";

// increment this constant when the state tree is reorganized/refactorered
// this will clear the state on upgrades (module-state only)
const STATE_VERSION = 2;

const { store } = createStore(STATE_VERSION);

export { store };
export * from "./reducers";
export * from "./actions";
