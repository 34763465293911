import { RouteComponentProps } from "react-router";
import timeRecording from "./time-recording/config";
import stockManagement from "./stock-management/config";
import { ReactElement } from "react";
import tools from "./tools/config";

export interface Module 
{
    title: string;
    icon: React.ComponentType;
    component: React.ComponentType<ModuleProps>;
    url: string;
    submenu?: MenuItem[];
    roles: string[];
    tabs?: ReactElement;
}

export interface MenuItem
{
    title: string;
    icon?: React.ComponentType;
    url: string;
}

export interface ModuleProps extends RouteComponentProps<any>
{
    isBusy: (busy: boolean) => void;
    toast: (message: NotificationMessage, timeout?: number) => void;
    username: string | undefined;
    hasRole: (role: string) => boolean;
}

export interface NotificationMessage 
{
    message: string;
    severity: Severity;
}

export type Severity = "info" | "success" | "warning" | "error";

const config: Module[] = [
    timeRecording,
    stockManagement,
    tools
];

export default config;
