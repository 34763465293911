import { createMuiTheme } from "@material-ui/core/styles"

const env = window?.guldmann_mobility_env ?? "prod";
const theme = createMuiTheme({
    palette: {
        primary: {
            main: (env === "test") ? "#c20430" : "#faba00"
        }
    }
});

export default theme;