
class UpdateNotificationService
{
    private callbacks: { [type: string]: Function[] } = {};

    addEventListener(type: "updateavailable", callback: Function)
    {
        this.callbacks[type] = this.callbacks[type] || [];
        this.callbacks[type].push(callback);
    }

    removeEventListener(type: string, callback: Function)
    {
        if(type in this.callbacks)
        {
            this.callbacks[type] = this.callbacks[type].filter(c => c !== callback);
        }
    }

    emit(type: "updateavailable")
    {
        if(type in this.callbacks)
        {
            this.callbacks[type].forEach(fn => fn());
        }
    }
}



export default new UpdateNotificationService();